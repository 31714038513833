/**
 * Given a checkbox state management map:
 *
 * Return true if at least 1 diagnosis gap completed
 * Return false if no diagnosis gap completing checkboxes selected
 */
export default function areAnyDiagnosisGapsUpdated(dxActionCheckboxState) {
  return Object.values(dxActionCheckboxState).some(
    (actionState) =>
      actionState.diagnosisIncorrect || actionState.selectedIcds.length > 0,
  );
}
