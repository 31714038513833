import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import ModalIcd from '@/pages/PatientSummary/components/ModalIcd';
import {
  COMPLETE_WITHOUT_CODE,
  SUPERSEDED,
} from '@/pages/PatientSummary/utils/constants';
import { hccShape } from '@/utils/transformFrontendAction';
import { patientShape } from '@/utils/transformFrontendPatient';
import BaseLinkButton from '@/components/BaseLinkButton';
import CheckboxWithHighlight from '@/pages/PatientSummary/components/CheckboxWithHighlight';

export default function SectionSupersededHcc({
  actionId,
  patient,
  actionHcc,
  supersededHcc,
  icdModalOpen,
  handleOpenIcdModal,
  handleCloseIcdModal,
  handleSubmitIcdModal,
  onDxOptionToggled,
  dxActionCheckboxState,
}) {
  const checkboxId = `${actionId}-${SUPERSEDED}-${supersededHcc.code}`;
  const isHccSelected =
    dxActionCheckboxState[actionId].selectedOtherHccCode === supersededHcc.code;
  const isDxIncorrectSelected =
    dxActionCheckboxState[actionId].diagnosisIncorrect;
  const isAnyOtherIcdSelected =
    dxActionCheckboxState[actionId].selectedIcds.length > 0 && !isHccSelected;
  const icdForSelectedHcc = isHccSelected
    ? dxActionCheckboxState[actionId].selectedIcds[0]
    : undefined;

  const handleCheckboxClicked = (e) => {
    if (e.target.checked) {
      handleOpenIcdModal(checkboxId);
    } else {
      onDxOptionToggled(
        false,
        actionId,
        undefined,
        supersededHcc.code,
        false,
        false,
      );
    }
  };

  return (
    <>
      {icdModalOpen === checkboxId ? (
        <ModalIcd
          onClose={handleCloseIcdModal}
          onSubmit={handleSubmitIcdModal}
          patient={patient}
          actionHcc={actionHcc}
          overrideHcc={supersededHcc}
          actionId={actionId}
        />
      ) : null}
      <CheckboxWithHighlight
        sx={{ px: 1, alignItems: 'baseline' }}
        key={checkboxId}
        label={supersededHcc.name}
        disabled={isAnyOtherIcdSelected || isDxIncorrectSelected}
        checked={isHccSelected}
        onChange={handleCheckboxClicked}
        subtext={
          isHccSelected ? (
            <Typography component="span">
              {icdForSelectedHcc.icd === COMPLETE_WITHOUT_CODE
                ? icdForSelectedHcc.description
                : `${icdForSelectedHcc.icd} - ${icdForSelectedHcc.description}`}{' '}
              <Typography component="span" whiteSpace="nowrap">
                (
                <BaseLinkButton
                  sx={{ verticalAlign: 'baseline' }}
                  onClick={() => handleOpenIcdModal(checkboxId)}
                >
                  change
                </BaseLinkButton>
                )
              </Typography>
            </Typography>
          ) : null
        }
      />
    </>
  );
}

SectionSupersededHcc.propTypes = {
  actionId: PropTypes.number.isRequired,
  patient: patientShape.isRequired,
  actionHcc: hccShape.isRequired,
  supersededHcc: hccShape.isRequired,
  icdModalOpen: PropTypes.any.isRequired,
  handleOpenIcdModal: PropTypes.func.isRequired,
  handleCloseIcdModal: PropTypes.func.isRequired,
  handleSubmitIcdModal: PropTypes.func.isRequired,
  onDxOptionToggled: PropTypes.func.isRequired,
  dxActionCheckboxState: PropTypes.objectOf(PropTypes.object).isRequired,
};
