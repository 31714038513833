/**
 * Given a list of EditableTags from the getPatientSummary backend
 * Convert these tags into a list of options to be displayed as a CheckboxList
 *
 * Return [{value, label}]
 */
export default function getPatientTagOptions(editableTags, patientTags) {
  const patientTagOptions = [];
  editableTags.forEach((tag) => {
    if (tag.visible) {
      const shouldBeChecked = patientTags.includes(tag.name);
      patientTagOptions.push({
        value: tag.id.toString(),
        label: tag.name,
        checked: shouldBeChecked,
      });
    }
  });
  return patientTagOptions;
}
