import { isEmpty } from 'lodash';

/**
 * @deprecated Only use in unit tests please
 * @param messages
 * @param title
 */
export function mutatePatientFailure({ messages = [], title }) {
  return { messages, title };
}

const UNKNOWN_ERROR = mutatePatientFailure({ title: 'Unknown error' });

/**
 * @deprecated like the others
 * @param fileErrors
 * @returns {*[]|{messages: *[], title}}
 */
function processFileErrors(fileErrors) {
  if (!fileErrors) return [];

  return [
    mutatePatientFailure({
      messages: fileErrors,
      title: 'File Upload',
    }),
  ];
}

/**
 * @deprecated Only use in unit tests please
 * @param v1Errors
 * @returns an array of error objects
 */
export function processV1Errors(v1Errors) {
  if (!v1Errors) return [];

  try {
    const errorBody = v1Errors.response.data.formset_errors;

    // This creates a new HTML parsing context where we can safely create DOM
    // trees without accidentally executing any JS that might be contained
    // within it in an unsafe context. This protects us from cross-site
    // scripting attacks.
    //
    // After that, we collect some stringy data from the HTML, so we can return
    // a structured object.
    const parser = new DOMParser();
    const doc = parser.parseFromString(errorBody || '', 'text/html');
    const errorGroups = [...doc.querySelectorAll('ul.errorlist')];

    // For some reason we have no messages:
    if (errorGroups.length === 0) {
      const title = doc.body.firstChild.data.trim();
      if (!title) return [UNKNOWN_ERROR];
      return [mutatePatientFailure(title)];
    }

    return errorGroups.map((ul) => {
      // For each error, look at the previous sibling for the error title
      const title = ul.previousSibling.data.trim();

      // Then get the text of each LI
      const messages = [...ul.querySelectorAll('li')].map(
        (li) => li.textContent,
      );

      return mutatePatientFailure({ messages, title });
    });
  } catch (e) {
    return [UNKNOWN_ERROR];
  }
}

/**
 * Given an error from a step-based action, generate a standardized error
 * object that can be rendered on the frontend.
 *
 * @param error
 * @returns An error data object
 */
function processV2Error(error) {
  try {
    const actionDetails = error.action.v2_dto;
    const step = actionDetails.available_next_steps.find(
      (step) => step.step_id === error.step_id,
    );
    let messages;

    if (error.errors.ActionService) {
      // If the error comes from ActionService instead of the server-side
      // validation layer, it will have a top-level camelcased key of
      // ActionService, in which case use those error messages.
      messages = error.errors.ActionService;
    } else {
      messages = Object.entries(error.errors)
        .map(([fieldName, errorMessage]) => {
          const field = step.execution_requirements.find(
            (req) => req.name === fieldName,
          );

          if (!field) return null;
          return `${field.label}: ${errorMessage}`;
        })
        .filter(Boolean);
    }

    return mutatePatientFailure({
      messages,
      title: `Failed to save ${actionDetails.description} — ${step.description}`,
    });
  } catch (e) {
    return UNKNOWN_ERROR;
  }
}

/**
 * @deprecated Only use in unit tests please
 * @param v2Errors
 * @returns An array of error data objects
 */
export function processV2Errors(v2Errors) {
  if (!v2Errors) return [];

  try {
    const errorBody = v2Errors.response.data.reduce((result, a) => {
      // Only alert on errors, not successes
      if (!isEmpty(a.errors)) {
        result.push(a);
      }
      return result;
    }, []);

    // No error data, not sure why this would happen:
    if (isEmpty(errorBody)) return [UNKNOWN_ERROR];

    return errorBody.map(processV2Error);
  } catch (e) {
    return [UNKNOWN_ERROR];
  }
}

export default class MutatePatientFailures extends Error {
  constructor([fileErrors, v1Errors, v2Errors]) {
    super('MutatePatientFailures');

    this.errors = [
      // Eliminate duplicate unknown errors, if they exist:
      ...new Set([
        ...processFileErrors(fileErrors),
        ...processV1Errors(v1Errors),
        ...processV2Errors(v2Errors),
      ]),
    ];
  }
}
