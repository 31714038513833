import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

import BaseCheckbox from '@/components/BaseCheckbox';

export default function CheckboxWithHighlight({
  'data-dd-synthetics-id': dataDdSyntheticsId,
  'data-pendo-id': dataPendoId,
  checked,
  label,
  onChange,
  subtext,
  disabled,
  InputSx,
}) {
  return (
    <CheckboxWithHighlightRoot
      data-pendo-id={dataPendoId}
      borderRadius={1}
      bgcolor={checked ? 'background.tableZebra' : undefined}
      px={1}
      sx={{ ...InputSx }}
    >
      <StyledCheckbox
        data-dd-synthetics-id={dataDdSyntheticsId}
        checked={checked}
        label={label}
        onChange={(e) => onChange(e)}
        disabled={disabled}
      />

      {subtext && (
        <Typography sx={{ color: 'text.secondary', ml: 3 }} variant="bodysmall">
          {subtext}
        </Typography>
      )}
    </CheckboxWithHighlightRoot>
  );
}

CheckboxWithHighlight.propTypes = {
  'data-dd-synthetics-id': PropTypes.string,
  'data-pendo-id': PropTypes.string,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  subtext: PropTypes.node,
  disabled: PropTypes.bool,
  InputSx: PropTypes.object,
};

CheckboxWithHighlight.defaultProps = {
  'data-dd-synthetics-id': undefined,
  'data-pendo-id': undefined,
  subtext: undefined,
  disabled: false,
  InputSx: {},
};

const CheckboxWithHighlightRoot = styled(Box)`
  & + & {
    margin-top: ${(p) => p.theme.spacing(0.5)};
  }
`;

const StyledCheckbox = styled(BaseCheckbox)`
  align-items: baseline;
`;
