import styled from 'styled-components';

import { ReactComponent as LightbulbIcon } from '@/assets/icons/lightbulb.svg';

export default function InfoLightbulbIcon() {
  return <StyledLightbulbIcon />;
}

export const StyledLightbulbIcon = styled(LightbulbIcon)`
  color: ${(p) => p.theme.palette.status.infoForeground};
`;
