import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import SummaryBannerDropdownContainer from '@/pages/TaskBasedWorkflow/components/taskTableView/SummaryBannerDropdownContainer';
import {
  MainBox,
  StyledTocSummaryBannerRoot,
} from '@/pages/TaskBasedWorkflow/components/taskTableView/summaryBannerStyles';

import Markdown from '../markdown/Markdown';

import SummaryBannerWidget from './SummaryBannerWidget';

function TocSummaryBanner({ title, text, sx }) {
  return (
    <StyledTocSummaryBannerRoot sx={sx}>
      <MainBox>
        <Typography component="h1" variant="headerlarge">
          {title}
        </Typography>
        <Typography component="span" variant="bodylarge" mt={1}>
          <Markdown listItemPadding={8}>{text}</Markdown>
        </Typography>
      </MainBox>
      <SummaryBannerDropdownContainer />
      <SummaryBannerWidget />
    </StyledTocSummaryBannerRoot>
  );
}

TocSummaryBanner.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

TocSummaryBanner.defaultProps = {
  sx: undefined,
};

export default TocSummaryBanner;
