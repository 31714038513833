import { arrayOf, func, instanceOf, object } from 'prop-types';
import { Link, TableCell, TableRow, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { isEmpty, startCase } from 'lodash';
import { format, parseISO } from 'date-fns';

import BaseSelect from '@/components/BaseSelect';
import getVisibleStepExecutionRequirements, {
  getHistoricData,
  includeHistoricContextualData,
} from '@/pages/PatientSummary/utils/getVisibleStepExecutionRequirements';
import CareGapRowModal from '@/pages/PatientSummary/components/SectionCareGaps/CareGapRowModal';
import CareGapRowDetails from '@/pages/PatientSummary/components/SectionCareGaps/CareGapRowDetails';
import {
  DISPLAY_DATE_FORMAT,
  updatedFileNameKey,
} from '@/pages/PatientSummary/utils/constants';
import { constructChartUploadFilename } from '@/pages/PatientSummary/utils/renameChartUploadFile';
import SvuAmount from '@/components/SvuAmount';
import ActionHelpV2 from '@/pages/PatientSummary/components/ActionHelpV2';
import generateStatusFromAction from '@/pages/PatientSummary/utils/generateStatusFromAction';
import ActionTrigger from '@/pages/PatientSummary/components/SectionCareGaps/ActionTrigger';
import {
  BULK_REMOVE,
  appendExecutionRequirements,
  relatedActionShape,
} from '@/utils/relatedActions';
import {
  PRIORITY_PATIENT_VISIT,
  UPDATE_ELIGIBILITY_WINDOW,
} from '@/pages/TaskBasedWorkflow/constants';
import ActionUpdatedInfo from '@/pages/PatientSummary/components/SectionCareGaps/ActionUpdatedInfo';
import useFeatureFlag from '@/utils/useFeatureFlag';

import ActionProgressV2 from './ActionProgressV2';

function renameFiles(formSubmission, patient, action) {
  if (Object.hasOwn(formSubmission, 'file')) {
    // eslint-disable-next-line no-param-reassign
    formSubmission[updatedFileNameKey] = [];
    formSubmission.file.forEach((file) => {
      formSubmission[updatedFileNameKey].push(
        constructChartUploadFilename(patient, action.description, file.name),
      );
    });
  }
}

export default function CareGapActionRowV2({
  patient,
  action,
  dateOfService,
  formValues,
  onResetStep,
  onUpdateStep,
  relatedActions,
}) {
  const [showModal, toggleShowModal] = useToggle(false);
  // Here we maintain a bit of transient state to maintain user expectations:
  // If they select a new state for the action, it pops up a modal. If they
  // then immediately close that modal it should reset back to the previous
  // state.
  //
  // But if the user selects a new state, fills out the modal and saves it,
  // then reopening the modal later and closing it should **not** clear out
  // the values they have already input. This flag keeps track of that. It's
  // not a huge deal if it gets stale or is the wrong value, because it's just
  // user affordance.
  const [hasSavedModal, setHasSavedModal] = useState(false);
  const [selectedStep, setSelectedStep] = useState({});

  const [executionRequirements, defaultValues] = useMemo(() => {
    const visibleRequirements =
      getVisibleStepExecutionRequirements(selectedStep) ?? [];
    const execReqs = selectedStep?.isRemovalStep
      ? appendExecutionRequirements(
          visibleRequirements,
          relatedActions,
          action.actionId,
        )
      : visibleRequirements;
    return includeHistoricContextualData(
      execReqs,
      { ...formValues?.[selectedStep?.stepId] },
      getHistoricData(action),
      action.contextualInfo,
    );
  }, [selectedStep, relatedActions, action, formValues]);

  // TODO: Extract the below updates to defaultValues into a more robust implementation that can handle any action type, feature and execution requirement.
  const specifyPatientOffice = patient.attributedMgOffice?.id;
  if (specifyPatientOffice && action.type === 'scheduled') {
    defaultValues.office_id = specifyPatientOffice;
  }

  // Fill out completed_date for scheduled actions when autoclose_avs is enabled
  const autoCloseAVsEnabled = useFeatureFlag('autocloseAvs');
  if (
    autoCloseAVsEnabled &&
    action.contextualInfo?.latestExternalGapHistory &&
    action.type === 'scheduled'
  ) {
    defaultValues.completed_date =
      action.contextualInfo?.latestExternalGapHistory?.dateOfService;
  }

  const handleSelectStep = (e) => {
    const stepId = e.target.value;
    const step = action.availableNextSteps.find((s) => s.stepId === stepId);

    setSelectedStep(step);
    setHasSavedModal(false);

    if (!step) {
      // The user has selected the "Not started" step, which indicates that
      // this row should not submit any action form information when the parent
      // UI gets around to doing so.
      onResetStep();
    } else if (isEmpty(step.executionRequirements)) {
      // This step has no user inputs, so we can push that data to the parent
      // component immediately.
      onUpdateStep({ patientAction: action, step, formSubmission: {} });
    } else {
      toggleShowModal();
    }
  };

  const handleModalSubmit = (formSubmission) => {
    setHasSavedModal(true);
    renameFiles(formSubmission, patient, action);
    if (formSubmission[BULK_REMOVE]) {
      // iterate through the list of related actions
      relatedActions.forEach((relatedAction) => {
        const steps = relatedAction.availableNextSteps.filter(
          (step) => step.isRemovalStep,
        );
        if (steps.length > 0) {
          onUpdateStep({
            patientAction: { actionId: relatedAction.id },
            step: steps[0],
            formSubmission,
          });
        }
      });
    } else {
      onUpdateStep({
        patientAction: action,
        step: selectedStep,
        formSubmission,
      });
    }
    toggleShowModal();
  };

  const handleCloseModal = () => {
    toggleShowModal(); // hide.
    if (!hasSavedModal) {
      setSelectedStep();
      onResetStep();
    }
  };
  const lastStepHistory = action.stepHistories[action.stepHistories.length - 1];
  const actionWasUpdatedByClaims = Boolean(
    lastStepHistory &&
      lastStepHistory.templateStep === UPDATE_ELIGIBILITY_WINDOW,
  );

  const availableNextSteps = useMemo(() => {
    const firstStep = {
      value: '',
      label: generateStatusFromAction(
        action.preferredPath,
        action.stepHistories,
      ),
    };
    return [firstStep].concat(
      action.availableNextSteps.map((step) => ({
        value: step.stepId,
        label: step.description,
      })),
    );
  }, [action.availableNextSteps, action.preferredPath, action.stepHistories]);

  // set selectedStep from previous failed submission if any
  // formValues format follows useActionFormStateV2 for specific actionId
  // {
  //   [stepId]: {
  //       field_name_1: field_value_1,
  //       field_name_2: field_value_2,
  //       etc
  //     }
  //   },
  // }
  if (
    selectedStep &&
    formValues &&
    Object.keys(selectedStep).length === 0 &&
    Object.keys(formValues).length > 0
  ) {
    setSelectedStep(
      action.availableNextSteps.find(
        (s) => s.stepId === Object.keys(formValues)[0],
      ),
    );
  }
  const triggerUrl =
    action?.subtype === PRIORITY_PATIENT_VISIT
      ? null
      : action?.contextualInfo?.triggerUrl;
  return (
    <>
      <TableRow data-dd-synthetics-id={`care-gap-row-${action.subtype}`}>
        <TableCell>
          {action.contextualInfo?.category || startCase(action.type)}
        </TableCell>
        <TableCell>{action.description}</TableCell>
        <TableCell>
          {action.contextualInfo.helpText ? (
            <ActionHelpV2
              title={action.contextualInfo.category}
              helpContent={action.contextualInfo.helpText}
            />
          ) : null}
        </TableCell>

        <TableCell>
          <ActionTrigger
            data-pendo-id={`action-trigger-${action.actionId}`}
            actionId={action.actionId}
            trigger={{
              dataUrl: triggerUrl,
              text:
                action?.contextualInfo?.trigger || action?.triggerForDisplay,
            }}
          />
        </TableCell>
        <TableCell>
          {action.contextualInfo?.mostRecentProviderNameForDisplay ? (
            <Typography>
              {action.contextualInfo.mostRecentProviderNameForDisplay}
            </Typography>
          ) : (
            <Typography>Provider N/A </Typography>
          )}
          {action.contextualInfo?.mostRecentDateForDisplay ? (
            <>
              {action.type === 'patient_experience' && (
                <Typography gutterBottom variant="caption">
                  Last Completed:
                </Typography>
              )}
              <Typography variant="bodysmall" data-testid="mostRecentDateText">
                {format(
                  parseISO(action.contextualInfo.mostRecentDateForDisplay),
                  DISPLAY_DATE_FORMAT,
                )}
              </Typography>
            </>
          ) : null}
        </TableCell>
        <TableCell align="center">
          <ActionProgressV2 action={action} />
        </TableCell>

        <TableCell align="center">
          {autoCloseAVsEnabled &&
          action.contextualInfo?.latestExternalGapHistory ? (
            <Typography variant="bodyxsmall">
              Status in EHR:{' '}
              {
                action.contextualInfo?.latestExternalGapHistory?.details
                  ?.appointmentStatus
              }
            </Typography>
          ) : null}
          <br />
          <BaseSelect
            data-dd-synthetics-id="care-gap-status-dropdown"
            inputProps={{
              'data-pendo-id': 'ps-cg-base-select',
            }}
            options={availableNextSteps}
            value={
              formValues?.[selectedStep?.stepId] ? selectedStep?.stepId : ''
            }
            onChange={handleSelectStep}
          />
        </TableCell>
        <TableCell>
          <CareGapRowDetails
            actionState={formValues?.[selectedStep?.stepId]}
            executionRequirements={selectedStep?.executionRequirements}
            onEditStep={toggleShowModal}
          />
          {action.contextualInfo?.actionDetails && (
            <Typography variant="bodysmall">
              {action.contextualInfo.actionDetails}
            </Typography>
          )}
          {actionWasUpdatedByClaims && <ActionUpdatedInfo />}
          {action.contextualInfo.supportsPrintScheduledPdf ? (
            <Link
              href={`/patient/${patient.id}/scheduled-print/${action.actionId}`}
              target="_blank"
              rel="noreferrer"
            >
              Print referral
            </Link>
          ) : null}
        </TableCell>
        <TableCell align="center">
          <SvuAmount
            svuPostPromo={Number(action.possibleSvusRemainingPostPromo)}
            svuPrePromo={Number(action.possibleSvusRemainingPrePromo)}
            sx={{ display: 'block' }}
          />
        </TableCell>
      </TableRow>
      <CareGapRowModal
        action={action}
        dateOfService={dateOfService}
        defaultValues={defaultValues}
        executionRequirements={executionRequirements}
        onClose={handleCloseModal}
        onSubmit={handleModalSubmit}
        open={showModal}
        patient={patient}
        step={selectedStep}
        title={selectedStep?.description}
      />
    </>
  );
}

CareGapActionRowV2.propTypes = {
  patient: object.isRequired,
  action: object.isRequired,
  dateOfService: instanceOf(Date).isRequired,
  formValues: object,
  onResetStep: func.isRequired,
  onUpdateStep: func.isRequired,
  relatedActions: arrayOf(relatedActionShape),
};

CareGapActionRowV2.defaultProps = {
  formValues: undefined,
  relatedActions: [],
};
