import { TableCell, TableHead, TableRow } from '@mui/material';
import styled from 'styled-components';
import { bool } from 'prop-types';

const StyledCell = styled(TableCell)`
  border-bottom: 1px solid ${(p) => p.theme.palette.border.base};
  box-shadow: none;
`;

export default function CareGapsTableHeader({ completed }) {
  return (
    <TableHead>
      <TableRow sx={{ verticalAlign: 'bottom' }}>
        <StyledCell>Category</StyledCell>
        <StyledCell>Action Required</StyledCell>
        {/*
          Following is an empty column where we can optionally display help
          info for incomplete care gap rows.

          TODO: This column should ideally be labelled for accessibility
                purposes, even if we do not show it. --NH
         */}
        {completed ? null : <StyledCell />}
        <StyledCell>Trigger</StyledCell>
        <StyledCell align="center" width="120px">
          Most Recent
          <br />
          Service Provider
        </StyledCell>
        <StyledCell align="center" width="175px">
          Steps to Completion
        </StyledCell>
        <StyledCell align="center">Status</StyledCell>
        {completed ? null : <StyledCell align="center">Details</StyledCell>}
        <StyledCell align="center">SVU Amount</StyledCell>
      </TableRow>
    </TableHead>
  );
}

CareGapsTableHeader.propTypes = {
  completed: bool,
};

CareGapsTableHeader.defaultProps = {
  completed: false,
};
