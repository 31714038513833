import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as CheckIcon } from '@/assets/icons/checkTrue.svg';

export default function ZeroState({ message }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 1,
      }}
    >
      <Box display="flex">
        <Box component="span" display="flex" alignItems="center">
          <StyledCheckIcon />
        </Box>
        <Typography variant="h3">{message}</Typography>
      </Box>
    </Box>
  );
}

ZeroState.propTypes = {
  message: PropTypes.string.isRequired,
};

const StyledCheckIcon = styled(CheckIcon)`
  width: 18px;
  height: 18px;
  color: ${(p) => p.theme.palette.status.successForeground};
  margin-right: ${(p) => p.theme.spacing(1)};
`;
