import PropTypes from 'prop-types';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { useIntersection } from 'react-use';
import { useRef, useState } from 'react';
import { format } from 'date-fns';

import { ReactComponent as EditIcon } from '@/assets/icons/edit.svg';
import { patientShape } from '@/utils/transformFrontendPatient';
import { ButtonLabel } from '@/pages/PrintSummary/utils/printStyles';
import ModalPatientDemographics from '@/pages/PatientSummary/components/ModalPatientDemographics';
import useMutatePatientContactInfo from '@/pages/PatientSummary/hooks/useMutatePatientContactInfo';
import useFeatureFlag from '@/utils/useFeatureFlag';

import isValidPriorDate from '../utils/isValidPriorDate';
import useGetPriorDateView from '../hooks/useGetPriorDateView';

import PatientSummaryBanner from './PatientSummaryBanner';
import PaddedContainer from './PaddedContainer';
import PatientDetailsButtons from './PatientDetailsButtons';

const intersectionOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 1,
};

function PatientDetails({
  dateOfService,
  patient,
  tags,
  headline,
  onShowSubmitModal,
  onCopyPatientSummary,
  onShowPatientNotesModal,
  submitDisabled,
}) {
  const headerRef = useRef(null);
  const headerIntersection = useIntersection(headerRef, intersectionOptions);

  const actionsRef = useRef(null);
  const actionsIntersection = useIntersection(actionsRef, intersectionOptions);

  const showStickyActions =
    !actionsIntersection?.isIntersecting &&
    actionsIntersection?.boundingClientRect.top < 0;

  const navigateToUrl = (url) => {
    window.location.href = url;
  };

  const [editDemoModalDisplayed, setEditDemoModalDisplayed] = useState(false);

  const { isLoading: isLoadingContactInfo, mutate: mutateContactInfo } =
    useMutatePatientContactInfo({
      patientId: patient.id,
    });

  const handleEditDemoConfirmed = (addressData) => {
    setEditDemoModalDisplayed(false);
    mutateContactInfo({ addressData });
  };

  const patientSummaryV2LinkToLegacyPs = useFeatureFlag(
    'patientSummaryV2LinkToLegacyPs',
  );
  const patientSummaryV2LinkToMockEhr = useFeatureFlag(
    'patientSummaryV2LinkToMockEhr',
  );
  const showMemberNumber = useFeatureFlag('showMemberNumberOnSummary');

  const formattedHeadline =
    headline?.length > 100 ? `${headline.substring(0, 100)}...` : headline;

  const { data: dateViews } = useGetPriorDateView(patient.id);
  const isViewingPriorDate = isValidPriorDate(dateOfService, dateViews);

  return (
    <>
      <ModalPatientDemographics
        open={editDemoModalDisplayed}
        onSubmit={handleEditDemoConfirmed}
        onClose={() => setEditDemoModalDisplayed(false)}
        patient={patient}
      />
      <Box
        sx={{
          bgcolor: 'background.secondary',
          boxShadow: headerIntersection?.intersectionRatio < 1 ? 2 : 0,
          mt: 1,
          position: 'sticky',
          py: 1,
          top: -1,
          transition: 'box-shadow 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          zIndex: 1,
        }}
        ref={headerRef}
      >
        <PaddedContainer
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
              flexDirection: 'row',
            }}
          >
            <Typography
              sx={{ mr: 2, flex: '0 0 auto', whiteSpace: 'nowrap' }}
              variant="h1"
            >
              {patient.firstName} {patient.lastName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
              flexDirection: 'row',
            }}
          >
            <Typography variant="h2" flex="0 0 auto">
              {patient.dateOfBirth}
            </Typography>
            {/* There are two PatientDetailsButtons elements.
              One of them lives in the sticky header and is visually hidden on page load.
              This one here is the one in sticky header */}
            <Box
              sx={{
                display: 'flex',
                ml: 'auto',
              }}
            >
              <PatientDetailsButtons
                onShowSubmitModal={onShowSubmitModal}
                onCopyPatientSummary={onCopyPatientSummary}
                onShowPatientNotesModal={onShowPatientNotesModal}
                patient={patient}
                submitDisabled={submitDisabled}
                visuallyHidden={!showStickyActions}
                ddSyntheticsIdsDisabled
              />
            </Box>
          </Box>
        </PaddedContainer>
      </Box>

      <PaddedContainer>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            mt: 1,
          }}
        >
          {showMemberNumber ? (
            <PatientDetailWrapper>
              <Label>MBR ID:</Label> {patient.memberNumber}
            </PatientDetailWrapper>
          ) : null}
          {patient.provider && (
            <PatientDetailWrapper>
              <Label>PCP:</Label> {patient.provider.name}
            </PatientDetailWrapper>
          )}
          {patient.attributedMgOffice && (
            <PatientDetailWrapper>
              <Label>Office:</Label> {patient.attributedMgOffice.name}
            </PatientDetailWrapper>
          )}
          {patient.plan && (
            <PatientDetailWrapper>
              <Label>Plan:</Label> {patient.plan.name}
            </PatientDetailWrapper>
          )}
          {patient.address && (
            <PatientDetailWrapper>
              <Label>Contact:</Label>{' '}
              {patient.address.phoneNumber ||
                patient.address.secondaryPhoneNumber}
            </PatientDetailWrapper>
          )}
          <EditDetailsButton
            aria-label="Edit contact information"
            onClick={() => setEditDemoModalDisplayed(true)}
            disabled={isLoadingContactInfo}
          >
            <EditDetailsIcon />
          </EditDetailsButton>
        </Box>

        {formattedHeadline ? (
          <Typography sx={{ my: 1 }} variant="h4">
            {formattedHeadline}
          </Typography>
        ) : null}

        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          gap={1}
          mt={1}
        >
          {tags.length > 0 ? (
            <Stack
              direction="row"
              flexWrap="wrap"
              p={1}
              borderRadius={2}
              sx={{
                backgroundColor: 'background.primaryLightest',
              }}
            >
              {tags.map((tag) => (
                <Chip key={tag}>{tag}</Chip>
              ))}
            </Stack>
          ) : null}

          {patientSummaryV2LinkToLegacyPs && (
            <Typography variant="h1">
              <Button
                variant="text"
                size="medium"
                sx={{ p: 1 }}
                onClick={() =>
                  navigateToUrl(`/patient/${patient.id}/summary?override=True`)
                }
              >
                <ButtonLabel>Patient Summary V1</ButtonLabel>
              </Button>
            </Typography>
          )}

          {patientSummaryV2LinkToMockEhr && (
            <Typography variant="h1">
              <Button
                variant="text"
                size="medium"
                sx={{ p: 1 }}
                onClick={() =>
                  navigateToUrl(`/patient/${patient.id}/summary-ehr-demo`)
                }
              >
                <ButtonLabel>Patient Summary Mock EHR</ButtonLabel>
              </Button>
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            {isViewingPriorDate ? (
              <Typography
                variant="h3"
                color="text.primary"
                align="right"
                sx={{ display: 'flex', alignSelf: 'flex-end' }}
              >
                Displaying patient summary for{' '}
                {format(dateOfService, 'MMMM d, yyyy')}
              </Typography>
            ) : null}
            {/* There are two PatientDetailsButtons elements.
              One of them lives in the sticky header and is visually hidden on page load. */}
            <PatientDetailsButtons
              onShowSubmitModal={onShowSubmitModal}
              onCopyPatientSummary={onCopyPatientSummary}
              onShowPatientNotesModal={onShowPatientNotesModal}
              patient={patient}
              ref={actionsRef}
              submitDisabled={submitDisabled}
            />
          </Box>
        </Stack>
        {patient.banner ? (
          <PatientSummaryBanner banner={patient.banner} />
        ) : null}
      </PaddedContainer>
    </>
  );
}

PatientDetails.propTypes = {
  dateOfService: PropTypes.instanceOf(Date),
  patient: patientShape.isRequired,
  headline: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCopyPatientSummary: PropTypes.func.isRequired,
  onShowSubmitModal: PropTypes.func.isRequired,
  onShowPatientNotesModal: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
};

PatientDetails.defaultProps = {
  dateOfService: null,
};

const Label = styled.span`
  color: ${(p) => p.theme.palette.text.secondary};
`;

const PatientDetailWrapper = styled.div`
  white-space: nowrap;

  &:not(:last-of-type) {
    margin-right: ${(p) => p.theme.spacing(3)};
  }
`;

const Chip = styled.div`
  background-color: ${(p) => p.theme.palette.background.base};
  border-radius: 6px;
  padding: 3px 12px;
  white-space: nowrap;

  :not(:first-of-type) {
    margin-left: 4px;
  }
`;

const EditDetailsButton = styled.button`
  padding: 4px;
  line-height: 0;
  border: none;
  margin-left: 4px;
`;

const EditDetailsIcon = styled(EditIcon)`
  width: 16px;
  height: 16px;
  color: ${(p) => p.theme.palette.icon.link};
`;

export default PatientDetails;
