import { Container, styled } from '@mui/material';

/**
 * A Container that contains the correct max-width, min-width, and padding for PatientSummaryPage.
 */
const PaddedContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0, 4),
  minWidth: 900,
  [theme.breakpoints.down('md')]: { p: 0 },
  [theme.breakpoints.up('xs')]: {
    maxWidth: 1264,
    padding: theme.spacing(0, 4),
  },
}));

export default PaddedContainer;
