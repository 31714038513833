import produce from 'immer';
import { format } from 'date-fns';

import {
  ISO_DATE_FORMAT,
  dateOfServiceOfChartUpload,
  originalFilenameKey,
  systemFilenameKey,
  updatedFileNameKey,
} from '@/pages/PatientSummary/utils/constants';
import { updateDateOnChartUploadFilename } from '@/pages/PatientSummary/utils/renameChartUploadFile';

/**
 * Returns a date in the format YYYY-MM-DD.
 * @param {Date} date
 */
function formatDate(date) {
  return format(date, ISO_DATE_FORMAT);
}

/**
 * Util specific to new Step-Based Action manipulation
 * Given executionInput of key:value pairs
 *
 * @param executionInput
 * @returns {unknown}
 */
export default function cleanExecutionInput({
  stepId,
  executionInput,
  dateOfService,
  uploadFileResponses,
}) {
  const executionInputCopy = produce(executionInput, (draft) => {
    // Convert date objects to strings.
    Object.entries(executionInput).forEach(([key, value]) => {
      if (value instanceof Date) {
        draft[key] = formatDate(value);
      }
      // subtle difference between sending an undefined value (gets deserialized as '')
      // and not sending a value at all (gets deserialized as None); we want the latter.
      if (!value) {
        delete draft[key];
      }
    });

    // chart file upload actions (renameChartUploadFile for more details)
    // updatedFileNameKey temporary holder for purposefully renamed system files
    // files in step-based actions uploaded by front-end and only file names
    // submitted as execution info to ActionService
    if (draft.file) {
      const uploadFileResponse = uploadFileResponses.filter(
        (response) => response.stepId === stepId,
      )[0];
      draft[originalFilenameKey] = uploadFileResponse.file_id;
      draft[systemFilenameKey] = updateDateOnChartUploadFilename(
        dateOfService,
        draft[updatedFileNameKey],
      );
      delete draft[updatedFileNameKey];
      draft[dateOfServiceOfChartUpload] = formatDate(new Date(dateOfService));
      delete draft.file;
    }
  });

  return executionInputCopy;
}
