import { Paper, TableCell, Typography } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';

import ClinicalGuidelineInputForm from '@/pages/PatientSummary/components/EvidenceBasedRecommendationsSection/ClinicalGuidelineInputForm';

function IncompleteEvidenceBasedRecommendationsTable({
  incompleteClinicalGuidelineActions,
  saveActionFormStateV2,
}) {
  return (
    incompleteClinicalGuidelineActions?.length > 0 && (
      <Paper sx={{ my: 1, borderColor: 'border.base' }} variant="outlined">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Recommendation</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incompleteClinicalGuidelineActions.map((action) => (
                <TableRow key={action?.contextualInfo?.clinicalGuideline}>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    <Typography>
                      <b>
                        {action?.contextualInfo?.clinicalGuidelineSummary} -{' '}
                      </b>
                      {action?.contextualInfo?.clinicalGuidelineDescription}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    {action?.contextualInfo?.clinicalGuideline}
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    <ClinicalGuidelineInputForm
                      action={action}
                      saveActionFormStateV2={saveActionFormStateV2}
                      guidelineSummary={
                        action?.contextualInfo?.clinicalGuidelineSummary
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  );
}

IncompleteEvidenceBasedRecommendationsTable.propTypes = {
  incompleteClinicalGuidelineActions: PropTypes.array.isRequired,
  saveActionFormStateV2: PropTypes.func.isRequired,
};

export default IncompleteEvidenceBasedRecommendationsTable;
