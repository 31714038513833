import { Paper } from '@mui/material';
import PropTypes from 'prop-types';

import PaddedContainer from '@/pages/PatientSummary/components/PaddedContainer';
import useFeatureFlag from '@/utils/useFeatureFlag';
import EvidenceBasedRecommendationsHeader from '@/pages/PatientSummary/components/EvidenceBasedRecommendationsSection/EvidenceBasedRecommendationsHeader';
import IncompleteEvidenceBasedRecommendationsTable from '@/pages/PatientSummary/components/EvidenceBasedRecommendationsSection/IncompleteEvidenceBasedRecommendationsTable';
import CompleteEvidenceBasedRecommendationsTable from '@/pages/PatientSummary/components/EvidenceBasedRecommendationsSection/CompleteEvidenceBasedRecommendationsTable';

function SectionEvidenceBasedRecommendationsContainer({
  incompleteClinicalGuidelineActions,
  completedActionsIsLoading,
  completedClinicalGuidelineActions,
  saveActionFormStateV2,
}) {
  const clinicalGuidelinesSectionEnabled = useFeatureFlag(
    'clinicalRecommendationsAction',
  );
  return (
    clinicalGuidelinesSectionEnabled &&
    (incompleteClinicalGuidelineActions?.length > 0 ||
      (!completedActionsIsLoading &&
        completedClinicalGuidelineActions?.length > 0)) && (
      <PaddedContainer>
        <Paper sx={{ p: 1, mb: 4 }}>
          <EvidenceBasedRecommendationsHeader />
          <IncompleteEvidenceBasedRecommendationsTable
            incompleteClinicalGuidelineActions={
              incompleteClinicalGuidelineActions
            }
            saveActionFormStateV2={saveActionFormStateV2}
          />
          <CompleteEvidenceBasedRecommendationsTable
            completedActionsIsLoading={completedActionsIsLoading}
            completedClinicalGuidelineActions={
              completedClinicalGuidelineActions
            }
          />
        </Paper>
      </PaddedContainer>
    )
  );
}

SectionEvidenceBasedRecommendationsContainer.propTypes = {
  incompleteClinicalGuidelineActions: PropTypes.array.isRequired,
  completedActionsIsLoading: PropTypes.bool.isRequired,
  completedClinicalGuidelineActions: PropTypes.array.isRequired,
  saveActionFormStateV2: PropTypes.func.isRequired,
};

SectionEvidenceBasedRecommendationsContainer.defaultProps = {};

export default SectionEvidenceBasedRecommendationsContainer;
