import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import getCsrfToken from '@/utils/getCsrfToken';
import { textKey } from '@/pages/PatientSummary/utils/constants';

import queryKeyFactory from '../queryKeyFactory';

async function addPatientNote({ text }) {
  const formData = new FormData();
  formData.append(textKey, text);

  return axios.post(`add_note`, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-CSRFToken': getCsrfToken(),
    },
  });
}

export default function useMutatePatientNote({ patientId }) {
  const queryClient = useQueryClient();

  return useMutation(addPatientNote, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryKeyFactory.patientNotes({ patientId }),
      );
    },
  });
}
