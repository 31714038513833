import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

/**
 * Sends a request to update the follow-up and scheduling users for a specific MedicalGroup.
 * @param {Object} payload - The payload to send to the API.
 * @param {number} payload.medicalGroupId - The ID of the medical group to update.
 * @param {number} [payload.assignedFollowupUserId] - The ID of the follow-up user to assign (optional).
 * @param {number} [payload.assignedSchedulingUserId] - The ID of the scheduling user to assign (optional).
 * @returns {Promise<Object>} The API response data.
 * @throws {Error} Throws an error if the API request fails or returns a non-200 status code.
 */
async function updateMedicalGroupAssignedUsers({
  medicalGroupId,
  assignedFollowupUserId,
  assignedSchedulingUserId,
}) {
  const response = await axios.post(
    `/api/medical_group/${medicalGroupId}/toc_users/update`,
    {
      assigned_followup_user_id: assignedFollowupUserId,
      assigned_scheduling_user_id: assignedSchedulingUserId,
    },
  );

  if (response.status !== 200) {
    throw new Error(response.data.message || 'Error updating users');
  }

  return response.data;
}

/**
 * Custom hook to manage the mutation for updating follow-up and scheduling users in a medical group.
 * This hook leverages React Query's `useMutation` for handling the API request and its state.
 * @param {Object} [options] - Optional settings for the mutation.
 * @param {Function} [options.onSuccess] - Callback function to execute when the mutation is successful.
 * @param {Function} [options.onError] - Callback function to execute when the mutation fails.
 * @returns {Object} Mutation object from React Query, which includes:
 *  - `mutate`: The function to trigger the mutation.
 *  - `isLoading`: Boolean indicating if the mutation is in progress.
 *  - `isError`: Boolean indicating if the mutation encountered an error.
 *  - `isSuccess`: Boolean indicating if the mutation was successful.
 *  - `data`: The response data returned by the API.
 *  - `error`: The error object if the mutation fails.
 */
export default function useUpdateMedicalGroupAssignedUsers({
  onSuccess = undefined,
  onError = undefined,
} = {}) {
  return useMutation(updateMedicalGroupAssignedUsers, {
    onSuccess,
    onError,
  });
}
