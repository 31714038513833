const rootKey = 'patient-summary';

const queryKeyFactory = {
  patient: ({ patientId }) => {
    return [rootKey, patientId];
  },
  actions: ({ patientId, dateOfService = null }) => {
    return [
      ...queryKeyFactory.patient({ patientId }),
      'actions',
      dateOfService,
    ];
  },
  actionForms: ({ patientId, dateOfService = null }) => {
    return [
      ...queryKeyFactory.patient({ patientId }),
      'action-forms',
      dateOfService,
    ];
  },
  patientSummary: ({ patientId, dateOfService = null }) => {
    return [
      ...queryKeyFactory.patient({ patientId }),
      'patient-summary',
      dateOfService,
    ];
  },
  completedActions: ({ patientId, dateOfService = null }) => {
    return [
      ...queryKeyFactory.patient({ patientId }),
      'completed-actions',
      dateOfService,
    ];
  },
  patientNotes: ({ patientId }) => {
    return [
      ...queryKeyFactory.patient({ patientId }),
      'patient-notes',
      { patientId },
    ];
  },
  diagnosisOptions: ({ searchTerm, hcc, patientId }) => {
    return [
      ...queryKeyFactory.patient({ patientId }),
      'diagnosis-options',
      searchTerm,
      hcc,
    ];
  },
  staffData: ({ patientId }) => {
    return [
      ...queryKeyFactory.patient({ patientId }),
      'staff-data',
      { patientId },
    ];
  },
};

export default queryKeyFactory;
