import { useQuery } from '@tanstack/react-query';

import queryKeyFactory from '@/pages/PatientSummary/queryKeyFactory';
import transformFrontendPatientNotes from '@/utils/transformFrontendPatientNotes';
import transformFrontendPatientTags from '@/utils/transformFrontendPatientTags';
import useAxios from '@/hooks/useAxios';

async function fetchPatientNotes(axios, { patientId }) {
  const params = { patient_id: patientId };
  const response = await axios.get('/api/patient_summary/patient_notes_data', {
    params,
  });
  return response.data;
}

export default function useGetPatientNotes({ patientId }) {
  const params = { patientId };
  const axios = useAxios();
  return useQuery(
    queryKeyFactory.patientNotes(params),
    () => fetchPatientNotes(axios, params),
    {
      select: (notesData) => {
        const {
          patient_notes: patientNotes = [],
          editable_tags: editableTags = [],
          headline = '',
        } = notesData;
        return {
          patientNotes: transformFrontendPatientNotes(patientNotes),
          editableTags: transformFrontendPatientTags(editableTags),
          headline,
        };
      },
    },
  );
}
