import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';

function useValidation({ earned, pending, total }) {
  useEffect(() => {
    if (earned < 0) {
      // eslint-disable-next-line no-console
      console.error('Expected earned SVUs to be greater than 0.');
    }
    if (pending < 0) {
      // eslint-disable-next-line no-console
      console.error('Expected pending SVUs to be greater than 0.');
    }
    if (earned + pending > total) {
      // eslint-disable-next-line no-console
      console.error(
        'Expected sum of earned and pending SVUs to be less than total SVUs.',
      );
    }
  }, [earned, pending, total]);
}

function SvuProgress({ earned, pending, sx, total }) {
  useValidation({ earned, pending, total });

  const earnedBarWidth = (earned / total) * 100;
  const pendingBarWidth = (pending / total) * 100;

  return (
    <Box
      sx={[
        {
          borderRadius: '8px',
          bgcolor: 'background.base',
          border: 1,
          borderColor: 'border.base',
          py: 1,
          px: 2,
          width: 282,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography color="text.label">
        <b>{total}</b> Available SVUs
      </Typography>

      <Box
        sx={{
          overflow: 'hidden',
          borderRadius: '16px',
          height: 16,
          width: 250,
          bgcolor: 'background.primaryLightest',
          mt: 0.5,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            bgcolor: 'status.successForeground',
            borderRadius: '16px 0 0 16px',
            bottom: 0,
            left: 0,
            position: 'absolute',
            top: 0,
            width: `${earnedBarWidth}%`,
          }}
        />
        <Box
          sx={{
            bgcolor: 'status.infoForeground',
            borderRadius: '0 16px 16px 0',
            bottom: 0,
            left: `${earnedBarWidth}%`,
            position: 'absolute',
            top: 0,
            width: `${pendingBarWidth}%`,
          }}
        />
      </Box>

      <Typography sx={{ mt: 0.5 }}>
        <Box component="span" sx={{ color: 'status.successForeground' }}>
          <b>{earned}</b> <span>Earned</span>
        </Box>
        {pending > 0 && (
          <Box
            component="span"
            sx={{
              color: 'background.primary',
              ml: 2,
            }}
          >
            <b>{pending}</b> <span>Pending</span>
          </Box>
        )}
      </Typography>
    </Box>
  );
}

SvuProgress.propTypes = {
  earned: PropTypes.number.isRequired,
  pending: PropTypes.number.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  total: PropTypes.number.isRequired,
};

SvuProgress.defaultProps = {
  sx: undefined,
};

export default SvuProgress;
