import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@mui/material';

export default function PatientSummaryBanner({ banner }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: 'status.moderateBackground',
        outlineColor: 'status.moderateForeground',
        p: 2,
        mt: 2,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {banner.iconUrl ? (
        <Box flex="0 0 auto" mr={2}>
          <img src={banner.iconUrl} alt="" />
        </Box>
      ) : null}
      <Box flex="1">
        <Typography variant="bodybold">{banner.header}</Typography>
        <Typography mt={2}>{banner.body}</Typography>
      </Box>
    </Paper>
  );
}

PatientSummaryBanner.propTypes = {
  banner: PropTypes.objectOf(PropTypes.string).isRequired,
};
