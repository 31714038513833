import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { formatISO } from 'date-fns';

import queryKeyFactory from '../queryKeyFactory';

import useDateOfServiceManager from './useDateOfServiceManager';

async function fetchDiagnosisOptions({
  searchTerm,
  hcc,
  patientId,
  actionId,
  dateOfService,
}) {
  const response = await axios.get(`/patient/${patientId}/search`, {
    params: {
      term: searchTerm,
      hcc,
      action_id: actionId,
      date_of_service: dateOfService,
    },
  });
  return response.data;
}

export default function useGetDiagnosisOptions({
  searchTerm,
  hcc,
  patientId,
  actionId,
}) {
  const [dateOfService] = useDateOfServiceManager();
  const params = {
    searchTerm,
    hcc,
    patientId,
    actionId,
    dateOfService: dateOfService
      ? formatISO(dateOfService, { representation: 'date' })
      : undefined,
  };
  return useQuery(queryKeyFactory.diagnosisOptions(params), () =>
    fetchDiagnosisOptions(params),
  );
}
