import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { useQueryParameters } from '@/contexts/QueryParametersContext';

import queryKeyFactory from '../queryKeyFactory';

function transformResponse(data) {
  return {
    visits: {
      completedTasksCount: data.visits.completed_tasks_count,
      notScheduledCount: data.visits.not_scheduled,
      readyToCompleteCount: data.visits.ready_to_complete,
      upcomingApptCount: data.visits.upcoming_appt,
    },
  };
}

async function fetchInsights({ queryKey }) {
  const [
    ,
    ,
    {
      providerIds,
      officeIds,
      planIds,
      patientTags,
      ageBuckets,
      tbwSubtypeChoices,
      patientNeed,
      avYears,
      allSubtypes,
    },
  ] = queryKey;

  const response = await axios.get(`/api/tbw/insights`, {
    params: {
      provider_ids: providerIds,
      office_ids: officeIds,
      plan_ids: planIds,
      patient_tags: patientTags,
      age_buckets: ageBuckets,
      action_subtypes: tbwSubtypeChoices,
      patient_need: patientNeed,
      av_years: avYears,
      all_subtypes: allSubtypes,
    },
  });
  return response.data;
}

export default function useGetTbwInsights({ enabled = true } = {}) {
  const { parameters } = useQueryParameters();
  return useQuery(
    queryKeyFactory.taskCounts({
      providerIds: parameters.providerIds,
      officeIds: parameters.officeIds,
      planIds: parameters.planIds,
      patientTags: parameters.patientTags,
      ageBuckets: parameters.ageBuckets,
      tbwSubtypeChoices: parameters.tbwSubtypeChoices,
      patientNeed: parameters.patientNeed,
      avYears: parameters.avYears,
      allSubtypes: parameters.allSubtypes,
    }),
    fetchInsights,
    {
      select: transformResponse,
      keepPreviousData: true,
      enabled,
    },
  );
}
