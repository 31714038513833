import { useForm } from 'react-hook-form';
import { Alert, Box, FormLabel } from '@mui/material';
import useId from '@mui/utils/useId';
import { bool, func, instanceOf } from 'prop-types';
import { clamp } from 'date-fns';

import BaseModal from '@/components/BaseModal';
import RhfDatePicker from '@/components/reactHookFormComponents/RhfDatePicker';

import { dateViewShape } from '../hooks/useGetPriorDateView';

export default function PriorDateViewModal({
  dateOfService,
  onClose,
  onPickDate,
  open,
  dateView,
}) {
  const id = useId();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { minDate, maxDate } = dateView;

  function doHandleSubmit(evt) {
    onPickDate(evt.date_of_service);
  }

  return (
    <BaseModal
      sx={{ maxWidth: 450 }}
      onClose={onClose}
      onSubmit={handleSubmit(doHandleSubmit)}
      open={open}
      title={dateView.modalTitle}
    >
      <Alert severity="info">{dateView.modalMessage}</Alert>
      <Box py={1}>
        <FormLabel htmlFor={id}>Date of service</FormLabel>
        <RhfDatePicker
          control={control}
          defaultValue={clamp(dateOfService ?? new Date(), {
            start: minDate,
            end: maxDate,
          })}
          errors={errors}
          id={id}
          label="Date of service"
          maxDate={maxDate}
          minDate={minDate}
          name="date_of_service"
        />
      </Box>
    </BaseModal>
  );
}
PriorDateViewModal.propTypes = {
  dateOfService: instanceOf(Date),
  onClose: func.isRequired,
  onPickDate: func.isRequired,
  open: bool.isRequired,
  dateView: dateViewShape.isRequired,
};
PriorDateViewModal.defaultProps = {
  dateOfService: null,
};
