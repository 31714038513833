import { Paper, TableCell, Typography } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import { useToggle } from 'react-use';

import LoadingTableCard from '@/pages/PatientSummary/components/LoadingTableCard';
import { ActionHistoryButton } from '@/pages/PatientSummary/components/SectionCareGaps/ActionHistoryButton';

function CompleteEvidenceBasedRecommendationsTable({
  completedActionsIsLoading,
  completedClinicalGuidelineActions,
}) {
  const completedCareGapActionsCount =
    completedClinicalGuidelineActions?.length;
  const [showCompleted, toggleShowCompleted] = useToggle(false);
  const extractAdherenceConfirmationNotes = (action) =>
    action?.stepHistories?.find(
      (step) => step?.stepName === 'adherence_confirmation',
    )?.executionInfo?.notes ?? null;
  return (
    <>
      <ActionHistoryButton
        data-dd-synthetics-id="care-gap-completed-button"
        data-pendo-id="ps-cg-completed-button"
        onClick={toggleShowCompleted}
        disabled={completedCareGapActionsCount === 0}
        buttonColor={showCompleted ? 'text.primary' : 'text.link'}
      >
        {`Completed (${completedCareGapActionsCount})`}
      </ActionHistoryButton>

      {showCompleted && completedActionsIsLoading && <LoadingTableCard />}
      {showCompleted && !completedActionsIsLoading && (
        <Paper sx={{ my: 1, borderColor: 'border.base' }} variant="outlined">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    Recommendation
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top' }}>Source</TableCell>
                  <TableCell style={{ verticalAlign: 'top' }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {completedClinicalGuidelineActions?.map((action) => (
                  <TableRow key={action?.contextualInfo?.clinicalGuideline}>
                    <TableCell style={{ verticalAlign: 'top' }}>
                      <Typography>
                        <b>
                          {action?.contextualInfo?.clinicalGuidelineSummary} -{' '}
                        </b>
                        {action?.contextualInfo?.clinicalGuidelineDescription}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                      {action?.contextualInfo?.clinicalGuideline}
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                      <b>{action?.contextualInfo?.clinicalGuidelineStatus}</b>
                      <Typography
                        style={{
                          whiteSpace: 'pre-wrap',
                          maxWidth: '37.5rem',
                        }}
                      >
                        <i>{extractAdherenceConfirmationNotes(action)}</i>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
}

CompleteEvidenceBasedRecommendationsTable.propTypes = {
  completedActionsIsLoading: PropTypes.bool.isRequired,
  completedClinicalGuidelineActions: PropTypes.array.isRequired,
};

CompleteEvidenceBasedRecommendationsTable.defaultProps = {};

export default CompleteEvidenceBasedRecommendationsTable;
