import { Box, Paper, Table, TableBody, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useToggle } from 'react-use';
import { useCallback } from 'react';

import { careGapActionShape } from '@/utils/transformFrontendCareGapAction';
import CompletedCareGapActionRowV2 from '@/pages/PatientSummary/components/SectionCareGaps/CompletedCareGapActionRowV2';
import ZeroState from '@/pages/PatientSummary/components/ZeroState';
import LoadingTableCard from '@/pages/PatientSummary/components/LoadingTableCard';
import { findRelatedActions, getActionsToGroup } from '@/utils/relatedActions';
import PriorityPatientUserDrivenEnrollmentFormTile from '@/pages/PatientSummary/components/PriorityPatientUserDrivenEnrollmentFormTile';
import useFeatureFlag from '@/utils/useFeatureFlag';
import { PRIORITY_PATIENT_VISIT } from '@/pages/TaskBasedWorkflow/constants';

import { ActionHistoryButton } from './ActionHistoryButton';
import CareGapsTableHeader from './CareGapsTableHeader';
import CompletedCareGapActionRow from './CompletedCareGapActionRow';
import CareGapActionRowV2 from './CareGapActionRowV2';
import CareGapActionRowV1 from './CareGapActionRowV1';

function SectionCareGaps({
  actionForms,
  careGapActionFormValuesV2,
  completedActionsIsLoading,
  completedCareGapActionsCount,
  completedCareGapActionsV1,
  completedCareGapActionsV2,
  dateOfService,
  incompleteCareGapActionsV1,
  incompleteCareGapActionsV2,
  onResetCareGapsActionV2,
  onUpdateCareGapsAction,
  onUpdateCareGapsActionV2,
  patient,
}) {
  const [showCompleted, toggleShowCompleted] = useToggle(false);

  const handleV1SaveAction = useCallback(
    (e) => {
      onUpdateCareGapsAction({
        actionId: e.action.id,
        actionType: e.action.type,
        updatedActionState: e.state,
        updatedActionData: e.form,
      });
    },
    [onUpdateCareGapsAction],
  );

  const handleV1SelectAction = useCallback(
    (e) => {
      onUpdateCareGapsAction({
        actionId: e.action.id,
        actionType: e.action.type,
        updatedActionState: e.state,
      });
    },
    [onUpdateCareGapsAction],
  );

  const hasOpenGaps =
    incompleteCareGapActionsV1.length > 0 ||
    incompleteCareGapActionsV2.length > 0;

  const actionsToGroup = getActionsToGroup(incompleteCareGapActionsV2);
  const userDrivenEnrollmentFormTileEnabled =
    useFeatureFlag('ppvAtPointOfCare');
  const ppvAction = incompleteCareGapActionsV2.filter(
    (action) => action.subtype === PRIORITY_PATIENT_VISIT,
  )[0];

  return (
    <Paper sx={{ p: 1 }}>
      <Paper
        elevation={0}
        sx={{ backgroundColor: 'background.tableZebra', p: 1, display: 'flex' }}
      >
        <Box mr="auto" my="auto">
          <Typography variant="h2">Care Gaps</Typography>
        </Box>
      </Paper>
      {userDrivenEnrollmentFormTileEnabled && ppvAction ? (
        <Paper sx={{ my: 1, borderColor: 'border.base', boxShadow: 'none' }}>
          <PriorityPatientUserDrivenEnrollmentFormTile
            patientId={patient.id}
            userDrivenEnrollmentForms={patient.userDrivenEnrollmentForms}
            ppvAction={ppvAction}
          />
        </Paper>
      ) : null}
      {!hasOpenGaps ? (
        <ZeroState message="This patient has no open care gaps." />
      ) : (
        <Paper sx={{ my: 1, borderColor: 'border.base' }} variant="outlined">
          <Table>
            <CareGapsTableHeader />
            <TableBody>
              {incompleteCareGapActionsV1.map((action) => (
                <CareGapActionRowV1
                  key={action.id}
                  action={action}
                  actionFormState={actionForms[action.id] ?? {}}
                  onSaveAction={handleV1SaveAction}
                  onSelectActionStatus={handleV1SelectAction}
                  patient={patient}
                  dateOfService={dateOfService}
                />
              ))}
              {incompleteCareGapActionsV2.map((action) => (
                <CareGapActionRowV2
                  key={action.actionId}
                  patient={patient}
                  action={action}
                  dateOfService={dateOfService}
                  formValues={careGapActionFormValuesV2[action.actionId]}
                  onResetStep={() =>
                    onResetCareGapsActionV2({ patientAction: action })
                  }
                  onUpdateStep={onUpdateCareGapsActionV2}
                  relatedActions={findRelatedActions(
                    action.contextualInfo.adtEventDate,
                    actionsToGroup,
                  )}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
      <ActionHistoryButton
        data-dd-synthetics-id="care-gap-completed-button"
        data-pendo-id="ps-cg-completed-button"
        onClick={toggleShowCompleted}
        disabled={completedCareGapActionsCount === 0}
        buttonColor={showCompleted ? 'text.primary' : 'text.link'}
      >
        {`Completed (${completedCareGapActionsCount})`}
      </ActionHistoryButton>
      {showCompleted && completedActionsIsLoading && <LoadingTableCard />}
      {showCompleted && !completedActionsIsLoading && (
        <Paper
          data-dd-synthetics-id="completed-care-gaps-section"
          sx={{ mt: 1, borderColor: 'border.base' }}
          variant="outlined"
        >
          <Table>
            <CareGapsTableHeader completed />
            <TableBody>
              {completedCareGapActionsV1.map((action) => (
                <CompletedCareGapActionRow key={action.id} action={action} />
              ))}
              {completedCareGapActionsV2.map((action) => (
                <CompletedCareGapActionRowV2
                  key={action.actionId}
                  action={action}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Paper>
  );
}

export default SectionCareGaps;

SectionCareGaps.propTypes = {
  actionForms: PropTypes.object.isRequired,
  careGapActionFormValuesV2: PropTypes.object.isRequired,
  completedActionsIsLoading: PropTypes.bool,
  completedCareGapActionsCount: PropTypes.number.isRequired,
  completedCareGapActionsV1: PropTypes.arrayOf(careGapActionShape),
  completedCareGapActionsV2: PropTypes.array,
  dateOfService: PropTypes.instanceOf(Date).isRequired,
  incompleteCareGapActionsV1: PropTypes.arrayOf(careGapActionShape),
  incompleteCareGapActionsV2: PropTypes.array.isRequired,
  onResetCareGapsActionV2: PropTypes.func,
  onUpdateCareGapsAction: PropTypes.func,
  onUpdateCareGapsActionV2: PropTypes.func,
  patient: PropTypes.object.isRequired,
};

SectionCareGaps.defaultProps = {
  completedActionsIsLoading: true,
  completedCareGapActionsV1: [],
  completedCareGapActionsV2: [],
  incompleteCareGapActionsV1: [],
  onResetCareGapsActionV2: undefined,
  onUpdateCareGapsAction: undefined,
  onUpdateCareGapsActionV2: undefined,
};
