import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

export default function LoadingTableCard() {
  return (
    <Paper
      variant="outlined"
      sx={{ p: 2, pb: 3.25, mt: 1, width: '100%', height: '110px' }}
    >
      {[1, 2, 3].map((i) => (
        <Box key={i} sx={{ pb: 1.75, display: 'flex' }}>
          <Box sx={{ flexGrow: 2, pr: 1 }}>
            <Skeleton height={16} variant="rectangular" />
          </Box>
          <Box sx={{ flexGrow: 1, pl: 1, pr: 1 }}>
            <Skeleton height={16} variant="rectangular" />
          </Box>
          <Box sx={{ flexGrow: 2.5, pl: 1, pr: 1 }}>
            <Skeleton height={16} variant="rectangular" />
          </Box>
          <Box sx={{ flexGrow: 1.5, pl: 1 }}>
            <Skeleton height={16} variant="rectangular" />
          </Box>
        </Box>
      ))}
    </Paper>
  );
}
