import { bool, func, node, oneOf, shape, string } from 'prop-types';
import {
  Box,
  CircularProgress,
  Dialog,
  Paper,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as CheckIcon } from '@/assets/icons/checkFalse.svg';
import { ReactComponent as ErrorIcon } from '@/assets/icons/warning.svg';

const ICON_SIZE = 57;

export default function SubmissionModal({
  children,
  messages,
  onClose,
  open,
  state,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Paper sx={{ width: 332, pt: 4, pb: 2, px: 2 }}>
        <Box textAlign="center">
          {state === 'idle' ? (
            <CircularProgress
              size={ICON_SIZE}
              variant="determinate"
              value={100}
            />
          ) : null}
          {state === 'loading' ? <CircularProgress size={ICON_SIZE} /> : null}
          {state === 'success' ? (
            <StyledCheckIcon height={ICON_SIZE} width={ICON_SIZE} />
          ) : null}
          {state === 'error' ? (
            <StyledErrorIcon height={ICON_SIZE} width={ICON_SIZE} />
          ) : null}
        </Box>

        <Typography my={2} textAlign="center">
          {messages[state]}
        </Typography>

        {children}
      </Paper>
    </Dialog>
  );
}

SubmissionModal.propTypes = {
  children: node,
  messages: shape({
    loading: string,
    success: string,
    error: string,
  }),
  onClose: func.isRequired,
  open: bool.isRequired,
  state: oneOf(['idle', 'loading', 'success', 'error']).isRequired,
};
SubmissionModal.defaultProps = {
  children: undefined,
  messages: {
    loading: 'Submitting...',
    success: 'Submitted',
    error: 'Submission failed',
  },
};

const StyledCheckIcon = styled(CheckIcon)`
  color: ${(p) => p.theme.palette.status.successForeground};
`;

const StyledErrorIcon = styled(ErrorIcon)`
  color: ${(p) => p.theme.palette.status.errorForeground};
`;
