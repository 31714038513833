import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import getCsrfToken from '@/utils/getCsrfToken';

import queryKeyFactory from '../queryKeyFactory';

async function updatePatientContactInfo({ addressData }) {
  const formData = new FormData();
  Object.entries(addressData).forEach(([key, value]) => {
    formData.set(key.toString(), value);
  });

  return axios.post(`update_contact_info`, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-CSRFToken': getCsrfToken(),
    },
  });
}

export default function useMutatePatientContactInfo({ patientId }) {
  const queryClient = useQueryClient();

  return useMutation(updatePatientContactInfo, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryKeyFactory.patientSummary({ patientId }),
      );
    },
  });
}
