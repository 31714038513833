import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import useFeatureFlag from '@/utils/useFeatureFlag';
import { userInputTaskShape } from '@/utils/shapes';
import { SummaryBannerRoot } from '@/pages/TaskBasedWorkflow/components/taskTableView/summaryBannerStyles';

import { Page } from '../../constants';
import { usePage } from '../../contexts/PageContext';
import Markdown from '../markdown/Markdown';

import PatientExperienceBanner from './PatientExperienceBanner';
import SummaryBannerWidget from './SummaryBannerWidget';

function SummaryBanner({ title, text, sx, firstTask }) {
  const page = usePage();
  const pxLandingPageBannerEnabled = useFeatureFlag('px_landing_page_banner');

  if (pxLandingPageBannerEnabled && page === Page.patientExperience) {
    return (
      <PatientExperienceBanner
        title={title}
        text={text}
        firstTask={firstTask}
      />
    );
  }

  return (
    <SummaryBannerRoot sx={sx}>
      <Box flexBasis={0} flexGrow={4}>
        <Typography component="h1" variant="headermedium">
          {title}
        </Typography>
        <Typography component="div" mt={1}>
          <Markdown listItemPadding={8}>{text}</Markdown>
        </Typography>
      </Box>

      <SummaryBannerWidget />
    </SummaryBannerRoot>
  );
}

SummaryBanner.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  firstTask: userInputTaskShape,
};

SummaryBanner.defaultProps = {
  sx: undefined,
  firstTask: undefined,
};

export default SummaryBanner;
