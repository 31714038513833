import { useMemo, useState } from 'react';

import instantiateActionForms from '../utils/instantiateActionForms';

/**
 * Generates an ergonomic version of the action forms on the patient summary
 * page. It takes in the action form state from the server and converts it
 * into something that can be manipulated client-side. When the user enters
 * in new form data, those new values shadow the old.
 *
 * This allows new initialActionForms values to come in without overriding
 * user-inputted data, ensuring a reasonable balance between having the latest
 * server-provided info without overriding the user's in-progress activity.
 *
 * @param initialActionForms
 * @returns {[{},((value: (((prevState: {}) => {}) | {})) => void)]}
 */
export default function useActionFormsToSubmit(initialActionForms) {
  const actionForms = useMemo(
    () => instantiateActionForms(initialActionForms),
    [initialActionForms],
  );

  const [actionFormsInternal, setActionFormsInternal] = useState({});

  const actionFormState = useMemo(() => {
    return Object.entries(actionForms).reduce(
      (accumulator, [id, actionForm]) => {
        // Note mutation. No need to create new object identities
        // unnecessarily:
        accumulator[id] = actionFormsInternal[id] ?? actionForm;
        return accumulator;
      },
      {},
    );
  }, [actionFormsInternal, actionForms]);

  return [actionFormState, setActionFormsInternal];
}
