import styled from 'styled-components';
import { Link, TableCell, TableRow, Typography } from '@mui/material';
import { func, instanceOf, object } from 'prop-types';
import { useToggle } from 'react-use';
import { isEmpty } from 'lodash';
import { useState } from 'react';

import { careGapActionShape } from '@/utils/transformFrontendCareGapAction';
import BaseSelect from '@/components/BaseSelect';
import SvuAmount from '@/components/SvuAmount';

import getActionExecutionRequirements from '../../utils/getActionExecutionRequirements';

import ActionHelp from './ActionHelp';
import ActionTrigger from './ActionTrigger';
import ActionProgress from './ActionProgress';
import CareGapRowDetails from './CareGapRowDetails';
import CareGapRowModal from './CareGapRowModal';

/**
 * Constructs a care gap action row for legacy non-step-based actions. This
 * renders the information for a care gap action as well as any input the
 * user may have made for this care gap. It offers a select control for
 * resolving the action in a variety of ways. Selecting an option may bring
 * up a modal containing a form for collecting additional info.
 *
 * It maintains no internal state, and instead triggers callbacks at key
 * moments to indicate interesting user interactions.
 *
 * @param action
 * @param onSaveAction
 * @param onSelectActionStatus
 * @param patient
 * @param userActionState
 * @returns {JSX.Element}
 */
export default function CareGapActionRowV1({
  action,
  dateOfService,
  onSaveAction,
  onSelectActionStatus,
  patient,
  actionFormState,
}) {
  const [showModal, toggleShowModal] = useToggle(false);

  // Here we maintain a bit of transient state to maintain user expectations:
  // If they select a new state for the action, it pops up a modal. If they
  // then immediately close that modal it should reset back to the previous
  // state.
  //
  // But if the user selects a new state, fills out the modal and saves it,
  // then reopening the modal later and closing it should **not** clear out
  // the values they have already input. This flag keeps track of that. It's
  // not a huge deal if it gets stale or is the wrong value, because it's just
  // user affordance.
  const [hasSavedModal, setHasSavedModal] = useState(
    actionFormState.state !== action.state,
  );

  // Given the current staged state of the action, get the execution
  // requirements for that action. We use this in a moment to populate the
  // modal's form, if the modal is in view.
  const executionRequirements = getActionExecutionRequirements(
    action.subtype,
    actionFormState.state,
  );

  const handleModalSubmit = (form) => {
    setHasSavedModal(true);
    toggleShowModal(); // hide.
    onSaveAction({ action, form, state: actionFormState.state });
  };

  // Tell the parent component that the user has selected a state-to-close for
  // this care gap action.
  const handleSelectState = (e) => {
    const newState = e.target.value;

    const newExecutionRequirements = getActionExecutionRequirements(
      action.subtype,
      newState,
      { min_date: action.minDate },
    );

    // If the new action state has execution requirements, pop the modal open.
    if (!isEmpty(newExecutionRequirements)) toggleShowModal();

    onSelectActionStatus({ action, state: newState });
    setHasSavedModal(false);
  };

  const handleCloseModal = () => {
    toggleShowModal(); // hide.

    // If the user has not specifically saved the information in the modal
    // before, then reset back to the previous action state:
    if (!hasSavedModal) onSelectActionStatus({ action, state: action.state });
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography>{action.typeDisplay}</Typography>
          {action.staff ? <Subtext>staff</Subtext> : null}
        </TableCell>
        <TableCell>{action.text}</TableCell>
        <TableCell>
          {action.help?.show ? <ActionHelp help={action.help} /> : null}
        </TableCell>
        <TableCell>
          {action.trigger ? (
            <ActionTrigger
              data-pendo-id="ps-cg-action-trigger-button"
              trigger={action.trigger}
            />
          ) : null}
        </TableCell>
        <TableCell>
          <Typography>{action.providerName || 'Provider N/A'}</Typography>
          {action.date ? <Typography>{action.date}</Typography> : null}
        </TableCell>
        <TableCell align="center">
          {action.progress?.show ? (
            <ActionProgress progress={action.progress} />
          ) : null}
        </TableCell>
        <TableCell align="center">
          <BaseSelect
            inputProps={{
              'data-testid': `${action.subtype}-${action.id}-states`,
              'data-pendo-id': 'ps-cg-base-select',
            }}
            options={action.respondChoices}
            value={actionFormState.state ?? action.state}
            onChange={handleSelectState}
          />
        </TableCell>
        <TableCell>
          {hasSavedModal ? (
            <CareGapRowDetails
              actionState={actionFormState}
              executionRequirements={executionRequirements}
              onEditStep={toggleShowModal}
            />
          ) : null}
          <Typography
            variant="label"
            sx={{ color: 'status.successForeground' }}
          >
            {action.extraDetailForDisplay.concat(
              action.isScheduled ? ` by ${action.userWhoLastClicked}` : '',
            )}
          </Typography>
          {action.supportsPrintScheduledPdf ? (
            <Link
              href={`/patient/${patient.id}/scheduled-print/${action.id}`}
              target="_blank"
              rel="noreferrer"
            >
              Print referral
            </Link>
          ) : null}

          {action.contactHistoryCount > 0 ? (
            <ActionTrigger
              data-pendo-id="ps-cg-details-contact-history-button"
              trigger={{
                text: 'Contact History',
                dataUrl: `/coordinate/${patient.id}/action/${action.id}/contact_history`,
              }}
            />
          ) : null}

          {action.showAddressingHistory ? (
            <ActionTrigger
              data-pendo-id="ps-cg-details-history-button"
              trigger={{
                text: 'History',
                dataUrl: `/patient/${patient.id}/actions/${action.id}/addressing_history`,
              }}
            />
          ) : null}
        </TableCell>
        <TableCell align="center">
          <SvuAmount
            svuPostPromo={action.svusAfterPromo}
            svuPrePromo={action.svusBeforePromo}
            sx={{ display: 'block' }}
          />
        </TableCell>
      </TableRow>
      <CareGapRowModal
        action={action}
        dateOfService={dateOfService}
        defaultValues={actionFormState}
        executionRequirements={executionRequirements}
        onClose={handleCloseModal}
        onSubmit={handleModalSubmit}
        open={showModal}
        patient={patient}
        title={action.text}
      />
    </>
  );
}

CareGapActionRowV1.propTypes = {
  action: careGapActionShape.isRequired,
  dateOfService: instanceOf(Date).isRequired,
  onSaveAction: func.isRequired,
  onSelectActionStatus: func.isRequired,
  patient: object.isRequired,
  actionFormState: object.isRequired,
};

const Subtext = styled.span`
  font-style: italic;
  font-size: 80%;
  color: ${(p) => p.theme.palette.text.secondary};
`;
