import { Box, ThemeProvider } from '@mui/material';
import { func, instanceOf } from 'prop-types';

import { patientShape } from '@/utils/transformFrontendPatient';
import useFeatureFlag from '@/utils/useFeatureFlag';
import themeV2 from '@/themeV2';

import PaddedContainer from '../PaddedContainer';
import useGetPriorDateView from '../../hooks/useGetPriorDateView';
import isValidPriorDate from '../../utils/isValidPriorDate';

import PriorDateViewButton from './PriorDateViewButton';
import PatientSummaryLinkItem from './PatientSummaryLinkItem';
import BasePatientSummaryNavItem from './BasePatientSummaryNavItem';

export const SUMMARY_V2 = 'summary_v2';
export const PROVIDER_VISITS = 'provider-visits';
export const MEDICATIONS = 'medications';
export const REFERRAL = 'referral';
export const FILES = 'files';
export const ACCESS_LOG = 'access-log';
export const VISITS_DX_HISTORY = 'visits';

function PatientSummaryNavigation({
  dateOfService,
  onChangeDateOfService,
  patient,
  handleChangeTab,
}) {
  const providerVisitsTabFlag = useFeatureFlag('providerVisitsTab');
  const referralToFacilityFlag = useFeatureFlag('referralToFacility');
  const visitsDxHistoryFlag = useFeatureFlag('enablePrintedSummaryRiskFactors');
  const isStaff = useFeatureFlag('isStaff');
  const hideFilesTab = useFeatureFlag('hideFilesTabPsv2');

  const { data: dateViews } = useGetPriorDateView(patient.id);
  const isViewingPriorDate = isValidPriorDate(dateOfService, dateViews);

  return (
    <ThemeProvider theme={themeV2}>
      <PaddedContainer
        role="navigation"
        aria-label="Patient summary navigation"
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            mt: 4,
          }}
        >
          {isViewingPriorDate ? (
            <BasePatientSummaryNavItem
              label="Summary"
              data-pendo-id="ps-navigation-button-summary_v2"
              onClick={() => handleChangeTab(SUMMARY_V2, isViewingPriorDate)}
            />
          ) : (
            <PatientSummaryLinkItem
              label="Summary"
              expectedPath={SUMMARY_V2}
              patient={patient}
              onClick={() => handleChangeTab(SUMMARY_V2)}
            />
          )}

          {providerVisitsTabFlag && !visitsDxHistoryFlag ? (
            <PatientSummaryLinkItem
              label={`Review Provider Visits (${patient.providerVisitsCount})`}
              expectedPath={PROVIDER_VISITS}
              patient={patient}
              onClick={() => handleChangeTab(PROVIDER_VISITS)}
            />
          ) : null}
          {visitsDxHistoryFlag && (
            <PatientSummaryLinkItem
              onClick={() => handleChangeTab(VISITS_DX_HISTORY)}
              label="Visit and Dx History"
              patient={patient}
              expectedPath={`${SUMMARY_V2}?activeTab=visits`} // temporary: will be unneeded when all of these tabs are reactJS-ified
            />
          )}

          <PatientSummaryLinkItem
            label={`Medications (${patient.medicationsCount})`}
            expectedPath={MEDICATIONS}
            patient={patient}
            onClick={() => handleChangeTab(MEDICATIONS)}
          />

          {referralToFacilityFlag && (
            <PatientSummaryLinkItem
              label="Referral"
              expectedPath={REFERRAL}
              patient={patient}
              onClick={() => handleChangeTab(REFERRAL)}
            />
          )}

          {!hideFilesTab && (
            <PatientSummaryLinkItem
              label={`Files (${patient.filesCount})`}
              expectedPath={FILES}
              patient={patient}
              onClick={() => handleChangeTab(FILES)}
            />
          )}

          {dateViews?.map((dateView) => (
            <PriorDateViewButton
              key={dateView.buttonText}
              dateOfService={dateOfService}
              onChangeDateOfService={onChangeDateOfService}
              dateView={dateView}
            />
          ))}

          {isStaff && (
            <PatientSummaryLinkItem
              label="Access Log (Staff only)"
              expectedPath={ACCESS_LOG}
              patient={patient}
              onClick={() => handleChangeTab(ACCESS_LOG)}
            />
          )}
        </Box>
      </PaddedContainer>
    </ThemeProvider>
  );
}

PatientSummaryNavigation.propTypes = {
  dateOfService: instanceOf(Date),
  onChangeDateOfService: func.isRequired,
  patient: patientShape.isRequired,
  handleChangeTab: func.isRequired,
};
PatientSummaryNavigation.defaultProps = {
  dateOfService: null,
};

export default PatientSummaryNavigation;
