import { Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import SectionActionableDiagnosis from '@/pages/PatientSummary/components/SectionActionableDiagnosis';
import PaddedContainer from '@/pages/PatientSummary/components/PaddedContainer';
import { actionShape } from '@/utils/transformFrontendAction';
import SectionSummaryDiagnosis from '@/pages/PatientSummary/components/SectionSummaryDiagnosis';
// TODO: replace this with a more-local implementation:
import { ActionHistoryButton } from '@/pages/PatientSummary/components/SectionCareGaps/ActionHistoryButton';
import { ShowDiagnosisActionHistorySetting } from '@/pages/PatientSummary/utils/constants';
import { patientShape } from '@/utils/transformFrontendPatient';
import LoadingTableCard from '@/pages/PatientSummary/components/LoadingTableCard';

const showAwaitingClaims = false;

function SectionDiagnosisContainer({
  completedActionsIsLoading,
  completedDiagnosisActions,
  completedDiagnosisCount,
  dateOfService,
  diagnosisSectionType,
  dxActionCheckboxState,
  notAssessedDiagnosisMessageShowFlagState,
  onNotAssessedDiagnosisMessageShowFlagState,
  incompleteDiagnosisActions,
  isHistoricalConditions,
  onChangeDateOfService,
  onCustomIcdEntered,
  onDxOptionToggled,
  otherIcdDisplayed,
  showNotAssessedOption,
  patient,
}) {
  const [
    showDiagnosisActionHistoryToggle,
    setShowDiagnosisActionHistoryToggle,
  ] = useState(ShowDiagnosisActionHistorySetting.off);

  const handleDiagnosisActionHistoryToggle = (desiredSetting) => {
    if (showDiagnosisActionHistoryToggle === desiredSetting) {
      setShowDiagnosisActionHistoryToggle(
        ShowDiagnosisActionHistorySetting.off,
      );
    } else {
      setShowDiagnosisActionHistoryToggle(desiredSetting);
    }
  };

  return (
    <PaddedContainer>
      <Paper sx={{ p: 1, mb: 4 }}>
        <SectionActionableDiagnosis
          patient={patient}
          diagnosisActions={incompleteDiagnosisActions}
          dateOfService={dateOfService}
          onChangeDateOfService={onChangeDateOfService}
          diagnosisSectionType={diagnosisSectionType}
          onDxOptionToggled={onDxOptionToggled}
          onCustomIcdEntered={onCustomIcdEntered}
          dxActionCheckboxState={dxActionCheckboxState}
          notAssessedDiagnosisMessageShowFlagState={
            notAssessedDiagnosisMessageShowFlagState
          }
          onNotAssessedDiagnosisMessageShowFlagState={
            onNotAssessedDiagnosisMessageShowFlagState
          }
          showNotAssessedOption={showNotAssessedOption}
          otherIcdDisplayed={otherIcdDisplayed}
        />
        <Typography>
          {showAwaitingClaims && (
            <ActionHistoryButton
              data-pendo-id="ps-diagnosis-awaiting-claims-button"
              onClick={() =>
                handleDiagnosisActionHistoryToggle(
                  ShowDiagnosisActionHistorySetting.awaitingClaim,
                )
              }
              disabled={incompleteDiagnosisActions.length === 0}
              buttonColor={
                showDiagnosisActionHistoryToggle ===
                ShowDiagnosisActionHistorySetting.awaitingClaim
                  ? 'text.primary'
                  : 'text.link'
              }
            >
              {`Awaiting Claim (${incompleteDiagnosisActions.length})`}
            </ActionHistoryButton>
          )}

          <ActionHistoryButton
            data-dd-synthetics-id={
              isHistoricalConditions
                ? 'historical-conditions-completed-button'
                : undefined
            }
            data-pendo-id="ps-diagnosis-completed-button"
            onClick={() =>
              handleDiagnosisActionHistoryToggle(
                ShowDiagnosisActionHistorySetting.completed,
              )
            }
            disabled={completedDiagnosisCount === 0}
            buttonColor={
              showDiagnosisActionHistoryToggle ===
              ShowDiagnosisActionHistorySetting.completed
                ? 'text.primary'
                : 'text.link'
            }
          >
            {`Completed (${completedDiagnosisCount})`}
          </ActionHistoryButton>
          {showDiagnosisActionHistoryToggle ===
            ShowDiagnosisActionHistorySetting.completed &&
            completedActionsIsLoading && <LoadingTableCard />}
        </Typography>

        {!completedActionsIsLoading && (
          <SectionSummaryDiagnosis
            isHistoricalConditions={isHistoricalConditions}
            incompleteDiagnosisActions={incompleteDiagnosisActions}
            completedDiagnosisActions={completedDiagnosisActions}
            showDiagnosisActionHistoryToggle={showDiagnosisActionHistoryToggle}
          />
        )}
      </Paper>
    </PaddedContainer>
  );
}

SectionDiagnosisContainer.propTypes = {
  completedActionsIsLoading: PropTypes.bool,
  completedDiagnosisActions: PropTypes.arrayOf(actionShape),
  completedDiagnosisCount: PropTypes.number,
  dateOfService: PropTypes.instanceOf(Date),
  diagnosisSectionType: PropTypes.string.isRequired,
  dxActionCheckboxState: PropTypes.objectOf(PropTypes.object).isRequired,
  notAssessedDiagnosisMessageShowFlagState: PropTypes.objectOf(PropTypes.object)
    .isRequired,
  onNotAssessedDiagnosisMessageShowFlagState: PropTypes.func.isRequired,
  incompleteDiagnosisActions: PropTypes.arrayOf(actionShape),
  isHistoricalConditions: PropTypes.bool,
  onChangeDateOfService: PropTypes.func.isRequired,
  onCustomIcdEntered: PropTypes.func.isRequired,
  onDxOptionToggled: PropTypes.func.isRequired,
  otherIcdDisplayed: PropTypes.objectOf(PropTypes.object).isRequired,
  showNotAssessedOption: PropTypes.bool.isRequired,
  patient: patientShape.isRequired,
};

SectionDiagnosisContainer.defaultProps = {
  completedActionsIsLoading: true,
  completedDiagnosisActions: [],
  completedDiagnosisCount: 0,
  dateOfService: null,
  incompleteDiagnosisActions: [],
  isHistoricalConditions: false,
};

export default SectionDiagnosisContainer;
