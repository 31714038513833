import styled from 'styled-components';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { arrayOf, instanceOf, shape, string } from 'prop-types';
import { isEmpty } from 'lodash';

import MutatePatientFailures from '../utils/MutatePatientFailures';

const errorShape = shape({
  title: string,
  messages: arrayOf(string),
});

function PatientSummaryError({ error }) {
  return (
    <Box>
      <Typography fontWeight="bold">{error.title}</Typography>
      {!isEmpty(error.messages) ? (
        <List dense>
          {error.messages.map((m, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem key={i}>
              <ListItemText>{m}</ListItemText>
            </ListItem>
          ))}
        </List>
      ) : null}
    </Box>
  );
}
PatientSummaryError.propTypes = {
  error: errorShape.isRequired,
};

/**
 * Given a MutatePatientFailures error type, render it out in a useful way.
 *
 * @param error
 * @returns {JSX.Element|null}
 */
export default function PatientSummaryErrors({ error }) {
  if (!error) return null;

  return (
    <ErrorBox borderRadius={2}>
      <List dense>
        {error.errors.map((e, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={i}>
            <PatientSummaryError error={e} />
          </ListItem>
        ))}
      </List>
    </ErrorBox>
  );
}

PatientSummaryErrors.propTypes = {
  error: instanceOf(MutatePatientFailures),
};

PatientSummaryErrors.defaultProps = {
  error: undefined,
};

// Utility fn for grabbing things out of the palette theme values
const palette =
  (...props) =>
  (p) =>
    props.reduce((pointer, prop) => pointer[prop], p.theme.palette);

const ErrorBox = styled(Box)`
  border: 1px solid ${palette('status', 'errorForeground')};
  background-color: ${palette('status', 'errorBackground')};
  color: ${palette('status', 'errorForeground')};
`;
