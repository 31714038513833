import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import BaseModal from '@/components/BaseModal';
import DynamicForm from '@/components/DynamicForm/DynamicForm';
import DynamicFormFields from '@/components/DynamicForm/DynamicFormFields';
import { patientShape } from '@/utils/transformFrontendPatient';
import { UsStatesTupleWithNone } from '@/utils/transformFrontendAddress';

function getDemoFields(patient) {
  return [
    {
      type: 'text',
      label: `Patient Name`,
      name: 'patient_name',
      defaultValue: `${patient.firstName} ${patient.lastName}`,
      disabled: true,
    },
    {
      type: 'text',
      label: `Date Of Birth`,
      name: 'date_of_birth',
      defaultValue: patient.dateOfBirth || '',
      disabled: true,
    },
    {
      type: 'text',
      label: 'Primary Phone Number',
      name: 'phone_number',
      defaultValue: patient.address?.phoneNumber || '',
      required: true,
    },
    {
      type: 'text',
      label: 'Secondary Phone Number',
      name: 'secondary_phone_number',
      defaultValue: patient.address?.secondaryPhoneNumber || '',
    },
    {
      type: 'text',
      label: 'Address Line 1',
      name: 'address1',
      defaultValue: patient.address?.address1 || '',
    },
    {
      type: 'text',
      label: 'Address Line 2',
      name: 'address2',
      defaultValue: patient.address?.address2 || '',
    },
    {
      type: 'text',
      label: 'City',
      name: 'city',
      defaultValue: patient.address?.city || '',
    },
    {
      type: 'dropdown',
      label: 'State',
      name: 'state',
      options: UsStatesTupleWithNone,
      defaultValue: patient.address?.state || UsStatesTupleWithNone[''],
    },
    {
      type: 'text',
      label: 'ZIP',
      name: 'zip',
      defaultValue: patient.address?.zip || '',
    },
    {
      type: 'textarea',
      label: 'Notes',
      name: 'contact_info_notes',
      defaultValue: patient.contactInfoNotes || '',
    },
  ];
}

export default function ModalPatientDemographics({
  open,
  onSubmit,
  onClose,
  patient,
}) {
  return (
    <BaseModal open={open} onClose={onClose} title="Contact Information">
      <DynamicForm onSubmit={onSubmit}>
        <Stack spacing={2}>
          <DynamicFormFields fields={getDemoFields(patient)} />
          <Stack direction="row" justifyContent="flex-end">
            <Button type="submit">Save</Button>
          </Stack>
        </Stack>
      </DynamicForm>
    </BaseModal>
  );
}

ModalPatientDemographics.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  patient: patientShape.isRequired,
};
