import { isWithinInterval, startOfDay } from 'date-fns';

export function isDateOfServiceInDateView(dateOfService, dateView) {
  return isWithinInterval(startOfDay(dateOfService), {
    start: dateView.minDate,
    end: dateView.maxDate,
  });
}

// We check if the current date of service is one selected by a date view modal.
// affects if we reload the patient's actions for the prior date or not.
export default function isValidPriorDate(dateOfService, dateViews) {
  if (!dateOfService || !dateViews) {
    return false;
  }
  // checks if the date of service is in the date view range.
  for (const dateView of dateViews) {
    if (isDateOfServiceInDateView(dateOfService, dateView)) {
      return true;
    }
  }

  return false;
}
