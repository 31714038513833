import { isSameDay } from 'date-fns';

import useDate from '@/hooks/useDate';
import useTimestampCookie from '@/hooks/useTimestampCookie';

export default function useHasSeenVisitFeedbackModalToday() {
  const [timestamp, updateTimestamp] = useTimestampCookie(
    'visit_feedback_modal_seen',
  );
  const now = useDate();

  const hasSeenVisitFeedbackModalToday = Boolean(
    timestamp && isSameDay(now, timestamp),
  );

  return [hasSeenVisitFeedbackModalToday, updateTimestamp];
}
