import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useToggle } from 'react-use';
import styled from 'styled-components';

import {
  getIconComponent,
  locationAndDateText,
} from '@/utils/pastDiagnosisUtils';
import { diagnosisShape } from '@/utils/transformFrontendAction';

function SingleDiagnosisSubtext({ diagnosis, locationString, dateString }) {
  const Icon = getIconComponent(diagnosis.sourceType);
  return (
    <>
      {/* This prevents the SVG from shrinking to accommodate other elements */}
      <Box
        component="span"
        sx={{
          flex: '0 0 16px',
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            color: 'icon.secondary',
          },
        }}
      >
        <Icon
          height={16}
          width={16}
          alt-text={diagnosis.sourceType.toLowerCase()}
        />
      </Box>
      <Box
        component="span"
        sx={{
          flex: '1 0 0',
          mx: 1,
          display: 'flex',
          flexWrap: 'wrap',
          minWidth: 0,
        }}
      >
        <Typography
          component="span"
          sx={{
            whiteSpace: 'pre',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {locationString}
        </Typography>
        <Typography component="span">{dateString}</Typography>
      </Box>
    </>
  );
}

SingleDiagnosisSubtext.propTypes = {
  diagnosis: diagnosisShape.isRequired,
  locationString: PropTypes.string,
  dateString: PropTypes.string,
};

SingleDiagnosisSubtext.defaultProps = {
  locationString: null,
  dateString: null,
};

function PastDiagnosisSubtext({ diagnoses }) {
  const [expandPastDiagnoses, toggleExpandPastDiagnoses] = useToggle(false);
  const diagnosesToDisplay = diagnoses.slice(0, expandPastDiagnoses ? 10 : 1);

  const previousCount = diagnoses.length - 1;
  const previousCountText = `${previousCount} previous`;

  return diagnosesToDisplay.map((diagnosis, i) => {
    const { locationString, dateString } = locationAndDateText(diagnosis);

    return (
      <Box
        component="span"
        sx={{
          my: 1,
          fontSize: 14,
          display: 'flex',
          alignItems: 'center',
        }}
        // eslint-disable-next-line react/no-array-index-key
        key={`${locationString}-${dateString}-${i}`}
      >
        <SingleDiagnosisSubtext
          diagnosis={diagnosis}
          locationString={locationString}
          dateString={dateString}
        />
        {i === 0 && previousCount > 0 ? (
          <PreviousText
            component="span"
            sx={{
              flex: '0 0 96px',
              pl: 1,
              borderLeft: '1px solid',
              borderLeftColor: 'border.base',
              color: 'text.secondary',
              cursor: 'pointer',
            }}
            onClick={toggleExpandPastDiagnoses}
          >
            {previousCountText}
          </PreviousText>
        ) : (
          <Box component="span" sx={{ flex: '0 0 96px' }} />
        )}
      </Box>
    );
  });
}

PastDiagnosisSubtext.propTypes = {
  diagnoses: PropTypes.arrayOf(diagnosisShape).isRequired,
};

export default PastDiagnosisSubtext;

const PreviousText = styled(Typography)`
  :hover {
    text-decoration: underline;
  }
`;
