import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import axios from 'axios';

function transformUser(user) {
  return {
    id: user.id,
    active: user.active,
    firstName: user.first_name,
    lastLogin: user.last_login,
    lastName: user.last_name,
    offices: user.offices,
    logins: [
      // cut out the weekends
      ...user.logins.slice(0, 5),
      ...user.logins.slice(7, 12),
    ],
  };
}

function transformResponse(data) {
  return {
    totalUserCount: data.total_user_count || 0,
    users: sortBy(
      data.users?.map(transformUser),
      ['lastName', 'firstName'] || [],
    ),
    officeOptions: data.office_options || [],
  };
}

async function getUserLogins({ startDate, endDate, showAll }) {
  const response = await axios.get(
    `/practice_management_dashboard/user_logins`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
        show_all: showAll,
      },
    },
  );
  return response.data;
}

export default function useGetUserLogins(params) {
  const queryKey = ['practice-management-user-logins', params];
  return useQuery(queryKey, () => getUserLogins(params || {}), {
    keepPreviousData: true,
    select: transformResponse,
  });
}

export const userShape = PropTypes.shape({
  offices: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
});
