import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { useQueryParameters } from '@/contexts/QueryParametersContext';

import queryKeyFactory from '../queryKeyFactory';
import { usePage } from '../contexts/PageContext';

function transformPreferredNextStepCounts(preferredNextStepCounts) {
  if (!Array.isArray(preferredNextStepCounts)) return [];

  return preferredNextStepCounts.map((x) => ({
    templateStep: x.template_step,
    count: x.count,
    label: x.label,
  }));
}

function transformResponse(data) {
  return {
    totalTaskCount: data.total_results,
    totalPatientCount: data.total_patients,
    preferredNextStepCounts: transformPreferredNextStepCounts(
      data.preferred_next_step_counts,
    ),
    hiddenTaskCount: data.hidden_task_count,
    filterTabCounts: data.filter_tab_counts,
    completedTasksCount: data.completed_tasks_count,
    flagReasonFilterOptions: data.flag_reason_filter_options,
  };
}

async function fetchTaskCounts({ queryKey }) {
  const [
    ,
    ,
    {
      providerIds,
      officeIds,
      planIds,
      patientTags,
      ageBuckets,
      tbwSubtypeChoices,
      avYears,
      allSubtypes,
      page,
      patientNeed,
    },
  ] = queryKey;

  const response = await axios.get(`/api/tbw/${page}/counts`, {
    params: {
      provider_ids: providerIds,
      office_ids: officeIds,
      plan_ids: planIds,
      patient_tags: patientTags,
      age_buckets: ageBuckets,
      action_subtypes: tbwSubtypeChoices,
      av_years: avYears,
      all_subtypes: allSubtypes,
      patient_need: patientNeed,
    },
  });
  return response.data;
}

export default function useGetTaskCounts({ enabled = true } = {}) {
  const { parameters } = useQueryParameters();
  return useQuery(
    queryKeyFactory.taskCounts({
      providerIds: parameters.providerIds,
      officeIds: parameters.officeIds,
      planIds: parameters.planIds,
      patientTags: parameters.patientTags,
      ageBuckets: parameters.ageBuckets,
      tbwSubtypeChoices: parameters.tbwSubtypeChoices,
      avYears: parameters.avYears,
      allSubtypes: parameters.allSubtypes,
      page: usePage(),
      patientNeed: parameters.patientNeed,
    }),
    fetchTaskCounts,
    {
      select: transformResponse,
      keepPreviousData: true,
      enabled,
    },
  );
}
