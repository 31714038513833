// eslint-disable-next-line import/prefer-default-export
export const FILTER_KEYS = [
  'actionSubtypeStates',
  'providerIds',
  'officeIds',
  'planIds',
  'patientTags',
  'ageBuckets',
  'tbwSubtypeChoices',
  'patientNeed',
];
