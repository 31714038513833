import produce from 'immer';
import { format as formatDate } from 'date-fns';

import { URL_DATE_FORMAT } from '@/pages/PatientSummary/utils/constants';

/**
 * userAddedDiagnosisCodes is a general list for each patient summary submission
 * within each individual diagnosis action form was_diagnosed will be flipped to
 * true if that action contains a new diagnosisCode in that list
 *
 * The logic for associating the userAddedDiagnosisCode to the specific action
 * is done entirely in the back-end. (see /sfe/views/patient/actions.py Line 224)
 * The HCC code the DiagnosisCode is associated to is compared to the HCC Code of
 * each diagnosis action. If these codes belong to the same family they will be
 * linked.
 */
export default function addOrUpdateDiagnosisActionForm({
  actionId,
  newState,
  oldIcdCodes,
  newIcdCodes,
  otherHcc,
  diagnosisActionForms,
}) {
  return produce(diagnosisActionForms, (draft) => {
    draft[actionId] = {
      id: actionId,
      state: newState,
      date_of_service: formatDate(new Date(), URL_DATE_FORMAT),
      other_hccs: otherHcc || '',
      was_diagnosed: newIcdCodes.length > 0,
    };

    const icdsToRemove = oldIcdCodes.filter((x) => !newIcdCodes.includes(x));
    const icdsToAdd = newIcdCodes.filter((x) => !oldIcdCodes.includes(x));

    draft[actionId].user_added_diagnosis_codes = [
      ...oldIcdCodes.filter((x) => !icdsToRemove.includes(x)),
      ...icdsToAdd,
    ];
    draft.userAddedDiagnosisCodes = [
      ...(draft.userAddedDiagnosisCodes || []).filter(
        (x) => !icdsToRemove.includes(x),
      ),
      ...icdsToAdd,
    ];
  });
}
