import styled from 'styled-components';
import { useState } from 'react';
import { Divider, Popover, Typography } from '@mui/material';

import focusOutline from '@/mixins/focusOutline';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';
import { actionHelpShape } from '@/utils/transformFrontendCareGapAction';
import BaseLinkButton from '@/components/BaseLinkButton';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

function ActionHelp({ help }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const contentNode =
    // Using type 'object' as a proxy for React element nodes. There's
    // certainly a more robust way to do this, but this is good enough.
    typeof help.content === 'object' ? (
      <PopoverDiv>{help.content}</PopoverDiv>
    ) : (
      <PopoverDiv
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: help.content }}
      />
    );

  return (
    <>
      {help.text ? (
        <BaseLinkButton
          tabIndex="0"
          onClick={handlePopoverOpen}
          onKeyDown={(e) => e.key === 'Enter' && handlePopoverOpen(e)}
          data-pendo-id="ps-gap-help-text"
        >
          {help.text}
        </BaseLinkButton>
      ) : (
        <StyledInfoIcon
          aria-label="Action help"
          tabIndex="0"
          onClick={handlePopoverOpen}
          onKeyDown={(e) => e.key === 'Enter' && handlePopoverOpen(e)}
          data-pendo-id="ps-gap-info-icon"
        />
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handlePopoverClose}
      >
        <Typography component="h4" sx={{ m: 2 }}>
          {help.title}
        </Typography>
        <StyledButton aria-label="Close modal" onClick={handlePopoverClose}>
          <CloseIcon />
        </StyledButton>
        <StyledDivider />
        {contentNode}
      </Popover>
    </>
  );
}

export default ActionHelp;

ActionHelp.propTypes = {
  help: actionHelpShape,
};

ActionHelp.defaultProps = {
  help: undefined,
};

const StyledDivider = styled(Divider)`
  color: ${(p) => p.theme.palette.border.base};
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: ${(p) => p.theme.palette.icon.link};
  ${focusOutline}
`;

const PopoverDiv = styled.div`
  width: 500px;
  margin: 16px;
  p {
    margin-bottom: 8px;
  }
`;

const StyledButton = styled.button`
  line-height: 0;
  position: absolute;
  right: 16px;
  top: 16px;

  color: ${(p) => p.theme.palette.icon.primary};

  :hover {
    color: ${(p) => p.theme.palette.icon.link};
  }
`;
