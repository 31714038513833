import styled from 'styled-components';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import useId from '@mui/utils/useId';

import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';
import focusOutline from '@/mixins/focusOutline';
import TransitionOfCarePopover from '@/components/TransitionOfCarePopover';

function ActionUpdatedInfo() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverId = useId();
  return (
    <>
      <Typography>
        Updated due to a more recent discharge event{' '}
        <button
          type="button"
          aria-describedby={popoverId}
          aria-label="Transition of care update info"
          onClick={handleClick}
        >
          <StyledInfoIcon />
        </button>
      </Typography>

      <TransitionOfCarePopover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      />
    </>
  );
}

export default ActionUpdatedInfo;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin-left: 2px;
  color: ${(p) => p.theme.palette.icon.secondary};
  ${focusOutline}
`;
