import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export type User = {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  medicalGroupName: string;
  medicalGroupId: number;
};

async function fetchUser(): Promise<User> {
  const response = await axios.get('/api/user');
  return response.data as User;
}

export default function useGetUser({ enabled = true } = {}) {
  return useQuery({
    queryFn: fetchUser,
    queryKey: ['user'],
    enabled,
  });
}
