import { styled } from '@mui/material';

import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';
import { ReactComponent as HighNeedIcon } from '@/assets/icons/highNeed.svg';
import { HIGH_NEED_PATIENT } from '@/pages/TaskBasedWorkflow/constants';
import PatientNeedTooltip from '@/components/PatientNeedTooltip';

const StyledHighNeedIcon = styled(HighNeedIcon)`
  width: 16px;
  height: 16px;
  fill: ${(p) => p.theme.palette.colorPool.purple[600]};
`;

type PatientNeedProp = {
  task: userInputTaskShape;
};

export default function PatientNeed({ task }: PatientNeedProp) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return task?.patient && task.patient.patientNeed === HIGH_NEED_PATIENT ? (
    <PatientNeedTooltip>
      <StyledHighNeedIcon fill="blue" />
    </PatientNeedTooltip>
  ) : null;
}
