import { useCallback, useEffect, useState } from 'react';
import { formatISO, isValid, parseISO, startOfYear } from 'date-fns';

import useHistory from '@/utils/useHistory';

import getPatientIdFromUrl from '../utils/getPatientIdFromUrl';
import isValidPriorDate from '../utils/isValidPriorDate';

import useGetPriorDateView from './useGetPriorDateView';

/**
 * Given a date or null, ensure that the given date falls within the bounds
 * that are valid for this hook. Namely: dates should not be in the future, nor
 * should they be more than a year in the past.
 *
 * Dates that exceed these boundaries are cast to null.
 *
 * @param {Date|null} date
 * @returns {Date|null}
 */
function constrainDateOfService(date) {
  if (date instanceof Date) {
    if (!isValid(date)) return null;

    const today = new Date();
    // Disallow dates after today:
    if (date > today) return null;
    // Disallow dates before last year:
    if (date.getFullYear() < today.getFullYear() - 1) return null;

    // Dates are mutable, so clone our input, just to be safe:
    return new Date(date);
  }
  return null;
}

/**
 * Checks the current URL for a date_of_service param and, if it exists, it
 * attempts to parse the date as an ISO8601 date string. If that string is
 * valid, it constrains it using `constrainDateOfService`, otherwise this
 * function will return null.
 *
 * @param history {HistoryUtils}
 * @returns {Date|null}
 */
function getDateOfServiceFromParams(history) {
  const url = new URL(history.getCurrentLocation());
  const searchParamState = url.searchParams.get('date_of_service');

  if (searchParamState && isValid(parseISO(searchParamState))) {
    const date = parseISO(searchParamState);
    return constrainDateOfService(date);
  }
  return null;
}

/**
 * Returns true if the date provided is last year or earlier.
 *
 * @param dateOfService {Date}
 * @returns {boolean}
 */
export function isPriorYearDate(dateOfService) {
  const today = new Date();
  return dateOfService && dateOfService < startOfYear(today);
}

/**
 * A hook for managing the PatientSummaryPage date-of-service.
 *
 * It exposes an API similar to `useState` but does not accept an initial
 * value—instead, it reads the initial value from the URL (if possible) or
 * sets to null.
 *
 * This hook does two things: it maintains and validates the patient date of
 * service, and if the user is viewing a _historical_ date (defined as any
 * date in the previous calendar year), it will update the URL.
 *
 * Updating the URL in this way maintains behavioral backwards compatibility
 * with the v1 patient summary UI.
 *
 * This hook also validates changes to the date of service using
 * `constrainDateOfService` defined above.
 *
 * @returns [Date | null, (date: Date | null) => void]
 */
export default function useDateOfServiceManager() {
  const history = useHistory();

  const [dateOfService, setDateOfService] = useState(() =>
    getDateOfServiceFromParams(history),
  );

  useEffect(() => {
    return history.addPopListener(() => {
      setDateOfService(getDateOfServiceFromParams(history));
    });
  }, [history]);
  const patientId = getPatientIdFromUrl(window.location.pathname);
  const { data: dateViews } = useGetPriorDateView(patientId);
  const isViewingPriorDate = isValidPriorDate(dateOfService, dateViews);
  useEffect(() => {
    const url = new URL(history.getCurrentLocation());
    if (dateOfService && isViewingPriorDate) {
      url.searchParams.set(
        'date_of_service',
        formatISO(dateOfService, { representation: 'date' }),
      );
    } else {
      url.searchParams.delete('date_of_service');
    }
    history.push(url);
  }, [dateOfService, history, isViewingPriorDate, patientId]);

  const setDateOfServiceFn = useCallback((date) => {
    setDateOfService(constrainDateOfService(date));
  }, []);

  return [dateOfService, setDateOfServiceFn];
}
