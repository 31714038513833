import { createElement, useMemo } from 'react';
import { func, object } from 'prop-types';
import { useFormContext } from 'react-hook-form';

import ModalDatePicker from '@/pages/PatientSummary/components/modals/ModalDatePicker';
import ModalTextArea from '@/pages/PatientSummary/components/modals/ModalTextArea';
import ModalTextField from '@/pages/PatientSummary/components/modals/ModalTextField';
import ModalNumberField from '@/pages/PatientSummary/components/modals/ModalNumberField';
import ModalFloatField from '@/pages/PatientSummary/components/modals/ModalFloatField';
import ModalSelect from '@/pages/PatientSummary/components/modals/ModalSelect';
import ModalRatingDropdown from '@/pages/PatientSummary/components/modals/ModalRatingDropdown';
import ModalCheckboxList from '@/pages/PatientSummary/components/modals/ModalCheckboxList';
import ModalRadioGroup from '@/pages/PatientSummary/components/modals/ModalRadioGroup';
import ModalFileUpload from '@/pages/PatientSummary/components/modals/ModalFileUpload';
import ModalTextDisplay from '@/pages/PatientSummary/components/modals/ModalTextDisplay';
import ModalTextDisplayBold from '@/pages/PatientSummary/components/modals/ModalTextDisplayBold';
import ModalProviderDropdown from '@/pages/PatientSummary/components/modals/ModalProviderDropdown';
import ModalOfficeDropdown from '@/pages/PatientSummary/components/modals/ModalOfficeDropdown';
import ModalInformationalText from '@/pages/PatientSummary/components/modals/ModalInformationalText';
import DynamicFormReferral from '@/components/DynamicForm/fieldComponents/DynamicFormReferral';
import ModalCheckbox from '@/pages/PatientSummary/components/modals/ModalCheckbox';
import DynamicFormYesNoRadioGroups from '@/components/DynamicForm/fieldComponents/DynamicFormYesNoRadioGroups';
import DynamicFormCheckbox from '@/components/DynamicForm/fieldComponents/DynamicFormCheckbox';
import DiabeticEyeExamReferral from '@/pages/TaskBasedWorkflow/components/patientDetailView/stepExecutionPanel/DiabeticEyeExamReferral';
import DynamicFormRiskFactors from '@/components/DynamicForm/fieldComponents/DynamicFormRiskFactors';
import DynamicFormPhoneNumberInput from '@/components/DynamicForm/fieldComponents/DynamicFormPhoneNumberInput';
import DynamicFormOutsideProviderDropdownWrapper from '@/components/DynamicForm/fieldComponents/DynamicFormOutsideProviderDropdownWrapper';

/**
 * A default mapping from `field.type` to a concrete React component.
 *
 * @type Record<string, Function>
 */
const FIELD_MAPPING = {
  checkboxlist: ModalCheckboxList,
  checkbox: ModalCheckbox,
  date: ModalDatePicker,
  dropdown: ModalSelect,
  fileupload: ModalFileUpload,
  int: ModalNumberField,
  integer: ModalNumberField, // Alias of int
  float: ModalFloatField,
  radio: ModalRadioGroup,
  radiogroup: ModalRadioGroup, // Alias of radio
  text: ModalTextField,
  label: ModalTextDisplay,
  boldlabel: ModalTextDisplayBold,
  textarea: ModalTextArea,
  provider: ModalProviderDropdown,
  office: ModalOfficeDropdown,
  ratingDropdown: ModalRatingDropdown,
  informationalText: ModalInformationalText,
  referral: DynamicFormReferral,
  yesnoradiogroups: DynamicFormYesNoRadioGroups,
  removeCheckbox: DynamicFormCheckbox,
  diabeticEyeExamReferral: DiabeticEyeExamReferral,
  riskFactors: DynamicFormRiskFactors,
  phoneInput: DynamicFormPhoneNumberInput,
  outsideProvider: DynamicFormOutsideProviderDropdownWrapper,
};

/**
 * A function that performs the mapping from a Stellar action form field to a
 * concreate React component.
 *
 * @param field {unknown}
 * @returns {any}
 */
export function defaultFieldComponentMapper(field) {
  // Design note: this relies on the FIELD_MAPPING constant in this file, but is
  // implemented as a function. The intention here is to be future-compatible, to
  // allow future field types to be selected by arbitrary business logic. --NH
  if (field === null)
    throw new TypeError(`Expected object for field, received null`);

  if (typeof field !== 'object')
    throw new TypeError(`Expected object for field, received ${typeof field}`);

  if (typeof field.type !== 'string')
    throw new TypeError(`Missing field type property`);

  if (field.type in FIELD_MAPPING) {
    return FIELD_MAPPING[field.type];
  }
  throw new RangeError(`Unexpected field type ${field.type}`);
}

/**
 * The contract that the Modal form fields follow is slightly different from
 * the agreed-upon contract for dynamic form fields. This function maps
 * between those two formats and warns if there are inconsistent usages. This
 * is mostly a sniff check that shouldn't be depended on to catch all cases.
 *
 * @param field
 * @returns {{required}|*}
 */
export function fieldPropMapper(field) {
  const out = { ...field };

  // Step 1: Adapt radio groups
  //         ModalRadioGroup expects a tuple-array of options, but step-based
  //         actions provide a tuple-array of choices. Rename the property and
  //         let future steps rework the tuple-array part.
  if ('choices' in out) {
    out.options = out.choices;
    delete out.choices;
  }

  // Step 2: Rework `options`
  //         Modal selects and checkboxes expect an array of objects in the
  //         form { label: "human-readable", value: "machine value" }, but this
  //         is not what we expect from the dynamic form description. Instead,
  //         it will pass a tuple of ["machine value", "human readable"]. This
  //         step remaps from one format to the other.
  if ('options' in out) {
    out.options = out.options.map((opt) => {
      if (Array.isArray(opt)) {
        const [value, label, checked] = opt;
        return { label, value, checked };
      }
      // TODO: warn if `opt` is already an object
      // TODO: filter out non-conformant entries and warn
      return opt;
    });
  }

  // Step 3: Rework `required`
  //         The dynamic form description includes a boolean indicating if the
  //         field is required or not. Modal form fields expect that required
  //         fields be provided an errorMsg prop. This indicates that the field
  //         is required and also specifies the error message to render when
  //         that field is not filled. Here we generate an errorMsg from the
  //         field label if the field is indicated as required.
  // TODO: warn if errorMsg is already specified
  // TODO: warn if label is not a string
  if (out.required && out.errorMsg === undefined) {
    delete out.required;
    out.errorMsg = `${out.label} is required.`;
  }

  // Step 4: Modify inputSx
  //         If the dynamic form is a text label, we want to preserve white
  //         space from the json. We modify InputSx to pass this in.
  if (out.type === 'label') {
    out.InputSx = { ...out.InputSx, whiteSpace: 'pre-wrap' };
  }
  return out;
}

/**
 * Given a form field on an action, select and render the appropriate form
 * UI for that field. This component takes one prop:
 *
 * - field: the comprehensive field definition for this form field,
 *     appropriate for its polymorphic type. (e.g. if the type is "date" it
 *     might include values like minYear and maxYear)
 *
 * It then pulls the form information out of context and passes it to the
 * dispatched component.
 *
 * These props are passed through to the underlying field component
 * implementation without validation.
 */
function DynamicFormField({ field, fieldComponentMapper }) {
  const form = useFormContext();
  if (!form) {
    throw new RangeError(
      `Missing form context, did you forget to wrap in <DynamicForm />?`,
    );
  }

  const modifiedProps = useMemo(() => fieldPropMapper(field), [field]);

  const FieldComponent =
    fieldComponentMapper(field) ??
    // Fall back to defaultFieldComponentMapper if fieldComponentWrapper
    // doesn't return anything
    defaultFieldComponentMapper(field);
  // Extra paranoia: make sure the thing we get back is a React component,
  // or nearly enough that we can't tell one way or the other:

  if (
    typeof FieldComponent !== 'function' &&
    FieldComponent.$$typeof !== Symbol.for('react.forward_ref')
  ) {
    throw new RangeError(
      'fieldComponentMapper did not produce a valid React component',
    );
  }

  return createElement(FieldComponent, {
    control: form.control,
    errors: form.formState.errors,
    field,
    ...modifiedProps,
  });
}

DynamicFormField.propTypes = {
  field: object.isRequired,
  fieldComponentMapper: func,
};

DynamicFormField.defaultProps = {
  fieldComponentMapper: defaultFieldComponentMapper,
};

export default DynamicFormField;
