import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

import transformActionForms from '@/pages/PatientSummary/utils/transformActionForms';
import useAxios from '@/hooks/useAxios';
import useCsrfToken from '@/hooks/useCsrfToken';
import cleanExecutionInput from '@/utils/cleanExecutionInput';
import { isPriorYearDate } from '@/pages/PatientSummary/hooks/useDateOfServiceManager';

import queryKeyFactory from '../queryKeyFactory';
import MutatePatientFailures from '../utils/MutatePatientFailures';
import { URL_DATE_FORMAT } from '../utils/constants';

async function uploadFile(fileInfo, axios, actionInfo, csrfToken) {
  const fileForm = new FormData();
  fileForm.append('file', fileInfo.file, fileInfo.file.name);
  fileForm.append('type', actionInfo.subtype);
  fileForm.append('csrfmiddlewaretoken', csrfToken);
  if (fileInfo.actionId) {
    fileForm.append('action_id', fileInfo.actionId);
  }

  return axios.post('upload_file', fileForm);
}

async function uploadFiles(
  { axios, csrfToken },
  {
    patientId,
    actionFormStateV1,
    actionFormStateV2,
    incompleteActionsV1,
    incompleteCareGapActionsV2,
  },
) {
  const filesToUpload = [];
  Object.values(actionFormStateV1).forEach((actionForm) => {
    if (Object.hasOwn(actionForm, 'file')) {
      actionForm.file.forEach((file) =>
        filesToUpload.push({
          actionId: actionForm.id,
          file,
        }),
      );
    }
  });

  Object.entries(actionFormStateV2).forEach(([actionId, actionForm]) => {
    Object.entries(actionForm).forEach(([stepId, actionStepForm]) => {
      if (Object.hasOwn(actionStepForm, 'file')) {
        actionStepForm.file.forEach((file) =>
          filesToUpload.push({
            actionId,
            stepId,
            file,
          }),
        );
      }
    });
  });

  const responses = await Promise.all(
    filesToUpload.map(async (fileInfo) => {
      const actionV1Info = incompleteActionsV1.filter(
        (action) => action.id === fileInfo.actionId,
      )[0];
      const actionV2Info = incompleteCareGapActionsV2.filter(
        (action) => action.actionId.toString() === fileInfo.actionId,
      )[0];
      const actionInfo = actionV1Info || actionV2Info;

      try {
        const { data } = await uploadFile(
          fileInfo,
          axios,
          actionInfo,
          csrfToken,
          patientId,
        );

        if (data.success) {
          return {
            file_id: data.file_id,
            status: 200,
            stepId: fileInfo.stepId,
          };
        }
        return data;
      } catch (e) {
        return e.response.data;
      }
    }),
  );
  const failedResponses = responses.filter((response) => !response.status);
  if (failedResponses.length > 0) {
    return Promise.reject(failedResponses.map((failure) => failure.message));
  }
  return responses;
}

/**
 * This function handles the updating of non-step-based actions,
 * sometimes called V1 actions. It posts FormData rather than
 * JSON, so some extensive transformation is performed on the
 * input data to get it into a format the server can consume.
 *
 * @param axios
 * @param csrfToken
 * @param providerId
 * @param dateOfService
 * @param actionFormStateV1
 * @param incompleteActionsV1
 * @param correlationUid
 * @param dxActionsNotAssessedIds
 * @returns {Promise<*>}
 */
async function updateActionFormsV1(
  { axios, csrfToken },
  {
    providerId,
    dateOfService,
    actionFormStateV1,
    incompleteActionsV1,
    correlationUid,
    dxActionsNotAssessedIds,
  },
) {
  const transformedActionForms = transformActionForms(
    actionFormStateV1,
    dateOfService,
    providerId,
    incompleteActionsV1,
    correlationUid,
  );
  // HOTFIX: for current-year updates, the `/dos/` parameter should always be
  //         today and no other day, otherwise this causes submission failures
  //         when the date of service the user selects is before the starts_at
  //         date of submitted actions. Prior-year submissions are a different
  //         kettle of fish.
  const dosParam = isPriorYearDate(dateOfService) ? dateOfService : new Date();

  await axios.post(
    `v2/actions/update/dos/${format(dosParam, URL_DATE_FORMAT)}`,
    transformedActionForms,
    {
      params: { not_assessed_dx_action_ids: dxActionsNotAssessedIds },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'X-CSRFToken': csrfToken,
      },
    },
  );
}

/**
 * This function handles the updating of step-based actions. It collects a
 * fairly sparse data structure representing all the step-based action changes
 * and uses that to generate a richer data structure that the server can
 * consume.
 *
 * @param axios
 * @param patientId
 * @param providerId
 * @param dateOfService
 * @param actionFormStateV2
 * @param uploadFileResponses
 * @param dxActionsNotClosedReasons
 * @param dxActionsNotClosedComments
 * @param correlationUid
 * @param unchangedV2actionIds
 * @returns {Promise<*>}
 */
async function updateActionFormsV2(
  axios,
  {
    patientId,
    providerId,
    dateOfService,
    actionFormStateV2,
    uploadFileResponses,
    dxActionsNotClosedReasons,
    dxActionsNotClosedComments,
    correlationUid,
    unchangedV2actionIds,
  },
) {
  const executionStepsRequest = {
    patient_id: patientId,
    servicing_provider_id: providerId,
    date_of_service: format(dateOfService, URL_DATE_FORMAT),
    execution_requests: [],
    dx_actions_not_closed_reasons: dxActionsNotClosedReasons,
    dx_actions_not_closed_comments: dxActionsNotClosedComments,
    correlation_uid: correlationUid,
    unchanged_action_ids: unchangedV2actionIds,
  };

  Object.entries(actionFormStateV2).forEach(([actionId, stepRequest]) => {
    Object.entries(stepRequest).forEach(([stepId, executionInput]) => {
      const executionInputCleaned = cleanExecutionInput({
        stepId,
        executionInput,
        dateOfService,
        uploadFileResponses,
      });
      executionStepsRequest.execution_requests.push({
        action_id: Number(actionId),
        step_id: stepId,
        execution_input: executionInputCleaned,
      });
    });
  });

  if (executionStepsRequest.execution_requests.length === 0) return null;

  return axios.post(
    `/api/patient_summary/actions/execute`,
    executionStepsRequest,
  );
}

async function mutator(
  { axios, csrfToken },
  {
    patientId,
    providerId,
    dateOfService,
    actionFormStateV1,
    actionFormStateV2,
    incompleteActionsV1,
    incompleteCareGapActionsV2,
    removeActionFormStateV2,
    dxActionsNotClosedReasons,
    dxActionsNotClosedComments,
    dxActionsNotAssessedIds,
  },
) {
  let uploadFileResponses = [];
  const dateOfServiceOrToday = dateOfService || new Date();
  const correlationUid = crypto.randomUUID();

  try {
    uploadFileResponses = await uploadFiles(
      { axios, csrfToken },
      {
        patientId,
        dateOfService: dateOfServiceOrToday,
        actionFormStateV1,
        actionFormStateV2,
        incompleteActionsV1,
        incompleteCareGapActionsV2,
      },
    );
  } catch (e) {
    throw new MutatePatientFailures([e, null, null]);
  }

  const updatedActionKeys = Object.keys(actionFormStateV2);
  const unchangedV2actionIds = incompleteCareGapActionsV2
    .map((obj) => obj.actionId)
    .filter((actionId) => !updatedActionKeys.includes(actionId.toString()));

  return Promise.allSettled([
    updateActionFormsV1(
      { axios, csrfToken },
      {
        providerId,
        dateOfService: dateOfServiceOrToday,
        actionFormStateV1,
        incompleteActionsV1,
        correlationUid,
        dxActionsNotAssessedIds,
      },
    ),
    updateActionFormsV2(axios, {
      patientId,
      providerId,
      dateOfService: dateOfServiceOrToday,
      actionFormStateV2,
      uploadFileResponses,
      dxActionsNotClosedReasons,
      dxActionsNotClosedComments,
      correlationUid,
      // We need to pass unchanged action to V2 in order to keep track of them on the backend
      unchangedV2actionIds,
    }),
  ]).then((responses) => {
    if (responses.some((r) => r.status === 'rejected')) {
      // In addition to invalidating the queries so the page data is reloaded,
      // we set the successful v2 action ids for removal from the form.
      const v2SuccessIds = responses[1].reason?.response?.data.reduce(
        (result, a) => {
          if (a.message === 'Step execution successful') {
            result.push(a.action_id);
          }
          return result;
        },
        [],
      );
      v2SuccessIds?.forEach((id) => {
        const actionToRemove = incompleteCareGapActionsV2.find(
          (a) => a.actionId === id,
        );
        removeActionFormStateV2({ patientAction: actionToRemove });
      });
      // At least one of the requests failed, so gather the rejections and
      // throw them as an error, contained by a MutatePatientFailures error
      // class. This gets us a stack trace (if we want it), and the ability to
      // rethrow (if we want to).
      throw new MutatePatientFailures([
        null,
        ...responses.map((r) => r.reason),
      ]);
    }

    // At no point is the normal response of either API call used by the
    // frontend: instead it invalidates the queries so the page data is
    // reloaded. To emphasize this, we just return null from the mutate
    // function here.
    return null;
  });
}

export default function useMutatePatientActionsV3() {
  const axios = useAxios();
  const csrfToken = useCsrfToken();
  const queryClient = useQueryClient();

  return useMutation(mutator.bind(null, { csrfToken, axios }), {
    onSettled: async (data, error, variables) => {
      // Re-fetch all the information from the patient summary, assume none of
      // it is up-to-date.
      await queryClient.invalidateQueries(
        queryKeyFactory.patient({ patientId: variables.patientId }),
      );
    },
  });
}
