import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import SubmissionModal from '@/components/SubmissionModal';
import PatientSummaryErrors from '@/pages/PatientSummary/components/PatientSummaryErrors';

const SUBMISSION_MESSAGE = (
  <Box mt={1}>
    <Typography
      variant="bodybold"
      sx={{
        bgcolor: 'status.successBackground',
        borderRadius: 2,
        color: 'stellar.successForeground',
        p: 1.5,
        textAlign: 'center',
      }}
    >
      Appropriately document selected diagnoses in chart and include on bill to
      receive pending SVUs.
    </Typography>
  </Box>
);

export default function ModalSubmitted({ error, onClose, open, state }) {
  return (
    <SubmissionModal onClose={onClose} open={open} state={state}>
      {error ? <PatientSummaryErrors error={error} /> : null}
      {state === 'success' ? SUBMISSION_MESSAGE : null}
    </SubmissionModal>
  );
}

ModalSubmitted.propTypes = {
  error: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  state: PropTypes.oneOf(['idle', 'loading', 'success', 'error']).isRequired,
};

ModalSubmitted.defaultProps = {
  error: null,
};
