import PropTypes from 'prop-types';
import { Box, Divider, Link, Typography, styled } from '@mui/material';
import { get } from 'lodash';

import { patientShape } from '@/pages/MedicationManagement/hooks/useGetPatients';
import PatientProgramEnrollments from '@/pages/PatientSummary/components/PatientProgramEnrollments';
import { usePage } from '@/pages/TaskBasedWorkflow/contexts/PageContext';
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';
import { actionShape } from '@/utils/transformFrontendAction';
import { StepExecutionProvider } from '@/pages/TaskBasedWorkflow/components/patientDetailView/stepExecutionPanel/StepExecutionContext';
import useFeatureFlag from '@/utils/useFeatureFlag';
import { ReactComponent as HighNeedIcon } from '@/assets/icons/highNeed.svg';
import PatientNeedTooltip from '@/components/PatientNeedTooltip';

import PriorityBadge from '../PriorityBadge';
import { HIGH_NEED_PATIENT, Page } from '../../constants';

import UserDrivenEnrollmentFormWidget from './UserDrivenEnrollmentFormWidget';
import OutreachLogModal from './OutreachLogModal';
import PatientTags from './PatientTags';

const FIELDS = [
  { label: 'DOB', field: 'dateOfBirth' },
  { label: 'Provider', field: 'provider.name' },
  { label: 'Plan', field: 'plan.name' },
  { label: 'Phone', field: 'address.phoneNumber' },
];
const ACTION_FIELDS = [
  { label: 'Next Annual', field: 'nextAnnualVisitDate' },
  { label: 'Most recent Annual', field: 'mostRecentAnnualVisitDate' },
  { label: 'Most recent other visit', field: 'mostRecentOtherVisit' },
];

export default function PatientBanner({
  hidePriority,
  patient,
  selectedAction,
  sx,
}) {
  const { firstName, lastName, id, address, tags, userDrivenEnrollmentForms } =
    patient;

  const page = usePage();

  const isSingleTaskList = Boolean(page === Page.tasks);
  const isHighNeedPatientUxEnabled = useFeatureFlag(
    'enable_high_need_patients_ux',
  );
  const isHighNeedPatient =
    isHighNeedPatientUxEnabled && patient.patientNeed === HIGH_NEED_PATIENT;
  const textColor = isHighNeedPatient ? 'white' : 'text.primary';
  return (
    <PatientBannerRoot
      sx={{
        ...sx,
        backgroundColor: isHighNeedPatient
          ? 'colorPool.purple.700'
          : 'transparent',
        color: textColor,
      }}
    >
      <Box display="flex">
        {isHighNeedPatient ? (
          <div>
            <PatientNeedTooltip>
              <StyledHighNeedIcon />
            </PatientNeedTooltip>
          </div>
        ) : null}
        <div>
          <Box display="flex">
            {isHighNeedPatient ? (
              <Box display="flex" flexDirection="column">
                <Typography
                  variant="h4"
                  component="h1"
                  data-dd-synthetics-id="tbw-patient-summary-link"
                  data-pendo-id="worklist-patient-summary-link"
                >
                  <StyledLink
                    href={`/patient/${id}/summary`}
                    data-dd-action-name="Patient Name"
                    sx={{
                      color: textColor,
                      '&:hover': {
                        color: isHighNeedPatient ? 'white' : 'text.link',
                      },
                    }}
                  >
                    {lastName}, {firstName}
                  </StyledLink>
                </Typography>
                <Typography sx={{ fontSize: '12px' }}>
                  High-need patient
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="h2"
                component="h1"
                data-dd-synthetics-id="tbw-patient-summary-link"
                data-pendo-id="worklist-patient-summary-link"
              >
                <StyledLink
                  href={`/patient/${id}/summary`}
                  data-dd-action-name="Patient Name"
                >
                  {lastName}, {firstName}
                </StyledLink>
              </Typography>
            )}

            {!hidePriority ? (
              <Box ml={2}>
                <PriorityBadge patient={patient} />
              </Box>
            ) : null}

            <Box
              display="flex"
              justifyContent="left"
              alignItems="center"
              flexGrow={1}
              sx={{ ml: 1 }}
            >
              {!isSingleTaskList && (
                <Box display="flex">
                  <PatientProgramEnrollments
                    patientName={`${patient.firstName} ${patient.lastName}`}
                    visuallyHidden={false}
                    useBannerButton
                    patientId={patient.id}
                  />
                  <Divider
                    orientation="vertical"
                    sx={{ mt: 1.5, height: '16px', ml: 0.25, mr: 0.25 }}
                  />
                </Box>
              )}
              {!isSingleTaskList &&
                userDrivenEnrollmentForms &&
                Object.keys(userDrivenEnrollmentForms).map((key) => {
                  return (
                    <Box display="flex">
                      <UserDrivenEnrollmentFormWidget
                        patientId={id}
                        key={key}
                        subtype={key}
                        userDrivenEnrollmentForm={
                          userDrivenEnrollmentForms[key]
                        }
                      />
                      <Divider
                        orientation="vertical"
                        sx={{ mt: 1.5, height: '16px', ml: 0.25, mr: 0.25 }}
                      />
                    </Box>
                  );
                })}
              <StepExecutionProvider patient={patient} action={selectedAction}>
                <OutreachLogModal
                  patientId={id}
                  patientName={`${firstName} ${lastName}`}
                  patientPhone={address?.phoneNumber}
                  selectedAction={selectedAction}
                  isHighNeedPatient={isHighNeedPatient}
                />
              </StepExecutionProvider>
              {isSingleTaskList && (
                <>
                  <Typography variant="bodymediumbold">
                    Last outreach:&nbsp;
                  </Typography>

                  <Typography component="p" variant="bodymedium">
                    {selectedAction ? (
                      get(
                        selectedAction.v2Dto.contextualInfo,
                        'lastOutreachDate',
                        '--',
                      )
                    ) : (
                      <FieldSkeleton />
                    )}
                  </Typography>
                </>
              )}
            </Box>
          </Box>

          <Box display="flex" mt={1} gap={3}>
            {FIELDS.map(({ label, field }, fieldIndex) => (
              <Box ml={fieldIndex > 0 ? 4 : 0} key={label}>
                <Typography component="div" variant="bodysmallbold">
                  {label}
                </Typography>
                <Typography component="div" variant="bodymedium">
                  {get(patient, field, 'Unknown')}
                </Typography>
              </Box>
            ))}
            {isSingleTaskList &&
              ACTION_FIELDS.map(({ label, field }, fieldIndex) => (
                <Box ml={fieldIndex > 0 ? 4 : 0} key={label}>
                  <Typography component="div" variant="bodysmallbold">
                    {label}
                  </Typography>
                  <Typography component="div" variant="bodymedium">
                    {selectedAction ? (
                      get(selectedAction.v2Dto.contextualInfo, field, '--')
                    ) : (
                      <FieldSkeleton />
                    )}
                  </Typography>
                </Box>
              ))}
          </Box>

          {tags.length > 0 ? <PatientTags patient={patient} /> : null}
        </div>
      </Box>
    </PatientBannerRoot>
  );
}

PatientBanner.propTypes = {
  hidePriority: PropTypes.bool,
  patient: patientShape.isRequired,
  selectedAction: actionShape,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PatientBanner.defaultProps = {
  hidePriority: PropTypes.false,
  selectedAction: undefined,
  sx: undefined,
};

const PatientBannerRoot = styled('div')`
  padding: ${(p) => p.theme.spacing(2, 3)};
  border-bottom: 1px solid;
  border-bottom-color: ${(p) => p.theme.palette.border.base};
`;

// This link doesn't follow our style guide.
// It's supposed to be a temporary style, so please do not copy the styles or component below.
const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,

  '&:hover': {
    color: theme.palette.text.link,
  },
}));

const StyledHighNeedIcon = styled(HighNeedIcon)`
  width: 35px;
  height: 35px;
  margin-top: 5px;
  margin-right: 16px;
  fill: white;
`;
