import { Box, Button, Select, styled } from '@mui/material';

import { ReactComponent as UserIcon } from '@/assets/icons/neutral.svg';
import { ReactComponent as EditUserIcon } from '@/assets/icons/edit.svg';
import { NavbarDivider } from '@/components/TheGlobalNavbar/components/TheGlobalNavbar';

export const StyledUserIcon = styled(UserIcon)`
  color: ${(p) => p.theme.palette.colorPool.purple['900']};
  margin-right: ${(p) => p.theme.spacing(1)};
`;

export const StyledEditUserIcon = styled(EditUserIcon)`
  color: rgba(91, 87, 234, 1);
`;

export const StyledUserDropdownButton = styled(Button)`
  color: black;
  background-color: rgba(248, 248, 255, 1) !important;
  width: 320px;
  max-width: 320px;
  box-sizing: border-box;
  min-height: 40px;
  border: none !important;
  border-radius: 300px;
  cursor: pointer;
  justify-content: left;
  padding: 8px 16px;

  & .MuiButton-endIcon {
    margin-left: auto;
    opacity: 0;
    transition: opacity 0s ease;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  &:hover {
    background-color: ${(p) =>
      p.theme.palette.background.primaryLighter} !important;
    color: ${(p) => p.theme.palette.text.link} !important;
    svg {
      color: rgba(91, 87, 234, 1);
    }

    .MuiButton-endIcon {
      opacity: 1;
      margin-left: auto;
    }
  }

  .MuiPaper-root > ul {
    background-color: lightblue;
  }
`;

export const StyledUnassignedUserDropdownButton = styled(
  StyledUserDropdownButton,
)`
  color: rgba(88, 88, 100, 1);
  svg {
    color: #bfbfca;
  }
`;

export const StyledSelect = styled(Select)`
  padding: 0;
  color: ${(p) => p.theme.palette.text.primary} !important;
  width: 320px;
  justify-items: center;
  min-height: 40px;

  & ul {
    margin-top: 100px !important;
    padding-top: 100px !important;
  }
  & #mui-component-select-user {
    padding-left: 10px;
  }
`;

// Styled Components
export const MainBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
}));

export const BannerDivider = styled(NavbarDivider)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  height: '120px',
}));

export const SubBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  flexGrow: 1,
  gap: '40px',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export const SummaryBannerRoot = styled('div')`
  background-color: ${(p) => p.theme.palette.background.base};
  border: 1px solid ${(p) => p.theme.palette.border.base};
  display: flex;
  padding: ${(p) => p.theme.spacing(2, 4)};
  border-radius: ${(p) => 2 * p.theme.shape.borderRadius}px;
  align-items: center;
`;

export const StyledTocSummaryBannerRoot = styled(SummaryBannerRoot)(
  ({ theme }) => ({
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexDirection: 'row',
    role: 'list',
    minWidth: '1000px',
    gridColumn: {
      lg: '2 / span 2',
    },
  }),
);
