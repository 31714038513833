import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';

import { patientShape } from '@/utils/transformFrontendPatient';
import BaseModal from '@/components/BaseModal';
import { ButtonLabel } from '@/pages/PrintSummary/utils/printStyles';
import ModalTextArea from '@/pages/PatientSummary/components/modals/ModalTextArea';
import useMutatePatientNote from '@/pages/PatientSummary/hooks/useMutatePatientNote';
import ModalCheckboxList from '@/pages/PatientSummary/components/modals/ModalCheckboxList';
import getPatientTagOptions from '@/pages/PatientSummary/utils/getPatientTagOptions';
import useMutatePatientTags from '@/pages/PatientSummary/hooks/useMutatePatientTags';
import useGetPatientNotes from '@/pages/PatientSummary/hooks/useGetPatientNotes';
import BaseSpinner from '@/components/BaseSpinner';

export default function ModalPatientNotes({
  open,
  onClose,
  patient,
  tags,
  onChangeHeadline,
  onChangeTags,
}) {
  const { isLoading, data } = useGetPatientNotes({
    patientId: patient.id,
  });
  const { patientNotes, editableTags, headline } = data || {};
  const { mutate: mutateNote } = useMutatePatientNote({
    patientId: patient.id,
  });

  const { mutate: mutateTags } = useMutatePatientTags({
    patientId: patient.id,
  });

  const updateTags = (selectedTagIds, allTags) => {
    const selectedTagLabels = allTags
      .filter((elem) => selectedTagIds.indexOf(elem.id) >= 0)
      .map((elem) => elem.name);
    onChangeTags(selectedTagLabels);
  };

  const tagsForm = useForm();
  const {
    control: tagsControl,
    formState: { errors: tagsErrors },
    handleSubmit: tagsHandleSubmit,
  } = tagsForm;

  const notesForm = useForm();
  const {
    control: notesControl,
    formState: { errors: notesErrors },
    reset: notesReset,
    handleSubmit: notesHandleSubmit,
  } = notesForm;

  const navigateToUrl = (url) => {
    window.location.href = url;
  };

  const handleAddNote = (obj) => {
    mutateNote(
      {
        text: obj.addNoteText,
      },
      {
        onSuccess: notesReset,
      },
    );
  };

  const handleUpdateTags = (objectList) => {
    // Extract selected patient tag ids from
    // the list of selected checkbox list items
    const selectedTagIds = [];
    let headlineText = '';
    Object.entries(objectList).forEach(([key, value]) => {
      switch (key) {
        case 'headline':
          headlineText = value || '';
          onChangeHeadline(headlineText);
          break;
        case 'addNoteText':
          break;
        default:
          if (value) {
            selectedTagIds.push(Number(key));
          }
          break;
      }
    });
    updateTags(selectedTagIds, editableTags);

    mutateTags({ selectedTagIds, headlineText });
  };

  return isLoading ? (
    <BaseModal
      title="Edit Patient Notes"
      onClose={onClose}
      open={open}
      sx={{ minWidth: 250, p: 1 }}
    >
      <BaseSpinner size={50} sx={{ ml: 1 }} />
    </BaseModal>
  ) : (
    <BaseModal
      title="Edit Patient Notes"
      onClose={onClose}
      open={open}
      sx={{ maxWidth: 850, p: 1 }}
    >
      <Stack sx={{ whiteSpace: 'nowrap' }} spacing={2}>
        <Typography>
          <Label>Patient Name:</Label> {patient.firstName} {patient.lastName}
        </Typography>
        <Typography>
          <Label>Date Of Birth:</Label> {patient.dateOfBirth}
        </Typography>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <FormProvider {...tagsForm}>
          <ModalTextArea
            name="headline"
            defaultValue={headline}
            control={tagsControl}
            label="Patient summary headline (also appears on the printed summary)"
            errors={tagsErrors}
            minRows={1}
          />
          {editableTags && (
            <ModalCheckboxList
              name="PatientTagCheckboxList"
              options={getPatientTagOptions(editableTags, tags)}
              control={tagsControl}
              label="Patient Tags"
              BoxSx={{ flexWrap: 'wrap', flexDirection: 'row' }}
              InputSx={{ width: 250 }}
            />
          )}
          <Stack direction="row" justifyContent="flex-end">
            <Button onClick={tagsHandleSubmit(handleUpdateTags)}>Update</Button>
          </Stack>
        </FormProvider>
        <Box
          sx={{
            height: '1px',
            backgroundColor: 'border.base',
          }}
        />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <FormProvider {...notesForm}>
          <ModalTextArea
            data-dd-synthetics-id="add-note-textarea"
            control={notesControl}
            errors={notesErrors}
            label="Note"
            name="addNoteText"
            errorMsg="Enter a note body"
          />
          <Stack direction="row" justifyContent="flex-end">
            <Button onClick={notesHandleSubmit(handleAddNote)}>Add Note</Button>
          </Stack>
          <Table sx={{ mt: 2 }}>
            <TableHead>
              <TableRow sx={{ verticalAlign: 'bottom' }}>
                <StyledCell>Date</StyledCell>
                <StyledCell>Whom</StyledCell>
                <StyledCell>Action</StyledCell>
                <StyledCell>Note</StyledCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientNotes.map((note, i) => (
                // Using index key here because no child components maintain any
                // state, and they will not be rearranged after rendering. If
                // those assumptions change, revise this key.
                //
                // This was changed because the same author may write more than
                // one note in a day, making `date-author` a non-unique key.
                //
                <TableRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  data-dd-synthetics-id={i === 0 ? 'first-note-row' : undefined}
                >
                  <TableCell>{note.date}</TableCell>
                  <TableCell>{note.author}</TableCell>
                  <TableCell>{note.action}</TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      width: '100%',
                    }}
                  >
                    {note.text}{' '}
                    {note.printUrl && (
                      <Button
                        variant="text"
                        sx={{ p: 1 }}
                        onClick={() => navigateToUrl(note.printUrl)}
                      >
                        <ButtonLabel>Print Visit Billing Summary</ButtonLabel>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </FormProvider>
      </Stack>
    </BaseModal>
  );
}

const Label = styled.span`
  color: ${(p) => p.theme.palette.text.label};
`;

const StyledCell = styled(TableCell)`
  box-shadow: none;
  border-bottom: 1px solid ${(p) => p.theme.palette.border.base};
`;

ModalPatientNotes.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChangeHeadline: PropTypes.func.isRequired,
  onChangeTags: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  patient: patientShape.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};
