// The default global line height
export const LINE_HEIGHT = 1.375;

/**
 * Typography Customization
 *
 * @type {import('@mui/material').TypographyVariantsOptions}
 *
 * Documentation: https://mui.com/customization/typography/
 */

const headerExtraLarge = {
  fontWeight: 600,
  fontSize: '2.5rem',
  lineHeight: '4rem',
};
const headerLarge = {
  fontWeight: 600,
  fontSize: '2rem',
  lineHeight: '3rem',
};
const headerMedium = {
  fontWeight: 600,
  fontSize: '1.5rem',
  lineHeight: '2.5rem',
};
const headerSmall = {
  fontWeight: 600,
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
};
const headerSmallBold = {
  fontWeight: 800,
};

const lightBold = {
  fontWeight: 600,
};

const headerMini = {
  fontSize: '0.75rem',
  lineHeight: '1.5rem',
};

const headerXsMini = {
  fontSize: '0.625rem',
  lineHeight: '1.5rem',
};

const bodyExtraLarge = {
  fontSize: '1.125rem',
  fontWeight: 400,
  lineHeight: '2rem',
};
const bodyLarge = {
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
};
const bodyMedium = {
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
};
const bodySmall = {
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
};

const bodyXSmall = {
  fontSize: '0.75rem',
};

const metricLarge = {
  fontSize: '2rem',
  fontWeight: 400,
  lineHeight: '3rem',
};
const metricMedium = {
  fontSize: '1.5rem',
  fontWeight: 400,
  lineHeight: '2.5rem',
};
const metricSmall = {
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
};

const typography = {
  fontFamily: 'Avenir, sans-serif',
  fontWeightBold: 600,

  h1: headerExtraLarge,
  headerextralarge: headerExtraLarge,

  h2: headerLarge,
  headerlarge: headerLarge,

  h3: headerMedium,
  headermedium: headerMedium,
  headermediumbold: { ...headerMedium, fontWeight: 600 },

  h4: headerSmall,
  headersmall: headerSmall,
  headersmallbold: { ...headerSmall, ...headerSmallBold },

  headerminibold: { ...headerMini, ...lightBold },

  headerxsmini: headerXsMini,
  headerxsbold: { ...headerXsMini, ...{ fontWeight: 600 } },

  bodyextralarge: bodyExtraLarge,
  bodyextralargebold: { ...bodyExtraLarge, ...{ fontWeight: 600 } },
  bodyextralargexbold: { ...bodyExtraLarge, ...{ fontWeight: 800 } },

  bodylarge: bodyLarge,
  body: bodyLarge,
  bodylargebold: { ...bodyLarge, ...{ fontWeight: 600 } },
  bodylargexbold: { ...bodyLarge, ...{ fontWeight: 800 } },

  bodymedium: bodyMedium,
  bodymediumbold: { ...bodyMedium, ...{ fontWeight: 600 } },
  bodymediumxbold: { ...bodyMedium, ...{ fontWeight: 800 } },

  bodysmall: bodySmall,
  bodysmallbold: { ...bodySmall, ...{ fontWeight: 600 } },
  bodysmallxbold: { ...bodySmall, ...{ fontWeight: 800 } },

  bodyxsmall: bodyXSmall,

  bodybold: {
    fontWeight: '600',
    fontSize: '1rem',
  },

  label: {
    fontSize: '0.875rem',
    fontWeight: '600',
    lineHeight: '1.5rem',
  },

  helper: {
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
  },

  linkextralarge: {
    fontSize: '1.125rem',
    fontWeight: 350,
    lineHeight: '2rem',
  },

  metricextralarge: {
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: '4rem',
  },
  metriclarge: metricLarge,
  metriclargebold: { ...metricLarge, ...{ fontWeight: 600 } },

  metricmedium: metricMedium,
  metric: metricMedium,
  metricmediumbold: { ...metricMedium, ...{ fontWeight: 600 } },

  metricsmall: metricSmall,
  metricsmallbold: { ...metricSmall, ...{ fontWeight: 600 } },

  buttonextralarge: {
    fontSize: '1.125rem',
    lineHeight: '1.125rem',
    fontWeight: 400,
  },
  buttonlarge: {
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 400,
  },
  buttonmedium: {
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontWeight: 400,
  },
} as const;

export default typography;
