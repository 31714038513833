import { Tooltip, TooltipProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { ReactElement } from 'react';

const LightTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,react/jsx-props-no-spreading,react/no-children-prop
    <Tooltip {...props} classes={{ popper: className }} children={children} />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.base,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[2],
  },
}));

type PatientNeedTooltipProps = {
  children: ReactElement;
};

function PatientNeedTooltip({
  children,
}: PatientNeedTooltipProps): JSX.Element {
  return (
    <LightTooltip
      arrow={false}
      placement="right"
      TransitionProps={{ timeout: 0 }}
      title={
        <Typography component="span">
          This patient has been identified as high-need due to co-morbidities,
          functional limitations, behavioral health, or other factors.
          Additional attention through more frequent and/or longer visits, and
          prioritizing their scheduling, is recommended.
        </Typography>
      }
    >
      {children}
    </LightTooltip>
  );
}

export default PatientNeedTooltip;
