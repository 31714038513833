import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useRef } from 'react';

import {
  BannerDivider,
  SubBoxContainer,
} from '@/pages/TaskBasedWorkflow/components/taskTableView/summaryBannerStyles';
import DynamicForm from '@/components/DynamicForm/DynamicForm';
import TocUserDropdownContainer, {
  FOLLOWUP_USER_LABEL,
  SCHEDULING_USER_LABEL,
} from '@/pages/TaskBasedWorkflow/components/taskTableView/TocUserDropdownContainer';
import useGetUserLogins from '@/pages/PracticeManagementDashboard/hooks/useGetUserLogins';
import { Page } from '@/pages/TaskBasedWorkflow/constants';
import useGetUser from '@/hooks/useGetUser';
import useGetMedicalGroup from '@/pages/TaskBasedWorkflow/hooks/useGetMedicalGroup';
import useFeatureFlag from '@/utils/useFeatureFlag';
import { usePage } from '@/pages/TaskBasedWorkflow/contexts/PageContext';

const prepareUsersForDropdown = (users = []) => {
  const activeUsers = users
    .filter((x) => x.active)
    .map(({ id, firstName, lastName }) => ({ id, firstName, lastName }));
  activeUsers.sort((a, b) => a.lastName.localeCompare(b.lastName));
  return activeUsers;
};

/**
 * Component for displaying active users and selecting assigned users for
 * varying TOC related tasks. At the moment this only saves to the MG model.
 * ToDo: Possible add confirmation message / user notifications.
 */
function SummaryBannerDropdownContainer() {
  const page = usePage();
  const assignUserForTocEnabled = useFeatureFlag(
    'enable_assign_user_for_toc_workflow',
  );

  const { data: user, isLoading: userIsLoading } = useGetUser({
    enabled: true,
  });
  const { data: medicalGroupData, isLoading: medGroupIsLoading } =
    useGetMedicalGroup({
      medicalGroupId: user?.medicalGroupId,
      enabled: Boolean(user?.medicalGroupId),
    });

  const { data: userLoginsData, isLoading: usersLoading } = useGetUserLogins();
  const reconRef = useRef(); // Ref for the follow-up dropdown container
  const schedRef = useRef(); // Ref for the scheduling dropdown container

  const { users = [] } = userLoginsData || {};
  const activeUsers = prepareUsersForDropdown(users);

  const finishedLoading = Boolean(
    !userIsLoading && !usersLoading && !medGroupIsLoading,
  );

  if (
    !finishedLoading ||
    !assignUserForTocEnabled ||
    page !== Page.transitionOfCare
  )
    return null;

  return (
    <>
      <BannerDivider />
      <SubBoxContainer>
        <Box flexDirection="column">
          <DynamicForm onSubmit={() => {}}>
            <TocUserDropdownContainer
              containerRef={reconRef}
              users={activeUsers}
              fieldLabel={FOLLOWUP_USER_LABEL}
              medicalGroupId={user?.medicalGroupId}
              assignedUser={medicalGroupData?.assignedFollowupUserId}
            />
          </DynamicForm>
        </Box>

        <Box flexDirection="column">
          <DynamicForm onSubmit={() => {}}>
            <TocUserDropdownContainer
              containerRef={schedRef}
              users={activeUsers}
              fieldLabel={SCHEDULING_USER_LABEL}
              medicalGroupId={user?.medicalGroupId}
              assignedUser={medicalGroupData?.assignedSchedulingUserId}
            />
          </DynamicForm>
        </Box>
      </SubBoxContainer>
    </>
  );
}

SummaryBannerDropdownContainer.propTypes = {
  user: PropTypes.shape({
    medicalGroupId: PropTypes.number,
  }),
  medicalGroupData: PropTypes.shape({
    assigned_followup_user_id: PropTypes.number,
    assigned_scheduling_user_id: PropTypes.number,
  }),
};

SummaryBannerDropdownContainer.defaultProps = {
  user: undefined,
  medicalGroupData: undefined,
};

export default SummaryBannerDropdownContainer;
