import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

function transformResponse(data) {
  if (!data) return {};
  return {
    key: data.key,
    id: data.id,
    name: data.name,
    tin: data.tin,
    assignedFollowupUserId: data.assigned_followup_user_id,
    assignedSchedulingUserId: data.assigned_scheduling_user_id,
  };
}

async function fetchMedicalGroup(data) {
  const medicalGroupId = data?.medicalGroupId;
  const response = await axios.get(`/api/medical_group/${medicalGroupId}`);

  if (response.status !== 200) {
    throw new Error(response.data.message || 'Error getting medical group');
  }

  return transformResponse(response.data);
}

/**
 * @param {boolean} [options.enabled=true] - Whether the query should automatically run.
 * @param {number} options.medicalGroupId - The ID of the medical group to fetch.
 */
export default function useGetMedicalGroup({
  enabled = true,
  medicalGroupId,
} = {}) {
  return useQuery({
    queryFn: () => fetchMedicalGroup({ medicalGroupId }),
    queryKey: ['medical_group_id', medicalGroupId],
    enabled,
  });
}
