import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Stack } from '@mui/material';

import BaseSelect from '@/components/BaseSelect';
import BaseModal from '@/components/BaseModal';
import DynamicForm from '@/components/DynamicForm/DynamicForm';
import DynamicFormFields from '@/components/DynamicForm/DynamicFormFields';
import { enhanceExecutionRequirement } from '@/pages/PatientSummary/utils/getActionExecutionRequirements';
import DynamicFormDatePicker from '@/components/DynamicForm/fieldComponents/DynamicFormDatePicker';
import DynamicFormTextareaV2 from '@/components/DynamicForm/fieldComponents/DynamicFormTextareaV2';

function fieldComponentMapper(field) {
  switch (field.type) {
    case 'date':
      return DynamicFormDatePicker;
    case 'textarea':
      return DynamicFormTextareaV2;
    default:
      return null;
  }
}
function ClinicalGuidelineInputForm({
  action,
  saveActionFormStateV2,
  guidelineSummary,
}) {
  const [selectedStatus, setSelectedStatus] = useState('');
  const adherenceConfirmationStep = action?.availableNextSteps?.find(
    (step) => step.stepName === 'adherence_confirmation',
  );

  const adherenceConfirmationSelectionExecutionRequirement =
    adherenceConfirmationStep?.executionRequirements?.find(
      (requirement) => requirement.name === 'adherence_confirmation_selection',
    );
  const dateOfAdherenceReviewExecutionRequirement =
    adherenceConfirmationStep?.executionRequirements?.find(
      (requirement) => requirement.name === 'date_of_adherence_review',
    );
  const notesExecutionRequirement = enhanceExecutionRequirement(
    adherenceConfirmationStep?.executionRequirements?.find(
      (requirement) => requirement.name === 'notes',
    ),
  );
  const [showModal, setShowModal] = useState(false);
  const onClose = () => {
    setShowModal(false);
    setSelectedStatus('');
  };
  const onSubmit = (data) => {
    setShowModal(false);
    saveActionFormStateV2({
      patientAction: action,
      step: adherenceConfirmationStep,
      formSubmission: {
        notes: data?.notes,
        adherence_confirmation_selection: selectedStatus,
        date_of_adherence_review: data?.date_of_adherence_review,
      },
    });
  };
  return (
    <>
      <BaseSelect
        options={adherenceConfirmationSelectionExecutionRequirement.choices.map(
          (choice) => ({
            value: choice[0],
            label: choice[1],
          }),
        )}
        value={selectedStatus}
        onChange={(event) => {
          setSelectedStatus(event.target.value);
          setShowModal(true);
        }}
        fullWidth={false}
        width={1}
        sx={{ minWidth: 150 }}
        noneOption="--"
        includeNoneOption
      />
      <BaseModal
        open={showModal}
        onClose={onClose}
        title={`Recommendation ${selectedStatus.replace('_', ' ')}: ${guidelineSummary}`}
      >
        <DynamicForm onSubmit={onSubmit}>
          <Stack spacing={0}>
            <DynamicFormFields
              fields={[
                dateOfAdherenceReviewExecutionRequirement,
                notesExecutionRequirement,
              ]}
              fieldComponentMapper={fieldComponentMapper}
            />
            <Stack
              direction="row"
              justifyContent="flex-end"
              marginTop={(theme) => theme.spacing(2)}
            >
              <Button type="submit">Save</Button>
            </Stack>
          </Stack>
        </DynamicForm>
      </BaseModal>
    </>
  );
}

ClinicalGuidelineInputForm.propTypes = {
  action: PropTypes.object.isRequired,
  saveActionFormStateV2: PropTypes.func.isRequired,
  guidelineSummary: PropTypes.string.isRequired,
};

export default ClinicalGuidelineInputForm;
