import {
  Box,
  Card,
  Chip,
  FormControl,
  FormGroup,
  FormLabel,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { startOfYear } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

import { ReactComponent as WarningIcon } from '@/assets/icons/warning.svg';
import BaseDatePicker from '@/components/BaseDatePicker';
import BaseLinkButton from '@/components/BaseLinkButton';
import SvuNumber from '@/pages/PatientSummary/components/SvuNumber';
import ModalIcd from '@/pages/PatientSummary/components/ModalIcd';
import PastDiagnosisSubtext from '@/pages/PatientSummary/components/PastDiagnosisSubtext';
import ActionHelp from '@/pages/PatientSummary/components/SectionCareGaps/ActionHelp';
import SectionSupersededHcc from '@/pages/PatientSummary/components/SectionSupersededHcc';
import {
  COMPLETE_WITHOUT_CODE,
  CONDITION_INCORRECT,
  CONDITION_NOT_PRESENT,
  DIAGNOSIS_ACTION_SUBTYPE_RECAPTURE,
  DIAGNOSIS_ACTION_SUBTYPE_SUGGESTION,
  OTHER_ICD_SPECIFIED,
  SUPERSEDED,
} from '@/pages/PatientSummary/utils/constants';
import { actionShape } from '@/utils/transformFrontendAction';
import { patientShape } from '@/utils/transformFrontendPatient';
import useFeatureFlag from '@/utils/useFeatureFlag';
import BaseModal from '@/components/BaseModal';
import ZeroState from '@/pages/PatientSummary/components/ZeroState';
import SvuAmount from '@/components/SvuAmount';
import ModalInformationalText from '@/pages/PatientSummary/components/modals/ModalInformationalText';

import isValidPriorDate from '../utils/isValidPriorDate';
import useGetPriorDateView from '../hooks/useGetPriorDateView';

import CheckboxWithHighlight from './CheckboxWithHighlight';
import ModalHistoricalConditions from './ModalHistoricalConditions';
import ReopenedDiagnosisRibbon from './ReopenedDiagnosisRibbon';
import ActionTrigger from './SectionCareGaps/ActionTrigger';
import SvuProgress from './SvuProgress';

const DIAGNOSIS_SECTION_CONFIG = {
  suggestion: {
    ZERO_STATE_TEXT: 'This patient has no open suspected diagnoses.',
    SECTION_TITLE: 'Suspected Diagnosis',
    PREVIOUS_SECTION_TITLE: 'Supporting evidence',
    PREVIOUS_BUTTON_TEXT: 'View all',
    SPECIFY_CODE_SECTION_TITLE: 'If condition is present, specify diagnosis',
    ACTION_SECTION_TITLE:
      'If patient does not have or is no longer being managed for this condition',
    SUPERSEDED_SECTION_TITLE: 'Related conditions',
    ACTION_SECTION_TITLE_WITH_SUPERSEDED:
      'If patient does not have or is no longer being managed for this condition',
    USE_ACTION_HELPTEXT: true,
    DISPLAY_HELPER_SECTION: false,
    DIAGNOSIS_INCORRECT_CHECKBOX_ID: CONDITION_NOT_PRESENT,
    STEP_1_LABEL: 'If condition is present, select a code to confirm',
  },
  recapture: {
    ZERO_STATE_TEXT: 'This patient has no open historical conditions.',
    SECTION_TITLE: 'Historical Conditions',
    PREVIOUS_SECTION_TITLE:
      'Previously billed conditions not yet confirmed by claims this year',
    PREVIOUS_BUTTON_TEXT: 'Show all diagnosis history',
    SPECIFY_CODE_SECTION_TITLE:
      'Possible other common diagnoses often encountered with this condition',
    ACTION_SECTION_TITLE:
      'If patient does not have or is no longer being managed for this condition',
    SUPERSEDED_SECTION_TITLE: 'Change to a related condition',
    ACTION_SECTION_TITLE_WITH_SUPERSEDED:
      'If patient does not have or is no longer being managed for this condition',
    USE_ACTION_HELPTEXT: false,
    DISPLAY_HELPER_SECTION: true,
    DIAGNOSIS_INCORRECT_CHECKBOX_ID: CONDITION_INCORRECT,
    STEP_1_LABEL: 'Select diagnoses assessed',
  },
};
const DIAGNOSIS_COUNT_BEFORE_EXPANDING = 3;

function showMostRecentDiagnosis(diagnoses) {
  return (
    diagnoses.length > 0 && (
      <PastDiagnosisSubtext
        key={`${diagnoses[0].sourceName}-${diagnoses[0].date}`}
        diagnoses={diagnoses}
      />
    )
  );
}

// TODO: remove this variable once we have SVU data for each action.
const showSvus = false;

function SectionActionableDiagnosis({
  patient,
  diagnosisActions,
  dateOfService,
  onChangeDateOfService,
  diagnosisSectionType,
  onDxOptionToggled,
  onCustomIcdEntered,
  dxActionCheckboxState,
  notAssessedDiagnosisMessageShowFlagState,
  onNotAssessedDiagnosisMessageShowFlagState,
  showNotAssessedOption,
  otherIcdDisplayed,
}) {
  const {
    ZERO_STATE_TEXT: zeroStateText,
    SECTION_TITLE: sectionTitle,
    PREVIOUS_SECTION_TITLE: previousSectionTitle,
    PREVIOUS_BUTTON_TEXT: previousButtonText,
    SUPERSEDED_SECTION_TITLE: supersededSectionTitle,
    ACTION_SECTION_TITLE: actionSectionTitle,
    ACTION_SECTION_TITLE_WITH_SUPERSEDED: actionSectionTitleWithSuperseded,
    USE_ACTION_HELPTEXT: useActionHelp,
    DISPLAY_HELPER_SECTION: hasHelperSect,
    DIAGNOSIS_INCORRECT_CHECKBOX_ID: diagnosisIncorrectCheckboxId,
    SPECIFY_CODE_SECTION_TITLE: specifyCodeSectionTitle,
    STEP_1_LABEL: step1Label,
  } = DIAGNOSIS_SECTION_CONFIG[diagnosisSectionType];

  const { data: dateViews } = useGetPriorDateView(patient.id);
  const isViewingPriorDate = isValidPriorDate(dateOfService, dateViews);

  const relatedConditionHelpContent = (
    <ol>
      <li>
        <p>What is a related condition?</p>
        <p>
          These conditions are similar to the originally listed condition, but
          may be a different severity or acuity that better matches what this
          patient is facing. We’ve listed them here to make it easier to
          comprehensively document a patient&apos;s condition.
        </p>
      </li>
      <li>
        <p>What happens to this gap if I select one of these conditions?</p>
        <p>
          Selecting one of these conditions signals to Stellar that we will need
          to assess a related diagnosis. If you select one of these conditions,
          we’ll ask you for a code that specifically satisfies this condition.
        </p>
      </li>
    </ol>
  );

  const [shouldShowHelpModal, toggleShouldShowHelpModal] = useToggle(false);
  const [icdModalOpen, setIcdModalOpen] = useState(false);
  const completeWithoutCodeEnabled = useFeatureFlag(
    'patientSummaryV2EnableCompleteWithoutCode',
  );
  const allowNonRafDiagnosisCodes = useFeatureFlag(
    'psAllowNonRafDiagnosisCodes',
  );
  const commonDiagnosesWithConditionEnabled = useFeatureFlag(
    'commonDiagnosesWithConditionEnabled',
  );

  const changeToRelatedConditionEnabled = useFeatureFlag(
    'changeToRelatedConditionEnabled',
  );

  const handleOpenIcdModal = (actionId) => {
    if (actionId) {
      setIcdModalOpen(actionId);
    }
  };

  const handleCloseIcdModal = () => {
    setIcdModalOpen(false);
  };

  const handleSubmitCustomIcd = (actionId, icd) => {
    onCustomIcdEntered(
      actionId,
      {
        icd: icd.value,
        description: icd.label,
        codeRelatedToAction: icd.codeRelatedToAction,
      },
      icd.hcc,
    );
    setIcdModalOpen(false);
  };

  const handleSubmitIcdModal = (icd) => {
    handleSubmitCustomIcd(Number(icdModalOpen), icd);
  };

  const handleSubmitSupersededIcdModal = (icd) => {
    const actionId = Number(icdModalOpen.split('-')[0]);
    handleSubmitCustomIcd(actionId, icd);
  };

  const isInvalidCustomIcdOnlySelected = (actionId) => {
    return (
      otherIcdDisplayed[actionId] &&
      !otherIcdDisplayed[actionId].codeRelatedToAction &&
      dxActionCheckboxState[actionId].selectedIcds.length === 1 &&
      dxActionCheckboxState[actionId].selectedIcds[0].icd ===
        otherIcdDisplayed[actionId].icd
    );
  };

  const areAnyDiagnosesSelected = (actionId) => {
    return dxActionCheckboxState[actionId].selectedIcds.length > 0;
  };

  const isDiagnosisCodeSelected = (actionId, diagnosisCode) => {
    return dxActionCheckboxState[actionId].selectedIcds.some(
      (x) => x.icd === diagnosisCode,
    );
  };

  const isSuggestedDiagnosisAction = (subtype) =>
    subtype === DIAGNOSIS_ACTION_SUBTYPE_SUGGESTION;

  const isRecaptureDiagnosisAction = (subtype) =>
    subtype === DIAGNOSIS_ACTION_SUBTYPE_RECAPTURE;

  const clinicalEvidenceSubtext = (assessedOn, evidence) => {
    if (assessedOn && evidence) {
      return (
        <>
          Assessed on {assessedOn}
          <br />
          {evidence}
        </>
      );
    }
    if (assessedOn) {
      return `Assessed on ${assessedOn}`;
    }
    return evidence;
  };

  const getPastDiagnosesOrClinicalEvidence = (
    pastDiagnosesOrClinicalEvidence,
    actionId,
    isFirstHistoricalCondition,
  ) => {
    return (
      <StyledFormGroup>
        {pastDiagnosesOrClinicalEvidence
          .slice(0, DIAGNOSIS_COUNT_BEFORE_EXPANDING)
          .map(
            (
              {
                icd,
                description,
                pastDiagnoses: groupedDiagnoses,
                assessedOn,
                evidence,
              },
              index,
            ) =>
              icd && isRecaptureDiagnosisAction(diagnosisSectionType) ? (
                <CheckboxWithHighlight
                  data-dd-synthetics-id={
                    isFirstHistoricalCondition && index === 0
                      ? 'first-historical-condition-checkbox'
                      : undefined
                  }
                  data-pendo-id={`ps-${diagnosisSectionType}-past-or-clinical-checkbox`}
                  checked={isDiagnosisCodeSelected(actionId, icd)}
                  key={`${icd} - ${description}`}
                  label={`${icd} - ${description}`}
                  onChange={(e) =>
                    onDxOptionToggled(
                      e.target.checked,
                      actionId,
                      { icd, description },
                      undefined,
                      false,
                      false,
                    )
                  }
                  subtext={
                    groupedDiagnoses
                      ? showMostRecentDiagnosis(groupedDiagnoses)
                      : clinicalEvidenceSubtext(assessedOn, evidence)
                  }
                  InputSx={{ maxWidth: '100%' }}
                />
              ) : (
                <Box sx={{ mb: 2 }} key={description}>
                  {isSuggestedDiagnosisAction(diagnosisSectionType) ? (
                    <Typography>{evidence}</Typography>
                  ) : (
                    <Typography>{description}</Typography>
                  )}
                  {isRecaptureDiagnosisAction(diagnosisSectionType) &&
                  (evidence || assessedOn) ? (
                    <Typography
                      sx={{ color: 'text.secondary', ml: 3 }}
                      variant="bodysmall"
                    >
                      {clinicalEvidenceSubtext(assessedOn, evidence)}
                    </Typography>
                  ) : null}
                  {isSuggestedDiagnosisAction(diagnosisSectionType) && icd && (
                    <Typography>Code: {icd}</Typography>
                  )}
                </Box>
              ),
          )}
      </StyledFormGroup>
    );
  };

  if (diagnosisActions.length === 0) {
    return (
      <>
        <Paper
          elevation={0}
          sx={{ backgroundColor: 'background.default', p: 1, display: 'flex' }}
        >
          <Typography variant="h2">{sectionTitle}</Typography>
        </Paper>
        <ZeroState message={zeroStateText} />
      </>
    );
  }

  return (
    <>
      <Paper
        elevation={0}
        sx={{ backgroundColor: 'background.tableZebra', p: 1, display: 'flex' }}
      >
        <Box mr="auto">
          <Typography variant="h2">{sectionTitle}</Typography>

          {!useActionHelp || patient.suggestedActionHelpText ? (
            <>
              <BaseLinkButton
                sx={{ color: 'text.secondary', mt: 1 }}
                onClick={toggleShouldShowHelpModal}
                data-pendo-id="ps-learn-more"
              >
                Learn More
              </BaseLinkButton>

              {useActionHelp ? (
                <BaseModal
                  onClose={toggleShouldShowHelpModal}
                  open={shouldShowHelpModal}
                  title={patient.suggestedActionHelpText.title || ''}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: patient.suggestedActionHelpText.content || '',
                    }}
                  />
                </BaseModal>
              ) : (
                <ModalHistoricalConditions
                  onClose={toggleShouldShowHelpModal}
                  open={shouldShowHelpModal}
                />
              )}
            </>
          ) : null}
        </Box>

        {showSvus && <SvuProgress total={150} earned={10} pending={10} />}

        {/* If we are looking at historical conditions 
          do not show the date of patient visit box on diagnoses gaps. */}
        {!isViewingPriorDate ? (
          <Card
            variant="outlined"
            sx={{ py: 1, px: 2, ml: 1, borderColor: 'border.base' }}
          >
            <Typography variant="label">Date of Patient Visit</Typography>
            <BaseDatePicker
              onChange={onChangeDateOfService}
              size="large"
              value={dateOfService}
              placeholder="Date of visit"
              data-pendo-id="ps-date-of-visit-picker"
              sx={{ width: '130px', display: 'block', mx: 'auto', mt: 0.5 }}
              maxDate={new Date()}
              minDate={startOfYear(new Date())}
            />
          </Card>
        ) : null}
      </Paper>

      <Box sx={{ p: 1 }}>
        {hasHelperSect && (
          <Typography sx={{ mb: 2 }}>
            If a condition is present and being managed this visit, select
            (click/check) at least one appropriate diagnosis that was assessed;
            the condition(s) should also accurately be reflected in the medical
            record.
            <br />
            <br />
            Note: Each HCC may be associated with multiple different ICD-10
            diagnoses.
          </Typography>
        )}

        {diagnosisActions.map((item, index) => {
          const {
            id: actionId,
            clinicalEvidence,
            hcc,
            mostRecentlyCompletedByDate,
            mostRecentlyCompletedByName,
            pastDiagnoses,
            commonCodes,
            svusBeforePromo,
            svusAfterPromo,
            hasBeenReopened,
            subtype,
            progress,
            supersededHccs,
            mostRecentProviderForDisplay,
            userAddedDiagnosisCodes,
          } = item;
          const customIcd = otherIcdDisplayed[actionId];
          const { bullets } = progress ?? { bullets: [] };
          let diagnosisIcdSVUs = 0;
          let diagnosisIcdSVUsAfterPromo = 0;
          let diagnosisConfirmedSVUs = 0;
          let diagnosisConfirmedSVUsAfterPromo = 0;

          // combine the first two if the length of the bullets array is > 2
          if (bullets.length > 2) {
            diagnosisIcdSVUs =
              (bullets[0]?.svuAwardAmount || 0) +
              (bullets[1]?.svuAwardAmount || 0);
            diagnosisIcdSVUsAfterPromo =
              (bullets[0]?.svuAwardAmountAfterPromo || 0) +
              (bullets[1]?.svuAwardAmountAfterPromo || 0);
            diagnosisConfirmedSVUs = bullets[2]?.svuAwardAmount || 0;
            diagnosisConfirmedSVUsAfterPromo =
              bullets[2]?.svuAwardAmountAfterPromo || 0;
          } else if (bullets.length === 2) {
            diagnosisIcdSVUs = bullets[0]?.svuAwardAmount || 0;
            diagnosisIcdSVUsAfterPromo =
              bullets[0]?.svuAwardAmountAfterPromo || 0;
            diagnosisConfirmedSVUs = bullets[1]?.svuAwardAmount || 0;
            diagnosisConfirmedSVUsAfterPromo =
              bullets[1]?.svuAwardAmountAfterPromo || 0;
          }

          const hasUserAddedNonRafCodes =
            userAddedDiagnosisCodes && userAddedDiagnosisCodes.length > 0;
          const rafCommonCodes = commonCodes.filter((code) => code.raf);
          const commonCodesToDisplay = allowNonRafDiagnosisCodes
            ? commonCodes
            : rafCommonCodes;
          return (
            hcc && (
              <Paper
                key={actionId}
                sx={{ p: 1, mt: 1, borderColor: 'border.base' }}
                variant="outlined"
                data-pendo-id={`ps-${diagnosisSectionType}-row-${index + 1}`}
              >
                {hasUserAddedNonRafCodes && (
                  <ModalInformationalText content="This action will continue to display because a previously selected code did not address the condition gap. It can be assessed again at the patient’s next visit." />
                )}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    pr: 1,
                    mt: hasUserAddedNonRafCodes ? 1 : 0,
                  }}
                >
                  <Typography variant="h3">
                    <span
                      data-dd-synthetics-id={
                        index === 0 &&
                        !isSuggestedDiagnosisAction(diagnosisSectionType)
                          ? 'first-diagnosis-action-name'
                          : undefined
                      }
                    >
                      {hcc.name}
                    </span>
                    {hasBeenReopened ? (
                      <Chip
                        label="Reopened"
                        variant="call-to-action"
                        sx={{ marginLeft: 1 }}
                      />
                    ) : null}
                  </Typography>
                  {isInvalidCustomIcdOnlySelected(actionId) ? (
                    <Typography sx={{ color: 'status.warningForeground' }}>
                      <StyledWarningIcon /> Completed, but selected ICD code
                      will not close gap
                    </Typography>
                  ) : (
                    <Typography variant="subtext" component="span">
                      <SvuAmount
                        svuPostPromo={svusAfterPromo}
                        svuPrePromo={svusBeforePromo}
                        svuText
                        sx={{ display: 'inline-flex' }}
                        typographyVariant="bodymediumbold"
                      />{' '}
                      available
                    </Typography>
                  )}
                </Box>
                <Box>
                  {notAssessedDiagnosisMessageShowFlagState[actionId].show && (
                    <Alert
                      sx={{ mt: 1, mb: 1 }}
                      severity="info"
                      icon={false}
                      action={
                        <IconButton
                          name="close-not-assessed-alert"
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() =>
                            onNotAssessedDiagnosisMessageShowFlagState(actionId)
                          }
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <Typography variant="bodylarge" as="span">
                        This condition was marked{' '}
                        <Typography
                          variant="bodylargebold"
                          as="span"
                          sx={{ fontWeight: 800 }}
                        >
                          Not assessed
                        </Typography>{' '}
                        and will remain here for assessment during a future
                        visit.
                      </Typography>
                    </Alert>
                  )}
                </Box>

                <Stepper sx={{ mt: 1 }}>
                  <Step>
                    <StepLabel>
                      {step1Label} (
                      <SvuNumber
                        svusBeforePromo={diagnosisIcdSVUs}
                        svusAfterPromo={diagnosisIcdSVUsAfterPromo}
                      />
                      )
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>
                      Ensure claim includes selected diagnoses (
                      <SvuNumber
                        svusBeforePromo={diagnosisConfirmedSVUs}
                        svusAfterPromo={diagnosisConfirmedSVUsAfterPromo}
                      />
                      )
                    </StepLabel>
                  </Step>
                </Stepper>
                {hasBeenReopened ? (
                  <ReopenedDiagnosisRibbon
                    inputByName={mostRecentlyCompletedByName}
                    closedDate={mostRecentlyCompletedByDate}
                    providerName={mostRecentProviderForDisplay}
                    diagnosisCodes={userAddedDiagnosisCodes}
                  />
                ) : null}
                <Box sx={{ display: 'flex', mt: 1 }}>
                  <Card
                    sx={{
                      flex: '3.5 1 0px',
                      p: 2,
                      borderColor: 'border.base',
                      display: 'grid',
                      gridAutoFlow: 'column',
                      gridAutoColumns: '50%',
                    }}
                    variant="outlined"
                  >
                    <Box
                      sx={{
                        pr: 1,
                        borderRightWidth: '1px',
                        borderRightStyle: 'solid',
                        borderRightColor: 'border.base',
                      }}
                      data-pendo-id={`ps-${diagnosisSectionType}-past-or-clinical-column`}
                    >
                      <FormControl sx={{ width: '100%' }}>
                        <FormLabel>
                          <Typography
                            variant="bodybold"
                            sx={{ color: 'text.primary' }}
                          >
                            {previousSectionTitle}
                          </Typography>
                        </FormLabel>

                        {getPastDiagnosesOrClinicalEvidence(
                          isRecaptureDiagnosisAction(subtype)
                            ? pastDiagnoses
                            : clinicalEvidence,
                          actionId,
                          index === 0,
                        )}

                        <ActionTrigger
                          data-pendo-id={`show-history-${actionId}`}
                          trigger={{
                            dataUrl: isRecaptureDiagnosisAction(subtype)
                              ? `actions/${actionId}/past_diagnosis`
                              : `actions/${actionId}/clinical_evidence`,
                            text: `${previousButtonText}`,
                          }}
                          color="text.secondary"
                        />
                      </FormControl>
                    </Box>

                    <Box
                      sx={{ pl: 2 }}
                      data-pendo-id={`ps-${diagnosisSectionType}-other-icds`}
                    >
                      {commonDiagnosesWithConditionEnabled && (
                        <FormControl>
                          <FormLabel>
                            {(commonCodesToDisplay.length > 0 ||
                              isSuggestedDiagnosisAction(subtype)) && (
                              <Typography
                                variant="bodybold"
                                sx={{ color: 'text.primary' }}
                              >
                                {specifyCodeSectionTitle}
                              </Typography>
                            )}
                          </FormLabel>
                          <StyledFormGroup>
                            {commonCodesToDisplay.map(
                              ({ icd, description }) => {
                                return (
                                  <Box key={`${icd} - ${description}`}>
                                    <CheckboxWithHighlight
                                      checked={isDiagnosisCodeSelected(
                                        actionId,
                                        icd,
                                      )}
                                      key={`${icd} - ${description}`}
                                      label={`${icd} - ${description}`}
                                      onChange={(e) =>
                                        onDxOptionToggled(
                                          e.target.checked,
                                          actionId,
                                          { icd, description },
                                          undefined,
                                          false,
                                          false,
                                        )
                                      }
                                      data-pendo-id={`ps-${diagnosisSectionType}-middle-checkbox`}
                                    />
                                  </Box>
                                );
                              },
                            )}
                          </StyledFormGroup>
                        </FormControl>
                      )}

                      <Box mt={2}>
                        {customIcd && 'Specified Code ('}

                        <BaseLinkButton
                          sx={{
                            verticalAlign: 'baseline',
                            whiteSpace: 'nowrap',
                            color: 'text.secondary',
                          }}
                          onClick={() => handleOpenIcdModal(actionId)}
                          data-pendo-id={`ps-${diagnosisSectionType}-middle-${
                            customIcd ? 'change-code' : 'specify-another-code'
                          }-button`}
                        >
                          {/* Link text when a custom code is selected */}
                          {customIcd && 'change'}

                          {/* Link text when no custom code is selected and we show codes to select from */}
                          {!customIcd && commonCodesToDisplay.length > 0
                            ? 'Select a different code'
                            : null}

                          {/* Link text when no custom code is selected and we don't show codes to select from */}
                          {!customIcd && commonCodesToDisplay.length === 0
                            ? 'Select a code'
                            : null}
                        </BaseLinkButton>

                        {customIcd && ')'}

                        {icdModalOpen === actionId ? (
                          <ModalIcd
                            onClose={handleCloseIcdModal}
                            onSubmit={handleSubmitIcdModal}
                            patient={patient}
                            actionHcc={hcc}
                            actionId={actionId}
                          />
                        ) : null}

                        {customIcd && (
                          <CheckboxWithHighlight
                            data-pendo-id={`ps-${diagnosisSectionType}-other-selected-checkbox`}
                            key={`${actionId}-${OTHER_ICD_SPECIFIED}`}
                            checked={isDiagnosisCodeSelected(
                              actionId,
                              customIcd.icd,
                            )}
                            label={`${customIcd.icd} - ${customIcd.description}`}
                            sx={{ color: 'text.secondary' }}
                            onChange={(e) =>
                              onDxOptionToggled(
                                e.target.checked,
                                actionId,
                                {
                                  icd: customIcd.icd,
                                  description: customIcd.description,
                                },
                                undefined,
                                false,
                                false,
                              )
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  </Card>

                  <Box
                    sx={(theme) => ({
                      flex: '1 1 0px',
                      ml: 1,
                      [theme.breakpoints.up('lg')]: { flexGrow: 1.3 },
                      display: 'flex',
                      flexDirection: 'column',
                    })}
                  >
                    {changeToRelatedConditionEnabled &&
                    supersededHccs.length > 0 ? (
                      <Card
                        sx={{ p: 2, mb: 1, flex: '0 1 auto' }}
                        variant="outlined"
                        data-pendo-id={`ps-${diagnosisSectionType}-superseded-section`}
                      >
                        <Typography variant="bodybold" component="span">
                          <Box component="span" sx={{ mr: 1 }}>
                            {supersededSectionTitle}
                          </Box>
                          <ActionHelp
                            help={{
                              title: 'What is this?',
                              content: relatedConditionHelpContent,
                            }}
                          />
                        </Typography>
                        <StyledFormGroup>
                          {supersededHccs.map((supersededHcc) => (
                            <SectionSupersededHcc
                              key={`${actionId}-${SUPERSEDED}-${supersededHcc.code}`}
                              actionId={actionId}
                              patient={patient}
                              actionHcc={hcc}
                              supersededHcc={supersededHcc}
                              icdModalOpen={icdModalOpen}
                              handleOpenIcdModal={handleOpenIcdModal}
                              handleCloseIcdModal={handleCloseIcdModal}
                              handleSubmitIcdModal={
                                handleSubmitSupersededIcdModal
                              }
                              onDxOptionToggled={onDxOptionToggled}
                              dxActionCheckboxState={dxActionCheckboxState}
                            />
                          ))}
                        </StyledFormGroup>
                      </Card>
                    ) : null}
                    <Card
                      sx={{ p: 2, flex: '1 1 auto' }}
                      variant="outlined"
                      data-pendo-id={`ps-${diagnosisSectionType}-none-apply-section`}
                    >
                      <Typography variant="bodybold">
                        {supersededHccs.length > 0
                          ? actionSectionTitleWithSuperseded
                          : actionSectionTitle}
                      </Typography>

                      <StyledFormGroup>
                        {completeWithoutCodeEnabled && (
                          <CheckboxWithHighlight
                            data-pendo-id={`ps-${diagnosisSectionType}-none-apply-checkbox`}
                            key={`${actionId}-${COMPLETE_WITHOUT_CODE}`}
                            checked={isDiagnosisCodeSelected(
                              actionId,
                              COMPLETE_WITHOUT_CODE,
                            )}
                            label={
                              isSuggestedDiagnosisAction(subtype)
                                ? 'Assessed and Present without Code'
                                : 'Mark condition as assessed & present without selecting diagnosis'
                            }
                            sx={{ color: 'text.secondary' }}
                            onChange={(e) =>
                              onDxOptionToggled(
                                e.target.checked,
                                actionId,
                                {
                                  code: COMPLETE_WITHOUT_CODE,
                                  description: 'No diagnosis selected',
                                },
                                undefined,
                                false,
                                false,
                              )
                            }
                            disabled={
                              areAnyDiagnosesSelected(actionId) &&
                              !isDiagnosisCodeSelected(
                                actionId,
                                COMPLETE_WITHOUT_CODE,
                              )
                            }
                          />
                        )}
                        <CheckboxWithHighlight
                          data-pendo-id={`ps-${diagnosisSectionType}-none-apply-checkbox`}
                          key={`${actionId}-${diagnosisIncorrectCheckboxId}`}
                          checked={
                            dxActionCheckboxState[actionId].diagnosisIncorrect
                          }
                          label="Assessed and Not Present"
                          sx={{ color: 'text.secondary' }}
                          onChange={(e) =>
                            onDxOptionToggled(
                              e.target.checked,
                              actionId,
                              undefined,
                              undefined,
                              true,
                              false,
                            )
                          }
                          disabled={areAnyDiagnosesSelected(actionId)}
                        />
                        {showNotAssessedOption && (
                          <>
                            <Typography variant="bodybold" sx={{ mt: 1 }}>
                              If patient was not assessed for this condition in
                              this visit
                            </Typography>
                            <CheckboxWithHighlight
                              data-pendo-id={`ps-${diagnosisSectionType}-not-assessed-checkbox`}
                              key={`${actionId}-not-assessed`}
                              checked={
                                dxActionCheckboxState[actionId].notAssessed ===
                                true
                              }
                              label="Not assessed"
                              sx={{ color: 'text.secondary' }}
                              onChange={(e) =>
                                onDxOptionToggled(
                                  e.target.checked,
                                  actionId,
                                  undefined,
                                  undefined,
                                  false,
                                  true,
                                )
                              }
                              disabled={areAnyDiagnosesSelected(actionId)}
                            />
                          </>
                        )}
                      </StyledFormGroup>
                    </Card>
                  </Box>
                </Box>
              </Paper>
            )
          );
        })}
      </Box>
    </>
  );
}

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  height: 15,
  width: 15,
  color: theme.palette.status.warningForeground,
}));

SectionActionableDiagnosis.propTypes = {
  patient: patientShape.isRequired,
  diagnosisActions: PropTypes.arrayOf(actionShape).isRequired,
  dateOfService: PropTypes.instanceOf(Date),
  onChangeDateOfService: PropTypes.func.isRequired,
  diagnosisSectionType: PropTypes.string.isRequired,
  onDxOptionToggled: PropTypes.func.isRequired,
  onCustomIcdEntered: PropTypes.func.isRequired,
  dxActionCheckboxState: PropTypes.objectOf(PropTypes.object).isRequired,
  notAssessedDiagnosisMessageShowFlagState: PropTypes.objectOf(PropTypes.object)
    .isRequired,
  onNotAssessedDiagnosisMessageShowFlagState: PropTypes.func.isRequired,
  showNotAssessedOption: PropTypes.bool.isRequired,
  otherIcdDisplayed: PropTypes.objectOf(PropTypes.object),
};

SectionActionableDiagnosis.defaultProps = {
  dateOfService: null,
  otherIcdDisplayed: {},
};

export default SectionActionableDiagnosis;
