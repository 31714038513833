import PropTypes from 'prop-types';

import CallToActionRibbon from '@/components/CallToActionRibbon';

function getReopenedString(
  inputByName,
  closedDate,
  providerName,
  diagnosisCodes,
) {
  const areDiagnosisCodes = diagnosisCodes.length > 0;

  let reopenedString =
    "Condition reopened because diagnosis wasn't received on a claim";
  if (inputByName || closedDate || (areDiagnosisCodes && !providerName)) {
    reopenedString = reopenedString.concat('. Originally input');
  }
  if (inputByName) {
    reopenedString = reopenedString.concat(` by ${inputByName}`);
  }
  if (closedDate) {
    reopenedString = reopenedString.concat(` on ${closedDate}`);
  }
  if (providerName) {
    reopenedString = reopenedString.concat(
      `. ${providerName} originally diagnosed patient`,
    );
  }
  if (areDiagnosisCodes) {
    if (diagnosisCodes.length === 1) {
      reopenedString = reopenedString.concat(' with code ');
    } else {
      reopenedString = reopenedString.concat(' with codes: ');
    }
    reopenedString = reopenedString.concat(`${diagnosisCodes.join('; ')}`);
  }
  return reopenedString.concat('.');
}

export default function ReopenedDiagnosisRibbon({
  inputByName,
  closedDate,
  providerName,
  diagnosisCodes,
}) {
  return (
    <CallToActionRibbon>
      {getReopenedString(inputByName, closedDate, providerName, diagnosisCodes)}
    </CallToActionRibbon>
  );
}

ReopenedDiagnosisRibbon.propTypes = {
  inputByName: PropTypes.string,
  closedDate: PropTypes.string,
  providerName: PropTypes.string,
  diagnosisCodes: PropTypes.arrayOf(PropTypes.string),
};

ReopenedDiagnosisRibbon.defaultProps = {
  inputByName: undefined,
  closedDate: undefined,
  providerName: undefined,
  diagnosisCodes: [],
};
