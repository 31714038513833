import PropTypes from 'prop-types';
import { Typography, styled } from '@mui/material';

import { PRIORITY_PATIENT_VISIT } from '@/pages/TaskBasedWorkflow/constants';
import UserDrivenEnrollmentFormWidget from '@/pages/TaskBasedWorkflow/components/patientDetailView/UserDrivenEnrollmentFormWidget';
import LearnMoreWidget from '@/pages/TaskBasedWorkflow/components/LearnMoreWidget';
import { actionShape } from '@/pages/MedicationManagement/hooks/useGetPatients';

function PriorityPatientUserDrivenEnrollmentFormTile({
  patientId,
  userDrivenEnrollmentForms,
  ppvAction,
}) {
  const config = userDrivenEnrollmentForms[PRIORITY_PATIENT_VISIT];
  const helpText =
    ppvAction.contextualInfo.bannerHelpText !== ''
      ? ppvAction.contextualInfo.bannerHelpText
      : ppvAction.contextualInfo.helpText;

  if (!config) {
    return null;
  }

  return (
    <StyledBox>
      <HeaderTileSection>
        <Typography variant="bodybold" component="div" marginBottom={1}>
          {config.tile_title}
        </Typography>
        <BodyContainer>
          <Typography variant="bodysmall" component="div">
            {config.tile_description}
          </Typography>
          <LearnMoreWidget
            helpText={helpText}
            title={ppvAction.contextualInfo.category}
            buttonSize={14}
          />
        </BodyContainer>
      </HeaderTileSection>
      <Divider />
      <TileSection>
        <BodyContainer>
          <Typography variant="bodysmall" component="div">
            Next visit:
          </Typography>
          <Typography
            variant="bodysmall"
            component="div"
            style={{ fontWeight: 'bold' }}
          >
            {config.next_visit_date}
          </Typography>
        </BodyContainer>
      </TileSection>
      <Divider />
      <TileSection>
        <BodyContainer>
          <Typography variant="bodysmall" component="div">
            Current cadence:
          </Typography>
          <Typography variant="bodysmall" component="div">
            {config.cadence_text}
          </Typography>
          <UserDrivenEnrollmentFormWidget
            patientId={patientId}
            subtype={PRIORITY_PATIENT_VISIT}
            userDrivenEnrollmentForm={config}
          />
        </BodyContainer>
      </TileSection>
    </StyledBox>
  );
}

PriorityPatientUserDrivenEnrollmentFormTile.propTypes = {
  patientId: PropTypes.number.isRequired,
  userDrivenEnrollmentForms: PropTypes.objectOf(
    PropTypes.shape({
      button_text: PropTypes.string,
      modal_title: PropTypes.string,
      tile_title: PropTypes.string,
      tile_description: PropTypes.string,
      tile_button_text: PropTypes.string,
      next_visit_date: PropTypes.string,
      cadence_text: PropTypes.string,
      fields: PropTypes.array,
    }),
  ).isRequired,
  ppvAction: actionShape.isRequired,
};

const StyledBox = styled('div')`
  display: flex;
  border: 1px solid ${(p) => p.theme.palette.border.base};
  border-radius: ${(p) => 2 * p.theme.shape.borderRadius}px;
`;

const Divider = styled('div')`
  width: 1px;
  background-color: ${(p) => p.theme.palette.border.base};
  margin: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(2)};
`;

const BodyContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing(1)};
`;

const TileSection = styled('div')`
  display: flex;
  align-items: center;
  padding: ${(p) => p.theme.spacing(1)};
  margin-top: auto;
  flex-direction: row;
`;

const HeaderTileSection = styled(TileSection)`
  flex-direction: column;
  align-items: flex-start;
  width: auto;
`;

export default PriorityPatientUserDrivenEnrollmentFormTile;
