import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';

import { actionShape } from '@/utils/transformFrontendAction';
import {
  DiagnosisActionState,
  STATE_COMPLETE,
  ShowDiagnosisActionHistorySetting,
} from '@/pages/PatientSummary/utils/constants';
import InfoLightbulbIcon from '@/components/InfoLightbulbIcon';
import ActionTrigger from '@/pages/PatientSummary/components/SectionCareGaps/ActionTrigger';
import SvuAmount from '@/components/SvuAmount';

function getPreviousCodes(groupedPastDiagnoses) {
  return groupedPastDiagnoses.map((group) => group.icd).join('; ');
}

function getActionHistorySubtext(
  state,
  dateOfService,
  mostRecentlyCompletedByName,
  mostRecentlyCompletedByDate,
  mostRecentExternalCareGapSource,
  mostRecentExternalCareGapDate,
  userAddedDiagnosisCodes,
) {
  const stateString =
    state === DiagnosisActionState.complete
      ? 'Assessed and Present'
      : 'Assessed and Not Present';
  const codesString = userAddedDiagnosisCodes.join('; ');

  const vimDetails =
    mostRecentExternalCareGapSource &&
    mostRecentExternalCareGapDate === dateOfService
      ? `through ${mostRecentExternalCareGapSource.toUpperCase()} `
      : '';

  if (mostRecentlyCompletedByName === 'claims') {
    return `Through claims on ${mostRecentlyCompletedByDate}`;
  }

  const userString =
    mostRecentlyCompletedByName === '' && mostRecentlyCompletedByDate === ''
      ? `Marked as ${stateString} ${vimDetails}`
      : `Marked as ${stateString} by ${mostRecentlyCompletedByName} ${vimDetails}on ${mostRecentlyCompletedByDate}`;
  if (!codesString) {
    return userString;
  }
  if (userAddedDiagnosisCodes.length === 1) {
    return userString.concat(` with code ${codesString}`);
  }
  return userString.concat(` with codes ${codesString}`);
}

function isDiagnosisIncorrect(state) {
  return state === DiagnosisActionState.diagnosisIncorrect;
}

/**
 * This function relies on the implementation of the progress field for each action
 * and the understanding that it returns an array 'bullets' each containing a 'state' property with possible values of:
 * 1. complete
 * 2. incomplete
 * 3. failed
 *
 * It returns:
 * - -1 if the state is 'diagnosis_incorrect'
 * - 2 if the last bullet is completed
 * - and 1 if the last bullet is incomplete or failed
 * which is used by Stepper to determine current status of the action
 * @param stepStates
 */
function determineStepStatus(state, bullets) {
  if (isDiagnosisIncorrect(state)) return -1;

  const lastBullet = bullets.slice(-1)[0];

  return lastBullet?.state === STATE_COMPLETE ? 2 : 1;
}

function getActionHistoryToDisplay({
  showDiagnosisActionHistoryToggle,
  incompleteDiagnosisActions,
  completedDiagnosisActions,
}) {
  switch (showDiagnosisActionHistoryToggle) {
    case ShowDiagnosisActionHistorySetting.awaitingClaim:
      return incompleteDiagnosisActions;
    case ShowDiagnosisActionHistorySetting.completed:
      return completedDiagnosisActions;
    default:
      return [];
  }
}

function getMostRecentServiceProvider(provider, date) {
  if (provider) {
    if (date) {
      return `Most recent service provider: ${provider} on ${date}`;
    }
    return `Most recent service provider: ${provider}`;
  }
  return null;
}

function SectionSummaryDiagnosis({
  incompleteDiagnosisActions,
  isHistoricalConditions,
  completedDiagnosisActions,
  showDiagnosisActionHistoryToggle,
}) {
  const diagnosisActionsToDisplay = getActionHistoryToDisplay({
    showDiagnosisActionHistoryToggle,
    incompleteDiagnosisActions,
    completedDiagnosisActions,
  });

  return (
    <Box
      data-dd-synthetics-id={
        isHistoricalConditions
          ? 'completed-historical-conditions-section'
          : undefined
      }
      sx={{ p: 1 }}
    >
      {showDiagnosisActionHistoryToggle ===
        ShowDiagnosisActionHistorySetting.awaitingClaim && (
        <Paper
          variant="outlined"
          sx={{
            alignItems: 'center',
            borderColor: 'status.infoForeground',
            display: 'flex',
            py: 2,
            px: 1,
          }}
        >
          <InfoLightbulbIcon />
          <Typography sx={{ ml: 2 }}>
            The conditions below all have matching diagnosis codes that were
            submitted. We are waiting for the claim to come back to confirm the
            selected codes were billed for. Conditions will be reopened for you
            in case no claim is received.
          </Typography>
        </Paper>
      )}

      {diagnosisActionsToDisplay.length > 0 &&
        diagnosisActionsToDisplay.map(
          ({
            id,
            mostRecentlyCompletedByName,
            mostRecentlyCompletedByDate,
            mostRecentProviderForDisplay,
            mostRecentExternalCareGapSource,
            mostRecentExternalCareGapDate,
            date,
            dateOfService,
            state,
            progress: { bullets },
            svusBeforePromo,
            svusAfterPromo,
            hcc,
            pastDiagnoses: pastGroupedDiagnoses,
            userAddedDiagnosisCodes,
          }) => {
            return (
              hcc && (
                <Paper
                  key={id}
                  sx={{ p: 1, mt: 1, borderColor: 'border.base' }}
                  variant="outlined"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      pr: 1,
                    }}
                  >
                    <Typography variant="h3">{hcc.name}</Typography>
                    <Typography variant="subtext" component="span">
                      <SvuAmount
                        svuPrePromo={svusBeforePromo}
                        svuPostPromo={svusAfterPromo}
                        svuText
                      />
                      available
                    </Typography>
                  </Box>

                  <Stepper
                    activeStep={determineStepStatus(state, bullets)}
                    sx={{
                      my: 1,
                    }}
                  >
                    <Step disabled={isDiagnosisIncorrect(state)}>
                      <StepLabel>Select diagnoses assessed</StepLabel>
                    </Step>
                    <Step disabled={isDiagnosisIncorrect(state)}>
                      <StepLabel>
                        {showDiagnosisActionHistoryToggle ===
                        ShowDiagnosisActionHistorySetting.completed
                          ? 'Billed on claim'
                          : 'Ensure claim includes selected diagnosis'}
                      </StepLabel>
                    </Step>
                  </Stepper>

                  {pastGroupedDiagnoses && pastGroupedDiagnoses.length > 0 && (
                    <Typography component="span" alignItems="center" mb={1.5}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: 'normal',
                          color: 'text.secondary',
                          ml: 2,
                        }}
                      >
                        Previously billed codes:{' '}
                        {/* If we ever start showing info for SDAs, this URL will break. See PR #5958 */}
                        <ActionTrigger
                          data-pendo-id={`show-history-${id}`}
                          trigger={{
                            dataUrl: `actions/${id}/past_diagnosis`,
                            text: getPreviousCodes(pastGroupedDiagnoses),
                          }}
                          color="text.secondary"
                        />
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'text.secondary',
                          fontWeight: 'normal',
                          ml: 2,
                        }}
                      >
                        {getActionHistorySubtext(
                          state,
                          dateOfService,
                          mostRecentlyCompletedByName,
                          mostRecentlyCompletedByDate,
                          mostRecentExternalCareGapSource,
                          mostRecentExternalCareGapDate,
                          userAddedDiagnosisCodes,
                        )}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'text.secondary',
                          fontWeight: 'normal',
                          ml: 2,
                        }}
                      >
                        {getMostRecentServiceProvider(
                          mostRecentProviderForDisplay,
                          date,
                        )}
                      </Typography>
                    </Typography>
                  )}
                </Paper>
              )
            );
          },
        )}
    </Box>
  );
}

SectionSummaryDiagnosis.propTypes = {
  incompleteDiagnosisActions: PropTypes.arrayOf(actionShape),
  isHistoricalConditions: PropTypes.bool,
  completedDiagnosisActions: PropTypes.arrayOf(actionShape),
  showDiagnosisActionHistoryToggle: PropTypes.string,
};

SectionSummaryDiagnosis.defaultProps = {
  incompleteDiagnosisActions: [],
  isHistoricalConditions: false,
  completedDiagnosisActions: [],
  showDiagnosisActionHistoryToggle: ShowDiagnosisActionHistorySetting.off,
};

export default SectionSummaryDiagnosis;
