import { useCallback, useReducer } from 'react';

function actionFormsStateReducer(state, action) {
  const { type } = action;

  if (type === 'SAVE_ACTION_FORM') {
    const { patientAction, step, formSubmission } = action.payload;

    return {
      ...state,
      [patientAction.actionId]: {
        [step.stepId]: formSubmission,
      },
    };
  }
  if (type === 'REMOVE_ACTION_FORM') {
    const { patientAction } = action.payload;
    const newState = { ...state };

    delete newState[patientAction.actionId];
    return newState;
  }
  if (type === 'CLEAR_ALL_ACTION_FORM') {
    return {};
  }

  return state;
}

/**
 * Manages step-based action form submission states. Internally it uses a
 * reducer to manage this complexity, though that is an implementation detail.
 *
 * This hook exposes an object of three properties:
 *
 * - formState: A nested object keyed by the action id and step id of the form
 *     that was submitted. This object can then be iterated over when
 *     the patient summary is submitted. In the case of errors, the
 *     identifiers can be used, in conjunction with the patient actions
 *     data, to generate forensic information.
 *
 *     The format of this data structure is:
 *     {
 *       [actionId]: {
 *         [stepId]: {
 *           field_name_1: field_value_1,
 *           field_name_2: field_value_2,
 *           etc
 *         }
 *       }
 *     }
 * - saveActionForm: This takes a single parameter that is an object of three
 *     properties. It uses these three properties to generate the
 *     state value seen above. Those three properties:
 *
 *      - patientAction: The full step-based action object
 *      - step: The step within the step-based action that was submitted
 *      - formSubmission: Form data from that submission, derived from the
 *             step's execution requirements property.
 */
export default function useActionFormStateV2() {
  const [formState, dispatch] = useReducer(actionFormsStateReducer, {});

  const saveActionForm = useCallback(
    ({ patientAction, step, formSubmission }) => {
      dispatch({
        type: 'SAVE_ACTION_FORM',
        payload: { patientAction, step, formSubmission },
      });
    },
    [dispatch],
  );

  const removeActionForm = useCallback(
    ({ patientAction }) => {
      dispatch({
        type: 'REMOVE_ACTION_FORM',
        payload: { patientAction },
      });
    },
    [dispatch],
  );

  const clearAllActionForm = useCallback(() => {
    dispatch({
      type: 'CLEAR_ALL_ACTION_FORM',
    });
  }, [dispatch]);

  return {
    formState,
    saveActionForm,
    removeActionForm,
    clearAllActionForm,
  };
}
