import { func, instanceOf } from 'prop-types';
import { useState } from 'react';

import usePendoId from '@ob/hooks/usePendoId';

import PriorDateViewModal from '../PriorDateViewModal';
import { dateViewShape } from '../../hooks/useGetPriorDateView';
import { isDateOfServiceInDateView } from '../../utils/isValidPriorDate';

import BasePatientSummaryNavItem from './BasePatientSummaryNavItem';

function PriorDateViewButton({
  dateOfService,
  onChangeDateOfService,
  dateView,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const pendoId = usePendoId({ prefix: ['prior_date_view'] });
  const buttonPendoId = pendoId(
    dateView.buttonText.replaceAll(' ', '-').toLowerCase(),
  );

  return (
    <>
      <BasePatientSummaryNavItem
        active={isDateOfServiceInDateView(dateOfService, dateView)}
        onClick={() => setModalOpen(true)}
        label={dateView.buttonText}
        data-pendo-id={buttonPendoId}
      />
      <PriorDateViewModal
        dateOfService={dateOfService}
        onClose={() => setModalOpen(false)}
        onPickDate={onChangeDateOfService}
        open={modalOpen}
        dateView={dateView}
      />
    </>
  );
}

PriorDateViewButton.propTypes = {
  dateOfService: instanceOf(Date),
  dateView: dateViewShape.isRequired,
  onChangeDateOfService: func.isRequired,
};
PriorDateViewButton.defaultProps = {
  dateOfService: null,
};

export default PriorDateViewButton;
