/* eslint-disable react/forbid-prop-types */
import { isEmpty } from 'lodash';
import { Button, Typography } from '@mui/material';
import { array, func, object } from 'prop-types';
import { format } from 'date-fns';

import { DISPLAY_DATE_FORMAT } from '@/pages/PatientSummary/utils/constants';
import useGetUserFilters from '@/hooks/useGetUserFilters';

function formatValue(executionRequirement, value, data) {
  const { name, type, options, choices } = executionRequirement;
  if (
    (name === 'attest' && type === 'radio') ||
    (name === 'functional_assessment_reviewed' && type === 'checkbox')
  )
    return undefined;
  if (type === 'date') {
    return value instanceof Date ? format(value, DISPLAY_DATE_FORMAT) : value;
  }
  if (type === 'dropdown') {
    const option = options?.find(([v]) => v === value);
    return option ? option[1] : null;
  }
  if (type === 'radio') {
    const choice = choices?.find(([v]) => v === value);
    return choice ? choice[1] : null;
  }
  if (type === 'fileupload') {
    return value ? value.map((file) => file.name) : null;
  }
  if (type === 'office') {
    const office = data?.offices?.find((office) => office.id === value);
    return office ? office.name : null;
  }
  if (type === 'provider') {
    const provider = data?.providers?.find((p) => p.id === value);
    return provider ? provider.name : null;
  }

  return value;
}

/**
 * Given a row form state and the execution requirements for the current form
 * state, generate a human-readable summary of the form state.
 *
 * The executionRequirement prop is used to generate labels and informs how
 * form field data should be formatted for the user (e.g. Dates are transformed
 * into MM-dd-yyyy).
 *
 * @param executionRequirement
 * @param values
 * @returns {JSX.Element|null}
 */
function CareGapRowDetail({ executionRequirement, values, filterData }) {
  const { label, name, type, options, choices } = executionRequirement;

  // Checkboxes are a special beast: instead of being a single field with a
  // single name, they're an aggregate of similarly-named fields that are
  // collected together. When we render out the detail here, we need to find
  // all the named options that are checked and display their labels.
  if (type === 'checkboxlist') {
    const items = options || choices;
    return (
      <>
        {items.map(([optionName, optionLabel]) => {
          if (!values[optionName]) return null;
          return (
            <Typography variant="bodybold" key={optionName}>
              {optionLabel}
            </Typography>
          );
        })}
      </>
    );
  }

  const formattedValue = formatValue(
    executionRequirement,
    values[name],
    filterData,
  );
  if (!formattedValue) return null;
  return (
    <>
      <Typography variant="bodybold">{label}</Typography>
      <Typography>{formattedValue}</Typography>
    </>
  );
}

CareGapRowDetail.propTypes = {
  executionRequirement: object.isRequired,
  values: object,
  filterData: object,
};

CareGapRowDetail.defaultProps = {
  values: {},
  filterData: {},
};

export default function CareGapRowDetails({
  actionState,
  executionRequirements,
  onEditStep,
}) {
  // This hook is called by PatientSummaryPage so the data retrieved here comes
  // from the cache. It is used in Office and Provider dropdowns to set the options.
  // Here that same data is used to appropriately display the name of the selected
  // office or provider rather than the id.
  const { data: filterData } = useGetUserFilters();

  if (isEmpty(actionState) || isEmpty(executionRequirements)) return null;

  return (
    <>
      {executionRequirements.map((req) => (
        <CareGapRowDetail
          key={req.name}
          executionRequirement={req}
          values={actionState}
          filterData={filterData}
        />
      ))}

      <Button variant="text" onClick={onEditStep}>
        Edit
      </Button>
    </>
  );
}

CareGapRowDetails.propTypes = {
  actionState: object,
  executionRequirements: array,
  onEditStep: func.isRequired,
};

CareGapRowDetails.defaultProps = {
  actionState: null,
  executionRequirements: [],
};
