import PropTypes, { number } from 'prop-types';
import styled from 'styled-components';
import { Box, Paper, Typography } from '@mui/material';
import { useState } from 'react';

import BaseModal from '@/components/BaseModal';
import BaseAutocomplete from '@/components/BaseAutocomplete';
import useGetDiagnosisOptions from '@/pages/PatientSummary/hooks/useGetDiagnosisOptions';
import { patientShape } from '@/utils/transformFrontendPatient';
import { hccShape } from '@/utils/transformFrontendAction';
import { ReactComponent as CheckIcon } from '@/assets/icons/checkTrue.svg';
import { ReactComponent as WarningIcon } from '@/assets/icons/warning.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';
import CheckboxWithHighlight from '@/pages/PatientSummary/components/CheckboxWithHighlight';
import useFeatureFlag from '@/utils/useFeatureFlag';
import { COMPLETE_WITHOUT_CODE } from '@/pages/PatientSummary/utils/constants';

function isIcdCodeRelevant(selectedDiagnosis, patient, actionHcc, overrideHcc) {
  const enteredHcc = selectedDiagnosis.hcc;

  const supersedingHccs = patient.hccIsSupersededBy[actionHcc] || [];
  const supersededHccs = patient.hccSupersedes[actionHcc] || [];

  const isHigherSeverity = supersedingHccs.includes(enteredHcc);
  const isLowerSeverity = supersededHccs.includes(enteredHcc);
  const isExactSeverity = enteredHcc === actionHcc;

  const codeRelatedToAction = overrideHcc
    ? enteredHcc === overrideHcc
    : isExactSeverity || isHigherSeverity || isLowerSeverity;

  return {
    isHigherSeverity,
    isLowerSeverity,
    isExactSeverity,
    codeRelatedToAction,
  };
}

export default function ModalIcd({
  onClose,
  onSubmit,
  patient,
  actionHcc,
  overrideHcc,
  actionId,
}) {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [codeSearchTerm, setCodeSearchTerm] = useState('');
  const { data: diagnosisOptions } = useGetDiagnosisOptions({
    searchTerm: codeSearchTerm,
    hcc: overrideHcc?.code || actionHcc.code,
    patientId: patient.id,
    actionId,
  });
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(false);

  const allowNoCode =
    useFeatureFlag('patientSummaryV2MorphDxWithoutCode') && overrideHcc;
  const completeWithoutCodeEnabled = useFeatureFlag(
    'patientSummaryV2EnableCompleteWithoutCode',
  );

  const handleDiagnosisCodeChange = (e, value) => {
    if (value.length >= 3) {
      setCodeSearchTerm(value);
    }
  };

  const handleIcdSelected = (e, newDiagnosis) => {
    if (newDiagnosis) {
      const {
        isHigherSeverity,
        isLowerSeverity,
        isExactSeverity,
        codeRelatedToAction,
      } = isIcdCodeRelevant(
        newDiagnosis,
        patient,
        actionHcc.code,
        overrideHcc?.code,
      );
      setSelectedDiagnosis({
        ...newDiagnosis,
        isHigherSeverity,
        isLowerSeverity,
        isExactSeverity,
        codeRelatedToAction,
      });
      setSubmitDisabled(overrideHcc && !codeRelatedToAction);
    } else {
      setSubmitDisabled(true);
    }
  };

  const handleNoCodeSelected = (e) => {
    if (e.target.checked) {
      setSelectedDiagnosis({
        value: COMPLETE_WITHOUT_CODE,
        label: 'No diagnosis selected',
        hcc: overrideHcc.code,
        hcc_abbrev: overrideHcc.name,
        isHigherSeverity: false,
        isLowerSeverity: true,
        isExactSeverity: false,
        codeRelatedToAction: completeWithoutCodeEnabled,
      });
      setSubmitDisabled(false);
    } else {
      setSelectedDiagnosis(false);
      setSubmitDisabled(true);
    }
  };

  const clearState = () => {
    setSelectedDiagnosis(false);
    setCodeSearchTerm('');
  };

  const handleClose = () => {
    onClose();
    clearState();
  };

  const handleSubmit = () => {
    onSubmit(selectedDiagnosis);
    clearState();
  };

  return (
    <BaseModal
      dataPendoId="ps-modal-icd"
      title={overrideHcc ? 'Change Condition Severity' : 'Specify Another Code'}
      onClose={handleClose}
      open
      onSubmit={handleSubmit}
      submitButtonDisabled={submitDisabled}
      sx={{ width: 500 }}
    >
      <Typography variant="label">
        {overrideHcc
          ? 'New Chronic Condition Category'
          : 'Chronic Condition Category'}
      </Typography>
      <Typography>{overrideHcc?.name || actionHcc.name}</Typography>

      <Typography color="text.label" variant="bodybold" mt={2}>
        {overrideHcc ? 'Specify a code below' : 'Specify another code below'}
      </Typography>
      <BaseAutocomplete
        autoHighlight
        fullWidth
        id="diagnosis-code-autocomplete"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onInputChange={handleDiagnosisCodeChange}
        onChange={handleIcdSelected}
        options={diagnosisOptions || []}
        getOptionLabel={(option) =>
          option ? `${option.value}: ${option.label}` : ''
        }
        placeholder="Enter a diagnosis code"
        sx={{
          mt: 0.75,
        }}
        data-pendo-id="ps-modal-icd-autocomplete"
      />

      {allowNoCode ? (
        <CheckboxWithHighlight
          onChange={handleNoCodeSelected}
          checked={selectedDiagnosis.value === COMPLETE_WITHOUT_CODE}
          label="Do not specify a code"
          InputSx={{ mt: 2 }}
        />
      ) : null}

      {selectedDiagnosis ? (
        <CodeApplicabilityMessage
          actionHcc={actionHcc}
          selectedDiagnosis={selectedDiagnosis}
          isOverrideHcc={!!overrideHcc}
        />
      ) : null}
    </BaseModal>
  );
}

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  height: 16,
  width: 16,
  color: theme.palette.status.successForeground,
}));

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  height: 16,
  width: 16,
  color: theme.palette.status.warningForeground,
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  height: 16,
  width: 16,
  color: theme.palette.status.infoForeground,
}));

ModalIcd.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  patient: patientShape.isRequired,
  actionHcc: hccShape.isRequired,
  overrideHcc: hccShape,
  actionId: number.isRequired,
};

ModalIcd.defaultProps = {
  overrideHcc: undefined,
};

function CodeApplicabilityMessage({
  actionHcc,
  selectedDiagnosis,
  isOverrideHcc,
}) {
  const selectedHcc = {
    code: selectedDiagnosis.hcc,
    name: selectedDiagnosis.hcc_abbrev,
  };
  const isCompleteWithoutCode =
    selectedDiagnosis.value === COMPLETE_WITHOUT_CODE;

  let message;
  let icon;
  let backgroundColor;
  let borderColor;

  if (isCompleteWithoutCode) {
    if (!selectedDiagnosis.codeRelatedToAction) {
      message = `This will change this condition to a ${selectedHcc.name} (HCC ${selectedHcc.code}) action in an incomplete state after submitting this patient summary and refreshing the page.`;
      icon = <StyledWarningIcon />;
      backgroundColor = 'status.warningBackground';
      borderColor = 'status.warningForeground';
    } else {
      return null;
    }
  } else if (isOverrideHcc) {
    if (selectedDiagnosis.codeRelatedToAction) {
      message = `This will decrease the severity of this condition to ${selectedHcc.name} from ${actionHcc.name}.`;
      icon = <StyledCheckIcon />;
      backgroundColor = 'status.successBackground';
      borderColor = 'status.successForeground';
    } else if (selectedDiagnosis.isLowerSeverity) {
      message = `This code doesn't match the condition you've selected. Did you mean to select ${selectedHcc.name}?`;
      icon = <StyledWarningIcon />;
      backgroundColor = 'status.warningBackground';
      borderColor = 'status.warningForeground';
    } else {
      message =
        'This code doesn\'t match the condition you\'ve selected. Please select this code in "Specify Another Code."';
      icon = <StyledWarningIcon />;
      backgroundColor = 'status.warningBackground';
      borderColor = 'status.warningForeground';
    }
  } else if (selectedDiagnosis.isExactSeverity) {
    message = 'Code matches condition category';
    icon = <StyledCheckIcon />;
    backgroundColor = 'status.successBackground';
    borderColor = 'status.successForeground';
  } else if (selectedDiagnosis.isHigherSeverity) {
    message = `When a claim is received with this code, this will increase the severity of this condition to ${selectedHcc.name} from ${actionHcc.name}.`;
    icon = <StyledInfoIcon />;
    backgroundColor = 'status.infoBackground';
    borderColor = 'status.infoForeground';
  } else if (selectedDiagnosis.isLowerSeverity) {
    message = `This will decrease the severity of this condition to ${selectedHcc.name} from ${actionHcc.name}. Are you sure you want to change the severity of the action?`;
    icon = <StyledInfoIcon />;
    backgroundColor = 'status.infoBackground';
    borderColor = 'status.infoForeground';
  } else {
    message = 'Completed, but selected ICD code will not close gap';
    icon = <StyledWarningIcon />;
    backgroundColor = 'status.warningBackground';
    borderColor = 'status.warningForeground';
  }

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor,
        padding: 1,
        mt: 2,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: '0 0 16px' }}>{icon}</Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', flex: '1', pl: 1 }}
        >
          <Typography variant="bodybold" mb={1}>
            {isCompleteWithoutCode
              ? selectedDiagnosis.label
              : `${selectedDiagnosis.value} ${selectedDiagnosis.label}`}
          </Typography>
          <Typography>{message}</Typography>
        </Box>
      </Box>
    </Paper>
  );
}

CodeApplicabilityMessage.propTypes = {
  actionHcc: hccShape.isRequired,
  selectedDiagnosis: PropTypes.object.isRequired,
  isOverrideHcc: PropTypes.bool,
};

CodeApplicabilityMessage.defaultProps = {
  isOverrideHcc: false,
};
