import { useMemo } from 'react';

/**
 * A memoized filter function. Expects both `data` and `filter` to have stable
 * identities across re-renders, otherwise the filtered view of the data will
 * be regenerated.
 *
 * @param {T[]} data
 * @param {(item: T) => boolean} filterFn
 * @returns {T[]}
 */
export default function useFilter(data, filterFn) {
  return useMemo(() => {
    return data.filter(filterFn);
  }, [data, filterFn]);
}
