import { forOwn, join } from 'lodash';
import { format } from 'date-fns';

import {
  URL_DATE_FORMAT,
  correlationUidKey,
  pcpVisitDateOfServiceKey,
  pcpVisitServicingProviderKey,
  servicingProviderKey,
  totalFormsKey,
  userAddedDiagCodesKey,
} from '@/pages/PatientSummary/utils/constants';

/**
 * pcp_visit_servicing_provider: is given to us in the initial expected form
 * however this is not required for a majority of ActionForms. Ignore this unless
 * explicitly added by Action type
 *
 * @type {Set<string>}
 */
const fieldsToIgnore = new Set([
  pcpVisitServicingProviderKey,
  servicingProviderKey,
  pcpVisitDateOfServiceKey,
]);

/**
 * Make sure the value we send to the server is a string in the format we
 * expect it to be.
 *
 * @param value
 * @returns {string}
 */
function formatValue(value) {
  if (value instanceof Date) {
    return format(value, URL_DATE_FORMAT);
  }
  return String(value);
}

/**
 * Given a list of ActionForms we intend to submit from the PatientSummaryPage
 * Create the desired multipart / FormData that is expected from the legacy
 * Django Forms PatientSummary POST endpoint
 *
 * The updated_at value is copied over from the server response of
 * incompleteActionsV1 to validate update request is valid.
 *
 * @param currentActionForms [ diagnosisActionFormShape ]
 * @param dateOfService
 * @param providerId
 * @param incompleteActionsV1
 * @param correlationUid
 * @returns {FormData}
 */
export default function transformActionForms(
  currentActionForms,
  dateOfService,
  providerId,
  incompleteActionsV1,
  correlationUid,
) {
  const actionKeys = Object.keys(currentActionForms).filter(
    (key) => key !== userAddedDiagCodesKey,
  );
  const numForms = actionKeys.length;
  const transformedActionForms = new FormData();
  transformedActionForms.append(totalFormsKey, numForms);
  transformedActionForms.append('form-INITIAL_FORMS', numForms);
  transformedActionForms.append('form-MIN_NUM_FORMS', numForms);
  transformedActionForms.append('form-MAX_NUM_FORMS', numForms);
  transformedActionForms.append(
    pcpVisitDateOfServiceKey,
    format(new Date(dateOfService), URL_DATE_FORMAT),
  );
  transformedActionForms.append(pcpVisitServicingProviderKey, providerId);
  transformedActionForms.append(servicingProviderKey, providerId);
  transformedActionForms.append(
    userAddedDiagCodesKey,
    join(currentActionForms.userAddedDiagnosisCodes, ','),
  );
  transformedActionForms.append('visit_type', '');
  transformedActionForms.append('visit_comments', '');
  transformedActionForms.append(correlationUidKey, correlationUid);

  const updatedActionForms = { ...currentActionForms };
  // Updating of actions in Patient Summary require payload
  // to have matching timestamps with DB as validation
  incompleteActionsV1.forEach((action) => {
    if (action.id in updatedActionForms) {
      const currentAction = { ...updatedActionForms[action.id] };
      currentAction.updated_at = action.updatedAt;
      updatedActionForms[action.id] = currentAction;
    }
  });

  Object.values(updatedActionForms).forEach((actionForm, index) => {
    const fieldPrefix = `form-${index}`;

    // copy over existing key-values
    forOwn(actionForm, (value, key) => {
      if (!fieldsToIgnore.has(key)) {
        transformedActionForms.append(
          `${fieldPrefix}-${key}`,
          formatValue(value),
        );
      }
    });

    // add additional form fields
    transformedActionForms.append(
      `${fieldPrefix}-${pcpVisitDateOfServiceKey}`,
      format(new Date(dateOfService), URL_DATE_FORMAT),
    );
    transformedActionForms.append(
      `${fieldPrefix}-${pcpVisitServicingProviderKey}`,
      providerId,
    );
    transformedActionForms.append(
      `${fieldPrefix}-${servicingProviderKey}`,
      providerId,
    );
  });

  return transformedActionForms;
}
