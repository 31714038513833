import { Button } from '@mui/material';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function NO_OP() {}

export default function BasePatientSummaryNavItem({
  active,
  disabled,
  label,
  onClick,
  'data-pendo-id': pendoId,
}) {
  if (active) {
    return <ActiveTabLabel>{label}</ActiveTabLabel>;
  }
  return (
    <Button
      variant="text"
      size="large"
      sx={{ p: 1 }}
      onClick={onClick ?? NO_OP}
      disabled={disabled}
      data-pendo-id={pendoId}
    >
      <ButtonLabel>{label}</ButtonLabel>
    </Button>
  );
}
BasePatientSummaryNavItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  'data-pendo-id': PropTypes.string,
};
BasePatientSummaryNavItem.defaultProps = {
  active: false,
  disabled: false,
  onClick: null,
  'data-pendo-id': null,
};

const ButtonLabel = styled('span')(({ theme }) => ({
  marginLeft: '6px',
  color: theme.palette.text.primary,
  ...theme.typography.linkextralarge,
}));

export const ActiveTabLabel = styled('span')(({ theme }) => {
  return {
    padding: '8px 8px',
    textDecoration: 'underline',
    textDecorationColor: theme.palette.text.link,
    textUnderlineOffset: theme.spacing(1),
    color: theme.palette.text.link,
    cursor: 'default',
    ...theme.typography.bodyextralargebold,
  };
});
