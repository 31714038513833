function transformEditableTag(editableTag) {
  return {
    description: editableTag.description,
    id: editableTag.id,
    name: editableTag.name,
    staffOnly: editableTag.staff_only,
    svuCohort: editableTag.svu_cohort,
    visible: editableTag.visible,
  };
}

export default function transformFrontendPatientTags(editableTags) {
  return editableTags ? editableTags.map(transformEditableTag) : [];
}
