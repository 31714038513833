import { format } from 'date-fns';

import { ISO_DATE_FORMAT } from '@/pages/PatientSummary/utils/constants';

export function constructChartUploadFilename(
  patient,
  actionName,
  originalFilename,
) {
  const extension = originalFilename.split('.').pop();
  const finalFilename =
    `${patient.medicalGroup.name}` +
    `-${patient.memberNumber}-${actionName}.${extension}`;
  return finalFilename.replaceAll(' ', '_');
}

export function updateDateOnChartUploadFilename(dateOfService, newFileName) {
  const formattedDateOfService = format(
    new Date(dateOfService),
    ISO_DATE_FORMAT,
  );
  return `${formattedDateOfService}-${newFileName}`;
}

export default function renameChartUploadFile(
  originalFile,
  dateOfService,
  newFileName,
) {
  const newChartUploadFilename = updateDateOnChartUploadFilename(
    dateOfService,
    newFileName,
  );
  return new File(originalFile, newChartUploadFilename, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}
