import styled from 'styled-components';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

export function ActionHistoryButton({
  'data-dd-synthetics-id': dataDdSyntheticsId,
  'data-pendo-id': dataPendoId,
  onClick,
  disabled,
  buttonColor,
  children,
}) {
  return (
    <StyledButton
      data-dd-synthetics-id={dataDdSyntheticsId}
      data-pendo-id={dataPendoId}
      onClick={onClick}
      disabled={disabled}
      variant="text"
      sx={{ color: buttonColor }}
    >
      {children}
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
`;

ActionHistoryButton.propTypes = {
  'data-dd-synthetics-id': PropTypes.string,
  'data-pendo-id': PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  buttonColor: PropTypes.string,
  children: PropTypes.any,
};

ActionHistoryButton.defaultProps = {
  'data-dd-synthetics-id': undefined,
  'data-pendo-id': undefined,
  onClick: undefined,
  disabled: undefined,
  buttonColor: 'text.primary',
  children: undefined,
};

export default ActionHistoryButton;
