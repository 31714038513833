import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { parseISO, startOfDay } from 'date-fns';

// {
//   "date_views": [
//       {
//           "min_date": "2024-04-11T22:02:44.321",
//           "max_date": "2024-04-24T22:02:44.321",
//           "modal_title": "View Prior Visits",
//           "modal_message": "Viewing the patient summary for the previous two weeks allows you to close well-child visits and missed care gaps.",
//           "button_text": "View Prior Visits"
//       }
//   ]
// }
// response looks like above

export const dateViewShape = PropTypes.shape({
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  modalTitle: PropTypes.string,
  modalMessage: PropTypes.string,
  buttonText: PropTypes.string,
});

async function getDateViews(patientId) {
  const response = await axios({
    method: 'get',
    url: `/api/patient_summary/prior_date_view`,
    params: {
      patient_id: patientId,
    },
  });

  return response.data;
}

function transformDateViewResponse(dateView) {
  return {
    minDate: startOfDay(parseISO(dateView.min_date)),
    maxDate: startOfDay(parseISO(dateView.max_date)),
    modalTitle: dateView.modal_title,
    modalMessage: dateView.modal_message,
    buttonText: dateView.button_text,
  };
}

function transformResponse(response) {
  if (!response) {
    return [];
  }
  return response.date_views.map(transformDateViewResponse);
}

export default function useGetPriorDateView(patientId) {
  return useQuery(
    ['prior-dates-views', patientId],
    () => getDateViews(patientId),
    {
      select: transformResponse,
      useErrorBoundary: true,
    },
  );
}
