import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

export default function SvuNumber({ svusBeforePromo, svusAfterPromo }) {
  if (!svusAfterPromo || svusBeforePromo === svusAfterPromo) {
    return (
      <Typography component="span" fontSize="inherit">
        {svusBeforePromo} SVU
      </Typography>
    );
  }
  return (
    <Typography component="span" fontSize="inherit">
      <Typography
        component="span"
        sx={{
          fontSize: 'inherit',
          textDecoration: 'line-through',
        }}
      >
        {svusBeforePromo}
      </Typography>{' '}
      {svusAfterPromo} SVU
    </Typography>
  );
}

SvuNumber.propTypes = {
  svusBeforePromo: PropTypes.number.isRequired,
  svusAfterPromo: PropTypes.number,
};

SvuNumber.defaultProps = {
  svusAfterPromo: undefined,
};
