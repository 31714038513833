import PropTypes, { bool, func } from 'prop-types';
import { Button, Divider, Stack } from '@mui/material';
import { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { ThemeProvider } from '@mui/material/styles';

import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import { ReactComponent as CopiedIcon } from '@/assets/icons/copied.svg';
import { ReactComponent as PrinterIcon } from '@/assets/icons/printer.svg';
import UserDrivenEnrollmentFormWidget from '@/pages/TaskBasedWorkflow/components/patientDetailView/UserDrivenEnrollmentFormWidget';
import { patientShape } from '@/utils/transformFrontendPatient';
import useFeatureFlag from '@/utils/useFeatureFlag';
import { PRIORITY_PATIENT_VISIT } from '@/pages/TaskBasedWorkflow/constants';
import CopyPasteModal from '@/components/CopyPasteModal/CopyPasteModal';
import themeV2 from '@/themeV2';

import PatientDetailsButton from './PatientDetailsButton';
import PatientProgramEnrollments from './PatientProgramEnrollments';

function NewCopyPasteButton({ patientId, visuallyHidden }) {
  const [open, setOpen] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);

  return (
    <ThemeProvider theme={themeV2}>
      <PatientDetailsButton
        onClick={() => setOpen(true)}
        visuallyHidden={visuallyHidden}
        label="Copy gaps to clipboard"
        icon={hasCopied ? <StyledCopiedIcon /> : <StyledCopyIcon />}
        pendoId="ps-copy-button"
      />
      <CopyPasteModal
        patientId={patientId}
        open={open}
        onClose={() => setOpen(false)}
        onCopy={() => setHasCopied(true)}
      />
    </ThemeProvider>
  );
}
NewCopyPasteButton.propTypes = {
  patientId: PropTypes.number.isRequired,
  visuallyHidden: PropTypes.bool.isRequired,
};

function CopyPasteButton({
  showNew,
  showOld,
  visuallyHidden,
  patientId,
  onCopyPatientSummary,
}) {
  if (showNew) {
    return (
      <NewCopyPasteButton
        visuallyHidden={visuallyHidden}
        patientId={patientId}
      />
    );
  }

  if (showOld) {
    return (
      <PatientDetailsButton
        onClick={onCopyPatientSummary}
        visuallyHidden={visuallyHidden}
        label="Copy"
        icon={<StyledCopyIcon />}
        pendoId="ps-copy-button"
      />
    );
  }

  return null;
}
CopyPasteButton.propTypes = {
  showNew: PropTypes.bool.isRequired,
  showOld: PropTypes.bool.isRequired,
  visuallyHidden: PropTypes.bool.isRequired,
  patientId: PropTypes.number.isRequired,
  onCopyPatientSummary: PropTypes.func.isRequired,
};

const PatientDetailsButtons = forwardRef(function PatientDetailsButtons(
  {
    onShowPatientNotesModal,
    onShowSubmitModal,
    onCopyPatientSummary,
    patient,
    submitDisabled,
    ddSyntheticsIdsDisabled,
    visuallyHidden,
    highNeedPatient,
  },
  ref,
) {
  const showCopySummaryButton = useFeatureFlag('summaryCopyPaste');
  const showNewCopyPasteButton = useFeatureFlag('summaryNewCopyPaste');

  const showEnrollmentsButton = useFeatureFlag(
    'enableProgramEnrollmentV2OverridePlanSetting',
  );
  const showPPVUserDrivenEnrollmentButton = useFeatureFlag('ppvAtPointOfCare');

  const patientNeed = !visuallyHidden && highNeedPatient;
  const size = patientNeed ? 'medium' : 'large';
  const variant = patientNeed ? 'outlined' : 'contained';
  const submitColor = 'text.primaryInverse';
  const color = patientNeed ? 'text.primaryInverse' : 'text.link';

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={1}
      aria-hidden={visuallyHidden}
      sx={{
        opacity: visuallyHidden ? 0 : undefined,
        pointerEvents: visuallyHidden ? 'none' : undefined,
        transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        width: '100%',
      }}
      ref={ref}
    >
      <Stack
        direction="row"
        gap={1}
        divider={
          <Divider orientation="vertical" sx={{ mt: 1.5, height: '16px' }} />
        }
      >
        {showEnrollmentsButton ? (
          <PatientProgramEnrollments
            patientName={`${patient.firstName} ${patient.lastName}`}
            visuallyHidden={visuallyHidden}
            patientId={patient.id}
          />
        ) : null}

        {Object.keys(patient.userDrivenEnrollmentForms).map((key) =>
          /**
           * Note that user-driven enrollment forms are different from program
           * enrollments below. They share a name in the data model, but are
           * conceptually very different things.
           *
           * User-driven enrollments relate to how a user is treated within
           * Stellar, while program enrollments are links to programs that
           * exist outside the Stellar universe.
           */
          !showPPVUserDrivenEnrollmentButton ||
          key !== PRIORITY_PATIENT_VISIT ? (
            <UserDrivenEnrollmentFormWidget
              patientId={patient.id}
              key={key}
              subtype={key}
              userDrivenEnrollmentForm={patient.userDrivenEnrollmentForms[key]}
              visuallyHidden={visuallyHidden}
            />
          ) : null,
        )}

        <PatientDetailsButton
          onClick={onShowPatientNotesModal}
          visuallyHidden={visuallyHidden}
          label="Notes"
          pendoId="ps-notes-button"
          size="medium"
          color={color}
        />
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{ ml: 'auto', pl: '2rem', color: { color } }}
      >
        <CopyPasteButton
          showNew={showNewCopyPasteButton}
          showOld={showCopySummaryButton}
          patientId={patient.id}
          visuallyHidden={visuallyHidden}
          onCopyPatientSummary={onCopyPatientSummary}
        />

        <PatientDetailsButton
          href={`/print_summary/?source=patient_summary&patient=${patient.id}`}
          target="_blank"
          visuallyHidden={visuallyHidden}
          label="Print"
          icon={<StyledPrinterIcon />}
          pendoId="ps-print-button"
          size={size}
          color={color}
        />
        <Button
          onClick={onShowSubmitModal}
          disabled={submitDisabled}
          tabIndex={visuallyHidden ? -1 : undefined}
          size={size}
          sx={{
            whiteSpace: 'nowrap',
            borderColor: submitColor,
            color: submitColor,
          }}
          placeholder="Submit Updated Patient Summary"
          data-dd-synthetics-id={
            ddSyntheticsIdsDisabled
              ? undefined
              : 'submit-updated-patient-summary-button'
          }
          data-pendo-id="ps-submit-button"
          variant={variant}
        >
          Submit Updated Patient Summary
        </Button>
      </Stack>
    </Stack>
  );
});

PatientDetailsButtons.propTypes = {
  onCopyPatientSummary: func.isRequired,
  onShowSubmitModal: func.isRequired,
  onShowPatientNotesModal: func.isRequired,
  patient: patientShape.isRequired,
  submitDisabled: bool,
  ddSyntheticsIdsDisabled: bool,
  visuallyHidden: bool,
  highNeedPatient: bool,
};

PatientDetailsButtons.defaultProps = {
  submitDisabled: false,
  ddSyntheticsIdsDisabled: false, // Allows us to disable ids for one of the two instances of PatientDetailsButtons on the page
  visuallyHidden: false,
  highNeedPatient: false,
};

const styleIcon = (Icon) => styled(Icon)`
  width: 18px;
  height: 18px;
`;

const StyledCopyIcon = styleIcon(CopyIcon);
const StyledCopiedIcon = styleIcon(CopiedIcon);

const StyledPrinterIcon = styleIcon(PrinterIcon);

export default PatientDetailsButtons;
