import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';

import BaseModal from '@/components/BaseModal';
import ModalProviderDropdown from '@/pages/PatientSummary/components/modals/ModalProviderDropdown';
import BaseDatePicker from '@/components/BaseDatePicker';

function ModalSubmit({
  dateOfService,
  onChangeDateOfService,
  shouldRequireDateOfService,
  onClose,
  open,
  onSubmit,
  defaultProviderChoiceId,
}) {
  const methods = useForm();

  const [requiresDateOfServiceOnModalPopup] = useState(
    shouldRequireDateOfService,
  );

  // Add a useWatch to enable/disable the submit button whenever we
  // come back to improve the TOC datepicker workflow

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <BaseModal
        onSubmit={methods.handleSubmit(onSubmit)}
        open={open}
        onClose={onClose}
        title="Submit Updated Patient Summary"
        submitButtonDisabled={
          !methods.formState.isDirty && !defaultProviderChoiceId
        }
      >
        <ModalProviderDropdown
          label="Servicing Provider"
          name="servicingProvider"
          control={methods.control}
          errors={methods.formState.errors}
          inputProps={{
            'data-testid': 'servicingProviderSelect',
          }}
          defaultValue={defaultProviderChoiceId?.toString()}
        />
        {requiresDateOfServiceOnModalPopup && (
          <>
            <Box
              sx={{
                display: 'flex',
                my: 1,
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Typography variant="label">Date of Patient Visit</Typography>
              <BaseDatePicker
                onChange={onChangeDateOfService}
                size="medium"
                value={dateOfService}
                placeholder="Date of visit"
                sx={{
                  width: '125px',
                  display: 'block',
                  mx: 'auto',
                  mt: 0.5,
                  mr: 0,
                }}
              />
            </Box>
            <Typography
              variant="subtext"
              sx={{ color: 'status.infoForeground' }}
            >
              If submitting historical conditions at the same time, <br />
              the date of service entered will be used for both
            </Typography>
          </>
        )}
      </BaseModal>
    </FormProvider>
  );
}

export default ModalSubmit;

ModalSubmit.propTypes = {
  dateOfService: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  shouldRequireDateOfService: PropTypes.bool,
  onChangeDateOfService: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultProviderChoiceId: PropTypes.number,
};

ModalSubmit.defaultProps = {
  shouldRequireDateOfService: false,
  dateOfService: undefined,
  defaultProviderChoiceId: undefined,
};
