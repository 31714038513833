import PropTypes from 'prop-types';

import BasePatientSummaryNavItem from './BasePatientSummaryNavItem';

/**
 * A wrapper for BasePatientSummaryNavItem that does some calculation of
 * active/inactive states, sets some important identifiers, and handles
 * clicks.
 *
 * @param disabled
 * @param expectedPath
 * @param label
 * @param patient
 * @returns {JSX.Element}
 * @constructor
 */
export default function PatientSummaryLinkItem({
  expectedPath,
  label,
  onClick,
}) {
  const currentPath = window.location.pathname + window.location.search;
  return (
    <BasePatientSummaryNavItem
      active={currentPath.endsWith(expectedPath)}
      onClick={onClick}
      label={label}
      data-pendo-id={`ps-navigation-button-${expectedPath}`}
    />
  );
}
PatientSummaryLinkItem.propTypes = {
  expectedPath: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
