import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

import BaseModal from '@/components/BaseModal';

export default function ModalHistoricalConditions({ onClose, open }) {
  return (
    <BaseModal onClose={onClose} open={open} title="Historical Conditions">
      <Typography>
        Historical Conditions are chronic diagnoses that have been billed
        previously for this patient and need to be revisited by a primary care
        provider every calendar year.
      </Typography>

      <Accordion variant="disclosure">
        <AccordionSummary
          sx={{ mt: 2 }}
          data-pendo-id="ps-historical-conditions-instructions-accordion"
        >
          <Typography variant="bodybold" component="h2">
            Instructions
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography sx={{ mt: 2 }}>
            One or more diagnoses of the conditions below has been made for this
            patient in the past (based on insurer data), and has not yet been
            evaluated by a provider this year.
          </Typography>

          <Typography sx={{ mt: 2 }}>
            These diagnoses may have been made by any provider, including
            specialists, hospitals, and labs, and are being presented for you to
            evaluate if the condition is still active and you are participating
            in the management of the condition.
          </Typography>

          <Typography sx={{ mt: 2 }}>
            Insurance requires that providers evaluate the presence of chronic
            diagnoses annually, and if present, to document their management of
            those conditions accurately in the medical record.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion variant="disclosure">
        <AccordionSummary
          sx={{ mt: 2 }}
          data-pendo-id="ps-historical-conditions-coding-accordion"
        >
          <Typography variant="bodybold" component="h2">
            Coding
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ mt: 2 }}>
            If a condition is still medically relevant for your patient, and you
            have provided any management for the condition (either directly or
            indirectly through referral), please select the appropriate
            diagnosis & ICD-10 code from the previous or possible common
            diagnoses, or Specify Another Code if more appropriate for the care
            you provided.{' '}
          </Typography>

          <Typography sx={{ mt: 2 }}>
            The ICD-10 code may be selected based on those made in the past that
            are still accurate (and shown in Stellar), or a new code
            corresponding to the same condition may also be used. The code you
            provide in Stellar must match what is on the visit claim to receive
            claim confirmation credit for completing the action . The medical
            record must accurately document the management of the condition.
          </Typography>

          <Typography sx={{ mt: 2 }}>
            If the condition is no longer present or is not currently being
            managed (either directly or indirectly) during the patient visit, or
            the diagnosis is not correct for your patient, mark it as
            &quot;Assessed and Not Present&quot;, which will remove the
            diagnosis prompt from future presentation.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion variant="disclosure">
        <AccordionSummary sx={{ mt: 2 }}>
          <Typography
            variant="bodybold"
            component="h2"
            data-pendo-id="ps-historical-conditions-mrd-accordion"
          >
            Medical Record Documentation
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography sx={{ mt: 2 }}>
            To support the diagnosis of chronic conditions and your management
            of the condition, providers should document in the medical record
            the relevant details related to the management and treatment of the
            condition.
          </Typography>

          <Typography sx={{ mt: 2 }}>
            As a reminder, referring a patient to another provider for further
            care, and/or reviewing the care provided by another provider, does
            constitute your management of that condition.
          </Typography>

          <Typography sx={{ mt: 2 }}>
            As best practice, include 1+ elements of the &quot;M-E-A-T&quot;
            framework:
          </Typography>

          <ul>
            <li>
              Monitor (signs/symptoms, impressions, disease
              progression/regression, treatment compliance)
            </li>
            <li>
              Evaluate (exam findings, tests results, effectiveness of
              medications, response to treatment)
            </li>
            <li>
              Assess (ordering tests, discussion/counseling, review of records)
            </li>
            <li>
              Treat (prescribing medications, making referrals to specialists)
            </li>
          </ul>

          <Typography sx={{ mt: 2 }}>
            Documentation Examples (meant to be thought-starters, please
            document with specifics of patient condition)
          </Typography>

          <ul>
            <li>Monitor: Patient with chronic cough and mucus production</li>
            <li>Evaluate: Patient with shortness of breath</li>
            <li>Assess: Oxygen saturation is 88% on room air</li>
            <li>Treat: Start Medrol dose pak and antibiotics</li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </BaseModal>
  );
}

ModalHistoricalConditions.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
