import { node } from 'prop-types';
import { Box } from '@mui/material';

export default function CallToActionRibbon({ children }) {
  return (
    <Box
      sx={{
        backgroundColor: 'status.warningBackground',
        borderRadius: 2,
        color: 'status.warningForeground',
        fontWeight: 'bold',
        marginTop: 1,
        padding: 1,
        textAlign: 'center',
      }}
    >
      {children}
    </Box>
  );
}

CallToActionRibbon.propTypes = {
  children: node,
};

CallToActionRibbon.defaultProps = {
  children: undefined,
};
