import produce from 'immer';

export function buildCareGapActionForm({
  actionId,
  state,
  dateOfService,
  updatedActionData,
}) {
  return {
    id: actionId,
    state,
    date_of_service: dateOfService,
    ...updatedActionData,
  };
}

export function addOrUpdateCareGapActionForm({
  actionId,
  newState,
  updatedActionData,
  actionForms,
  dateOfService = new Date(),
}) {
  return produce(actionForms, (draft) => {
    draft[actionId] = buildCareGapActionForm({
      actionId,
      state: newState,
      dateOfService,
      updatedActionData,
    });
  });
}
