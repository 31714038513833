import useId from '@mui/utils/useId';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { forwardRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ClickAwayListener, MenuItem } from '@mui/material';

import useUpdateMedicalGroupAssignedUsers from '@/pages/TaskBasedWorkflow/hooks/useUpdateMedicalGroupAssignedUsers';
import {
  StyledEditUserIcon,
  StyledSelect,
  StyledUnassignedUserDropdownButton,
  StyledUserDropdownButton,
  StyledUserIcon,
} from '@/pages/TaskBasedWorkflow/components/taskTableView/summaryBannerStyles';

import DynamicFormLabel from '../../../../components/DynamicForm/DynamicFormLabel';

export const FOLLOWUP_USER_LABEL =
  'Clinical follow-up and medication reconciliation';
export const SCHEDULING_USER_LABEL = 'Scheduling';

const transformOption = (option) => ({
  value: option.id,
  label: `${option.firstName} ${option.lastName}`,
});

const TocUserSelect = forwardRef(
  ({ name, control, options, onChange, isOpen, selectedUserId }, ref) => (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <StyledSelect
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          ref={ref}
          autoFocus
          open={isOpen}
          onChange={(e) => onChange(e, field)}
          value={selectedUserId || ''}
          variant="standard"
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
      )}
    />
  ),
);

TocUserSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedUserId: PropTypes.number,
};

TocUserSelect.defaultProps = {
  selectedUserId: null,
};

export default function TocUserDropdownContainer({
  users,
  fieldLabel,
  containerRef,
  medicalGroupId,
  assignedUser,
}) {
  const filterUser = (userId, options = []) =>
    options.find((item) => item?.value === userId);

  const options = useMemo(() => users.map(transformOption), [users]);
  const initialAssignedUser = filterUser(assignedUser, options);

  const [selectedUser, setSelectedUser] = useState(initialAssignedUser);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const { mutate } = useUpdateMedicalGroupAssignedUsers();

  const handleUpdate = (userId) => {
    const payload = { medicalGroupId };
    if (fieldLabel === FOLLOWUP_USER_LABEL) {
      payload.assignedFollowupUserId = userId;
    } else if (fieldLabel === SCHEDULING_USER_LABEL) {
      payload.assignedSchedulingUserId = userId;
    }
    mutate(payload);
  };

  const toggleDropdown = () => {
    setDropdownVisible(true);
  };

  const fieldId = useId();

  const methods = useForm();
  const { control } = methods;

  const onClose = (event) => {
    if (containerRef.current && !containerRef.current.contains(event?.target)) {
      setDropdownVisible(false);
    }
  };

  const onChange = (event, field) => {
    const userId = event.target.value;
    const selected = filterUser(userId, options);
    setSelectedUser(selected);
    field.onChange(event);
    onClose();
    handleUpdate(userId);
  };

  const DropDownButtonComponent = selectedUser
    ? StyledUserDropdownButton
    : StyledUnassignedUserDropdownButton;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <ClickAwayListener onClickAway={onClose} disableReactTree>
        <div ref={containerRef}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              alignItems: 'stretch',
            }}
          >
            <DynamicFormLabel htmlFor={fieldId}>{fieldLabel}</DynamicFormLabel>
            <Box>
              {!isDropdownVisible && (
                <DropDownButtonComponent
                  onClick={toggleDropdown}
                  startIcon={<StyledUserIcon />}
                  endIcon={<StyledEditUserIcon className="end-icon" />}
                >
                  {selectedUser?.label || 'Who is in charge of this?'}
                </DropDownButtonComponent>
              )}
              {isDropdownVisible && (
                <TocUserSelect
                  name="user"
                  selectedUserId={selectedUser?.value}
                  control={control}
                  options={options}
                  onChange={onChange}
                  isOpen={isDropdownVisible}
                />
              )}
            </Box>
          </Box>
        </div>
      </ClickAwayListener>
    </FormProvider>
  );
}

TocUserDropdownContainer.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  ),
  fieldLabel: PropTypes.string,
  containerRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  medicalGroupId: PropTypes.number,
  assignedUser: PropTypes.number,
};

TocUserDropdownContainer.defaultProps = {
  fieldLabel: 'Assign User',
  medicalGroupId: undefined,
  users: undefined,
  assignedUser: undefined,
};
