import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormLabel,
  ThemeProvider,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';

import BaseRadioGroup from '@/components/BaseRadioGroup';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import BaseCheckbox from '@/components/BaseCheckbox';
import themeV2 from '@/themeV2';
import BaseTextarea from '@/components/BaseTextarea';

export const RadioValue = {
  yes: 'yes',
  no: 'no',
};

const options = [
  { value: RadioValue.yes, label: 'Yes (will update now)' },
  { value: RadioValue.no, label: 'No' },
];

export const DxActionsOpenReason = {
  outOfTime: 'out_of_time',
  waitingOnInfo: 'waiting_on_info',
  differentManagement: 'different_management',
  other: 'other',

  // This reason isn't displayed to the user. It is used to track that the user selected the "Yes" radio option.
  updatedByUser: 'updated_by_user',
};

const reasons = [
  { value: DxActionsOpenReason.outOfTime, label: 'Provider ran out of time' },
  {
    value: DxActionsOpenReason.waitingOnInfo,
    label: 'Provider waiting on more information or test results',
  },
  {
    value: DxActionsOpenReason.differentManagement,
    label: 'Condition/gap being managed by a different provider/clinic',
  },
  { value: DxActionsOpenReason.other, label: 'Other (explain)' },
];

export default function VisitFeedbackModal({
  open,
  onClose,
  onConfirm,
  incompleteDiagnosisActions,
}) {
  const [radioValue, setRadioValue] = useState('');
  const handleChangeRadio = (e) => setRadioValue(e.target.value);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setCheckboxValues((oldValue) => [...oldValue, e.target.value]);
    } else {
      setCheckboxValues((oldValue) =>
        oldValue.filter((x) => x !== e.target.value),
      );
    }
  };
  const [comments, setComments] = useState('');
  const handleChangeComments = (e) => setComments(e.target.value);
  const confirmEnabled = Boolean(
    radioValue === RadioValue.yes ||
      (radioValue === RadioValue.no && checkboxValues.length > 0),
  );

  const handleClickConfirm = () => {
    onConfirm(radioValue, checkboxValues, comments);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ elevation: 2, sx: { p: 4 } }}
      onClose={onClose}
    >
      <ThemeProvider theme={themeV2}>
        <StyledDialogTitle sx={{ pr: 2 }}>
          Were any of these chronic conditions addressed in the last visit?
        </StyledDialogTitle>

        <StyledButton
          aria-label="Close modal"
          onClick={onClose}
          data-pendo-id="visit-feedback-modal-close"
        >
          <CloseIcon />
        </StyledButton>

        <StyledDialogContent>
          <List>
            {incompleteDiagnosisActions.map((action) => (
              <li key={action.id}>{action.hcc.name}</li>
            ))}
          </List>

          <div>
            <BaseRadioGroup
              data-pendo-id="visit-feedback-modal-radio"
              options={options}
              row
              sx={{ mt: 2 }}
              onChange={handleChangeRadio}
            />
          </div>

          {radioValue === RadioValue.no && (
            <>
              <Typography variant="bodylargebold" mt={4}>
                Just so we know--why weren&apos;t these covered?
              </Typography>

              <FormGroup sx={{ mt: 2 }}>
                {reasons.map((reason) => (
                  <BaseCheckbox
                    data-pendo-id={`visit-feedback-modal-${reason.value}`}
                    key={reason.value}
                    label={reason.label}
                    value={reason.value}
                    onChange={handleChangeCheckbox}
                  />
                ))}
              </FormGroup>

              {checkboxValues.includes(DxActionsOpenReason.other) && (
                <Box mt={2}>
                  <FormLabel sx={{ display: 'block' }}>Comments</FormLabel>
                  <BaseTextarea
                    data-pendo-id="visit-feedback-modal-comments"
                    sx={{
                      display: 'block',
                      position: 'relative',
                      // Textarea's "border" is a box-shadow, so it needs spacing to not get cut off
                      left: '1px',
                    }}
                    TextareaProps={{
                      style: { width: '454px' },
                    }}
                    onChange={handleChangeComments}
                    value={comments}
                  />
                </Box>
              )}
            </>
          )}

          <Box mt={3}>
            <Button
              data-pendo-id="visit-feedback-modal-confirm"
              disabled={!confirmEnabled}
              sx={{ width: '286px' }}
              onClick={handleClickConfirm}
            >
              Confirm
            </Button>
          </Box>
        </StyledDialogContent>
      </ThemeProvider>
    </Dialog>
  );
}

VisitFeedbackModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  incompleteDiagnosisActions: PropTypes.array.isRequired,
};

VisitFeedbackModal.defaultProps = {
  open: false,
  onClose: undefined,
  onConfirm: undefined,
};

const StyledDialogTitle = styled(DialogTitle)`
  padding: ${(p) => p.theme.spacing(1, 0)};
  border-bottom: 1px solid ${(p) => p.theme.palette.border.base};
`;

const StyledDialogContent = styled(DialogContent)`
  margin-top: ${(p) => p.theme.spacing(3)};
  padding: 0;
`;

const List = styled('ul')`
  margin: 0;
`;

const StyledButton = styled('button')`
  position: absolute;
  top: ${(p) => p.theme.spacing(2)};
  right: ${(p) => p.theme.spacing(2)};

  :hover {
    color: ${(p) => p.theme.palette.icon.link};
  }
`;
