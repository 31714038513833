import axios from 'axios';

import getCsrfToken from '@/utils/getCsrfToken';
import { ActionType } from '@/pages/PatientSummary/utils/constants';

function getCopypasteText(action) {
  // different property names for stuff in v1/v2 -_-
  const isV2Action = action.stepHistories !== undefined;
  const actionText = isV2Action ? action.description : action.text;
  const dateText = isV2Action ? action.mostRecentDateForDisplay : action.date;
  const adherenceText = isV2Action
    ? action.contextualInfo?.adherenceStatus
    : action.medAdherenceStatus;

  if (action.type === ActionType.diagnosis) {
    if (dateText) {
      return `Assess ${action.hcc.name}, last documented ${dateText}`;
    }
    return `Assess ${action.hcc.name}, last documented date unknown`;
  }
  if (action.type === ActionType.medication) {
    if (adherenceText) {
      return `${actionText}, patient ${adherenceText.toLowerCase()}`;
    }
    return actionText;
  }
  if (action.type === ActionType.quality) {
    if (dateText) {
      return `${actionText}, last on file ${dateText}`;
    }
    if (isV2Action) {
      return actionText;
    }
    return `${actionText}, no previous on file`;
  }
  return '';
}

/**
 * @deprecated Only use in unit tests please
 * @param patient
 * @param allIncompleteActions
 * @param copyPasteWithLineBreaks
 */
export const getSummaryPlaintext = (
  patient,
  allIncompleteActions,
  copyPasteWithLineBreaks,
) => {
  let printedAGap = false;
  let text = `${patient.firstName} ${patient.lastName}, DOB ${patient.dateOfBirth} care gaps:`;

  allIncompleteActions.forEach((action) => {
    const actionText = getCopypasteText(action);
    if (!actionText) {
      return;
    }

    if (copyPasteWithLineBreaks) {
      text = text.concat(`\n - ${actionText}`);
    } else if (!printedAGap) {
      text = text.concat(` ${actionText}`);
    } else {
      text = text.concat(` - ${actionText}`);
    }

    printedAGap = true;
  });

  if (!printedAGap) {
    text = text.concat(' None!');
  }

  return text;
};

/* Logs an event to the PatientAccessLog so that we can track whether
 * Stellar is being used as a companion *during* the patient visit
 * (the Stellar Visit Consistency Rate)
 */
function logCopyButtonClick() {
  axios.post(`copy_summary`, undefined, {
    headers: {
      'X-CSRFToken': getCsrfToken(),
    },
  });
}

export default function copyPlaintextSummary(
  patient,
  allIncompleteActions,
  allowCopyPaste,
  copyPasteWithLineBreaks,
) {
  if (!allowCopyPaste) {
    return;
  }

  navigator.clipboard.writeText(
    getSummaryPlaintext(patient, allIncompleteActions, copyPasteWithLineBreaks),
  );
  logCopyButtonClick();
}
