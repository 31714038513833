import { array, bool, func, instanceOf, object, string } from 'prop-types';
import { Button, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import BaseModal from '@/components/BaseModal';
import DynamicForm from '@/components/DynamicForm/DynamicForm';
import DynamicFormFields from '@/components/DynamicForm/DynamicFormFields';
import EmptyField from '@/components/DynamicForm/EmptyField';
import useFeatureFlag from '@/utils/useFeatureFlag';
import BaseFormModal from '@/components/BaseFormModal';
import { adjustPxExecutionRequirements } from '@/pages/PatientSummary/utils/getVisibleStepExecutionRequirements';
import { StepExecutionProvider } from '@/pages/TaskBasedWorkflow/components/patientDetailView/stepExecutionPanel/StepExecutionContext';
import { stepShape } from '@/utils/transformFrontendCareGapAction';

function fieldComponentMapper(field) {
  switch (field.type) {
    case 'provider':
      return EmptyField; // Not needed for Patient Summary
    default:
      // Fall back to the default field component
      return null;
  }
}

/**
 * Given a set of executionRequirements for completing an action (either
 * step-based or non-step-bsed), generate a modal containing a form that
 * represents those executionRequirements.
 *
 * Default values can be provided to prefill some or all of the form fields
 * on display.
 *
 * @param action
 * @param dateOfService
 * @param defaultValues
 * @param executionRequirements
 * @param onClose
 * @param onSubmit
 * @param open
 * @param patient
 * @param title
 * @returns {JSX.Element}
 */
export default function CareGapRowModal({
  action,
  dateOfService,
  defaultValues,
  executionRequirements,
  onClose,
  onSubmit,
  open,
  patient,
  step,
  title,
}) {
  // Don't needlessly generate new additionalProps reference identities:
  const additionalProps = useMemo(
    () => ({
      action,
      dateOfService,
      patient,
      executionRequirements,
    }),
    [action, dateOfService, executionRequirements, patient],
  );

  const visitChecklistV2Enabled = useFeatureFlag(
    'pxPatientExperienceChecklist',
  );

  if (
    visitChecklistV2Enabled &&
    [
      'patient_experience_checklist_px',
      'patient_experience_print_handout_px',
    ].includes(action.subtype)
  ) {
    const pendoIdPrefixer = (id) => `${action.subtype}_${id}`;
    return (
      <BaseFormModal
        defaultValues={defaultValues}
        fields={adjustPxExecutionRequirements(
          executionRequirements,
          pendoIdPrefixer,
        )}
        onCancel={onClose}
        onClose={onClose}
        onSubmit={onSubmit}
        open={open}
        submitButtonLabel="Save"
        title={title}
        modalSx={{ width: '620px' }}
        additionalProps={additionalProps}
        cancelButtonPendoId={pendoIdPrefixer('cancel')}
        submitButtonPendoId={pendoIdPrefixer('save')}
      />
    );
  }
  return (
    <BaseModal onClose={onClose} title={title} open={open}>
      <StepExecutionProvider action={action} patient={patient} step={step}>
        {action.contextualInfo?.claimsHelpText ? (
          <Typography mb={2} whiteSpace="pre-wrap">
            {action.contextualInfo.claimsHelpText}
          </Typography>
        ) : null}
        <DynamicForm onSubmit={onSubmit} defaultValues={defaultValues}>
          <Stack spacing={2}>
            <DynamicFormFields
              additionalProps={additionalProps}
              fieldComponentMapper={fieldComponentMapper}
              fields={executionRequirements}
            />
            <Stack direction="row" justifyContent="flex-end">
              <Button type="submit">Save</Button>
            </Stack>
          </Stack>
        </DynamicForm>
      </StepExecutionProvider>
    </BaseModal>
  );
}

CareGapRowModal.propTypes = {
  action: object.isRequired,
  dateOfService: instanceOf(Date).isRequired,
  defaultValues: object,
  executionRequirements: array.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  open: bool.isRequired,
  patient: object.isRequired,
  step: stepShape,
  title: string,
};

CareGapRowModal.defaultProps = {
  defaultValues: {},
  step: undefined,
  title: '',
};
