import { forOwn, keyBy } from 'lodash';
import range from 'lodash/range';

import { totalFormsKey } from '@/pages/PatientSummary/utils/constants';

const formFieldNameRegex = /form-(?<FormIndex>\d+)-(?<FieldName>[a-zA-Z_]+)/;
const regexGroups = { FormIndex: 1, FieldName: 2 };

function parseFormFieldName(key) {
  const matches = formFieldNameRegex.exec(key);
  if (matches) {
    return {
      formIndex: matches[regexGroups.FormIndex],
      fieldName: matches[regexGroups.FieldName],
    };
  }

  return null;
}

/**
 * On PatientSummaryPage load, a starting shape for expected ActionForm
 * payload is received from backend API. (See /stellar/frontend/test/json/actionForms.json)
 * This is difficult to work with for continuous updates. This function converts the static
 * JSON into an editable data structure.
 *
 * As the user is making updates on PatientSummaryPage, this data structure is keeping
 * track of the form state so far keyed by the actionId.
 *
 * @param initialActionForms
 * @returns {ActionId: {ActionFormShape}, }
 */
export default function instantiateActionForms(initialActionForms) {
  // Instantiate empty objects for each existing action form
  const numForms = initialActionForms[totalFormsKey];
  const listOfActionForms = range(numForms).map(() => ({}));

  // copy over persisted data into our form submission tracker
  forOwn(initialActionForms, (value, key) => {
    const parsedFormField = parseFormFieldName(key);
    if (parsedFormField) {
      listOfActionForms[parsedFormField.formIndex][parsedFormField.fieldName] =
        value;
    }
  });

  // transform to object where Key=ActionId, Value=FinalActionForm
  const actionFormsToSubmit = keyBy(listOfActionForms, 'id');

  // create new empty list ready for user to add more diagnosis codes
  actionFormsToSubmit.userAddedDiagnosisCodes = [];

  return actionFormsToSubmit;
}
